<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.range"
              label="Data"
              dark
              @change="load"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="pt-0"
          >
            <v-select
              v-model="filters.type"
              label="Tipo"
              :items="[
                { value: 'TODOS', text: 'Todos' },
                { value: 'COM_CARGA', text: 'Com Carga' },
                { value: 'SEM_CARGA', text: 'Sem Carga' },
              ]"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        v-model="selectedPallets"
        :headers="headers"
        :items="filteredItems"
        :search="filters.search"
        show-select
        dark
        item-key="id"
        mobile-breakpoint="1000"
        @click:row="edit"
      >
        <template #[`item.codigo_barras`]="{ value }">
          <div
            class="full-height align-content-center"
            @click.stop=""
          >
            {{ value }}
          </div>
        </template>
        <template #[`item.sscc`]="{ value }">
          <div
            class="full-height align-content-center"
            @click.stop=""
          >
            {{ value }}
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="remove(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <PalletDialog
      ref="palletDialog"
      @close="load"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="add()"
            v-on="on"
          >
            <v-icon>
              add
            </v-icon>
          </v-btn>
        </template>
        Novo
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel"
            v-on="on"
          >
            <v-icon>
              backup_table
            </v-icon>
          </v-btn>
        </template>
        Download (Excel)
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir
      </v-tooltip>

      <v-btn
        v-if="selectedPallets.length"
        fab
        dark
        color="orange darken-4"
        @click="printSheet"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              assignment
            </v-icon>
          </template>
          Imprimir Ficha de Pallet
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selectedPallets.length"
        fab
        dark
        color="orange darken-3"
        @click="printPackingSheet"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              inventory_2
            </v-icon>
          </template>
          Imprimir Ficha de Caixas
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue'
import PalletDialog from '@/Domains/Shipment/StorageControl/Components/PalletDialog.vue'

import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from 'moment-timezone';
import isEmpty from 'lodash/fp/isEmpty';

export default {

  components: {
    VDateRangePicker,
    PalletDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      filters: {
        range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        type: '',
        search: '',
      },

      headers: [
        { text: 'Pallet', value: 'descricao' },
        { text: 'Armazenamento', value: 'armazenamento.descricao' },
        { text: 'Produto', value: 'estoques.0.estoque.item.nome' },
        { text: 'Carga', value: 'codigo_carga' },
        { text: 'Peso Bruto', value: 'peso_bruto', formatter: value => this.formatNumber(value) + ' Kg', mask: '#,##0.00' },
        { text: 'Caixas', value: 'estoques.length' },
        { text: 'Data', value: 'data_hora_cadastro', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Código de Barras', value: 'codigo_barras' },
        { text: 'SSCC', value: 'sscc' },
        { text: '', altText: 'Opções', value: 'actions', align: 'end', width: 30, sortable: false, drag: false }
      ],

      items: [],

      selectedPallets: [],
    }
  },

  computed: {
    /**
     * Exibe as ordens com base nos filtros selecionados em tela
     */
    filteredItems() {
      const type = this.filters.type === 'TODOS' ? null : this.filters.type
      if (!this.filters.search && !type) {
        return this.items
      }

      const search = this.filters.search?.toUpperCase()?.trim()

      return this.items.filter(item => {
        const matchSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search)
        const matchType = !type || (type === 'COM_CARGA' && item.codigo_carga) || (type === 'SEM_CARGA' && !item.codigo_carga)

        return matchSearch && matchType
      });
    },
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.range;

        const { data } = await this.$axios.get(`pallet`, { params: {
          start_date: startDate,
          end_date: endDate,
        } });

        this.items = data;

      } catch (error) {
        this.$snotify.error('Erro ao carregar os pallets', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.$refs.palletDialog.show({});
    },

    edit(pallet) {
      this.$refs.palletDialog.show({
        id: pallet.id
      });
    },

    async remove(pallet) {
      if (!(await this.$root.$confirm(
        `Remover pallet: ${pallet.descricao}?`,
        `Esta ação não pode ser desfeita.`,
        { color: 'red' }
      ))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`pallet/${pallet.id}`);

        const index = this.items.findIndex(item => item.id === pallet.id);
        this.items.splice(index, 1);

        this.$snotify.success('Pallet removido com sucesso');
      } catch (error) {
        this.$snotify.error(`Erro ao remover o pallet. ${error}`, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.range;
      return `Pallets - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    async printSheet() {
      if (isEmpty(this.selectedPallets)) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/pallet/sheet`, { params: {
          ids: this.selectedPallets.map(row => row.id),
        } });

        return this.printJS({
          printable: data,
          type: 'raw-html'
        })
      } catch (e) {
        console.log(e);

        this.$snotify.error('Oops, ocorreu um erro ao imprimir as fichas!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async printPackingSheet() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/pallet/packing-sheet`, { params: {
          ids: this.selectedPallets.map(row => row.id),
        } });

        return this.printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        })
      } catch (e) {
        console.log(e);

        this.$snotify.error('Oops, ocorreu um erro ao imprimir as fichas!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
  }
}
</script>
