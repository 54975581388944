<template>
  <v-dialog
    v-model="show"
    width="80%"
  >
    <v-card flat>
      <v-card-title class="pl-2">
        {{ rawMaterial.name }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col
            cols="3"
            class="pa-1"
            style="border-right: 1px solid rgba(0, 0, 0, 0.07);overflow-x: hidden; "
          >
            <analysis-tree
              v-if="show == true"
              v-model="analysis"
              :settings.sync="settings"
              :raw-material-id="rawMaterial.id"
            />
          </v-col>
          <v-col
            cols="9"
            class="pa-1"
          >
            <analysis-settings
              v-if="show == true"
              v-model="analysis"
              :settings.sync="settings"
              :raw-materials="rawMaterials"
              @onValidation="onValidation"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-btn
          text
          @click.prevent="closeDialog"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click.prevent="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading"
      absolute
    >
      <v-card-text>
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<style lang="scss">
  .span-type {
    font-family: Roboto, Arial, sans-serif;
    font-size: 1.2rem;
    color: #424242;
  }

</style>

<script>
import * as _ from "lodash";

import AnalysisTree from "@/Domains/Settings/Components/Analysis/AnalysisTree.vue";
import AnalysisSettings from "@/Domains/Settings/Components/Analysis/AnalysisSettings.vue";

export default {
  components: {
    AnalysisSettings,
    AnalysisTree
  },

  data() {
    return {
      show: false,

      rawMaterial: {},
      rawMaterials: [],

      settings: {
        showRestriction: false,
        automaticallyMarkOutOfRange: false,
        restrictionRawMaterialId: null,
      },

      loading: false,

      //flag de validação dos cards
      hasErrorAnalysis: true,

      analysis: [],

    };
  },

  methods: {
    openDialog({ rawMaterial, rawMaterials }) {
      this.rawMaterial = rawMaterial;
      this.rawMaterials = rawMaterials;
      this.show = true;
    },

    closeDialog() {
      this.rawMaterial = {},
      this.show = false;
      this.$emit('close');
    },

    async save() {
      try {
        this.loading = true;

        // Verifica se existe alguma validação pendente
        if (!this.hasErrorAnalysis) {
          this.$snotify.error("Verificar os campos de parametrização !", "Atenção");
          return false;
        }

        const allAnalysis = [];
        const restrictionParams = [];
        // Faz o parse das analises para salvar no banco
        this.analysis.forEach(param => {

          // Se for um parametro depende verifica se o pai existe
          // e se não exister faz o push
          if (_.has(param, 'depend') && !_.find(allAnalysis, o => o.parametro === param.depend)) {
            allAnalysis.push({
              parametro: param.depend,
              padrao: false,
            });
          }

          const analysis = param.analysis;

          /**
           * Caso uma analise com padrão boolean(presença/ausencia) for macada para exibir
           * ela sempre será FALSE, normalmente estas analises indicam fralde no leite
           */
          const standard = _.isObject(analysis.standard) ? analysis.standard : false;

          const analysisBase =  {
            parametro: analysis.param,
            padrao: standard,
            descricao_padrao: analysis.standardDescription,
          };

          if (this.settings.showRestriction && !_.isEmpty(analysis.restriction)) {
            analysisBase.restricao = analysis.restriction

            if (analysis.restrictionItem) {
              restrictionParams.push(analysis.param);
            }
          }

          if (_.has(analysis, "units")) {
            const unitFind = _.find(analysis.units, p => p.selected);

            analysisBase.unidade_medida = {
              descricao: unitFind.description,
              unidade: unitFind.unit,
            };

          }

          allAnalysis.push(analysisBase);
        });

        const payload = {
          id_materia_prima: this.rawMaterial.id,
          parametros_analise: allAnalysis,
          configuracao: {
            possui_config_restricao: this.settings.showRestriction,
            marcar_automaticamente: this.settings.automaticallyMarkOutOfRange,
            id_materia_prima_restricao: this.settings.restrictionRawMaterialId,
            campos_materia_prima_restricao: restrictionParams,
          },
        };

        const { data } = await this.$axios.post(`/configuracao/salvaParametrosAnalise`, payload);

        if (data.codigo !== 1) {
          throw "Erro ao salvar analise";
        }

        this.$snotify.success("Parâmetros salvos com sucesso!", "Sucesso");
        this.UpdateAnalysisParametersStore(payload);
        this.closeDialog();
      } catch (e) {
        console.log(e);
        this.$snotify.error("Erro ao salvar parâmetros", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    /**
     * Trata validação das analises
     */
    onValidation(event) {
      this.hasErrorAnalysis = event.valid;
    },

    /**
     * Atualiza store
     */
    async UpdateAnalysisParametersStore(payload) {
      try {
        const parametros_analise = this.$store.state.settings.plataforma.parametros_analise;

        _.set(parametros_analise, `materia_prima.${payload.id_materia_prima}`, payload.parametros_analise)
        _.set(parametros_analise, `configuracao.${payload.id_materia_prima}`, payload.configuracao)

        this.$store.commit("setAnalysisParameters", {
          parametros_analise
        });
      } catch (e) {
        console.warn(e);
      }
    },

    /**
     * @void
     *
     */
    nextStep(analysis) {
      this.step = '2';
      this.analysisSelected = analysis;
    },
  },
};
</script>
