<template>
  <v-card class="producer-analysis">
    <v-card-title>
      Inconformidades
      <v-spacer />
      <v-btn
        icon
        small
        depressed
        @click="close()"
      >
        <v-icon small>
          close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="py-0">
      <div>
        <div>
          <div class="px-0">
            <v-row>
              <v-col
                cols="4"
                sm="3"
                lg="2"
                class="py-0"
                style="border-right: rgba(0, 0, 0, 0.07) 1px solid;"
              >
                <v-row
                  v-for="tank of tanks"
                  :key="tank.index"
                  class="pa-1 text-overline ma-0 mb-2"
                  style="border: 1px solid #ccc; font-size: 10px !important; line-height: 20px !important;"
                >
                  <v-col
                    cols="12"
                    class="pa-0 ma-o"
                  >
                    {{ tank.label }}
                    <span
                      v-if="tank.plate"
                      class="text-subtitle-2"
                    >
                      ({{ tank.plate }})
                    </span>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pa-0 ma-o"
                  >
                    &nbsp;- Vale
                  </v-col>
                  <v-col
                    cols="7"
                    class="pa-0 ma-o text-right"
                  >
                    <span>{{ tank.vol }} L</span>
                  </v-col>

                  <v-col
                    v-if="tank.measured"
                    cols="6"
                    class="pa-0 ma-o"
                  >
                    &nbsp;- Medidos
                  </v-col>
                  <v-col
                    v-if="tank.measured"
                    cols="6"
                    class="pa-0 ma-o text-right"
                  >
                    {{ tank.measured }} L
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="8"
                sm="9"
                lg="10"
                class="pt-0"
              >
                <v-tabs>
                  <v-tab>Tanques</v-tab>
                  <v-tab>Lançamento de Análise</v-tab>
                  <v-tab-item>
                    <v-row class="ma-1">
                      <v-col
                        cols="5"
                        sm="4"
                        lg="3"
                        class="pt-0"
                      >
                        <v-checkbox
                          v-model="showAllParams"
                          label="Exibir todos os parâmetros"
                          single-line
                          hide-details
                        />
                      </v-col>
                      <v-col
                        offset="1"
                        offset-lg="3"
                        class="pt-0"
                      >
                        <v-text-field
                          ref="searchIn"
                          v-model="searchIn"
                          prepend-inner-icon="search"
                          label="Pesquisar"
                          single-line
                          hide-details
                          @input="onFocusPersist"
                        />
                      </v-col>
                    </v-row>

                    <v-card
                      v-if="discardedProducers.length"
                      class="mb-5"
                    >
                      <v-card-title>
                        Tanques descartados
                      </v-card-title>

                      <v-data-table
                        :headers="[
                          { text: 'Código', value: 'code', align: 'left', width: 150 },
                          { text: 'Nome', value: 'name' },
                          { text: 'Nº Amostra', value: 'sampleNumber', width: 120 },
                          { text: 'Vol. (L)', value: 'vol', width: 120 },
                          { text: 'Descarte Vinculado', value: 'link', width: 200 },
                          { text: '', value: 'revert', width: 100 },
                        ]"
                        :items="discardedProducers"
                        :search="search"
                        group-by="tank.index"
                        item-key="key"
                        disable-sort
                        hide-default-footer
                        disable-pagination
                        show-expand
                        single-expand
                      >
                        <template #[`group.header`]="{ group, items, headers, isOpen, toggle }">
                          <td :colspan="headers.length - 2">
                            <v-layout
                              row
                              class="pa-0 align-center"
                            >
                              <v-flex class="pa-0 text-left">
                                <v-btn
                                  icon
                                  @click="toggle"
                                >
                                  <v-icon v-if="isOpen">
                                    remove
                                  </v-icon>
                                  <v-icon v-else>
                                    add
                                  </v-icon>
                                </v-btn>
                                <span class="text-overline">
                                  {{ items[0].tank.label }}
                                </span>
                                <template v-if="items[0].tank.plate">
                                  ({{ items[0].tank.plate }})
                                </template>
                              </v-flex>
                            </v-layout>
                          </td>
                          <td>
                            <v-tooltip
                              v-if="dicardedTanksByIndex[group].driverNonconformityLink"
                              bottom
                            >
                              <template #activator="{ on }">
                                <span
                                  style="cursor: help"
                                  v-on="on"
                                >
                                  <v-icon color="yellow darken-4">warning_amber</v-icon>
                                  <span class="ma-2 yellow--text text--darken-4">Transportador</span>
                                </span>
                              </template>

                              Descarte vinculado ao transportador
                            </v-tooltip>
                          </td>
                          <td>
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-btn
                                  icon
                                  dark
                                  depressed
                                  v-on="on"
                                  @click="revertTankDiscard(group)"
                                >
                                  <v-icon color="red">
                                    settings_backup_restore
                                  </v-icon>
                                </v-btn>
                              </template>

                              Reverte o descarte do tanque
                            </v-tooltip>
                          </td>
                        </template>

                        <template #[`item.vol`]="{ value }">
                          {{ value | Mask(FloatNumberMask) }}
                        </template>

                        <template #[`item.link`]="{ item }">
                          <v-tooltip
                            v-if="item.status === 'DISCARDED'"
                            bottom
                          >
                            <template #activator="{ on }">
                              <span
                                style="cursor: help"
                                v-on="on"
                              >
                                <v-icon color="yellow darken-4">warning_amber</v-icon>
                                <span class="ma-2 yellow--text text--darken-4">Produtor</span>
                              </span>
                            </template>

                            Descarte vinculado ao produtor
                          </v-tooltip>
                        </template>

                        <template #expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            <v-tabs class="my-2">
                              <v-tab>
                                <v-badge
                                  :value="item.hasError"
                                  color="red"
                                  dot
                                >
                                  Padrão
                                </v-badge>
                              </v-tab>
                              <v-tab
                                v-for="additional in item?.additionalAnalysis"
                                :key="additional.analysisId"
                              >
                                <v-badge
                                  :value="additional.hasError"
                                  color="red"
                                  dot
                                >
                                  {{ additional.type }}
                                </v-badge>
                              </v-tab>
                              <v-tab-item>
                                <analysis
                                  v-if="showAllParams || item.problems.length > 0"
                                  :key="item.id"
                                  :value="item.analysis"
                                  :visible="!showAllParams ? item.problems : visibleAnalysisParams"
                                  :vol="item.vol"
                                  disabled
                                />
                              </v-tab-item>
                              <v-tab-item
                                v-for="additional in item?.additionalAnalysis"
                                :key="additional.analysisId"
                              >
                                <analysis
                                  v-if="showAllParams || item.problems.length > 0"
                                  :key="item.id"
                                  :value="additional.analysis"
                                  :visible="!showAllParams ? item.problems : visibleAnalysisParams"
                                  :vol="item.vol"
                                  disabled
                                />
                              </v-tab-item>
                            </v-tabs>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>

                    <v-card>
                      <v-card-title>
                        Análises de Produtor
                      </v-card-title>

                      <v-data-table
                        :headers="[
                          { text: 'Código', value: 'code', align: 'left', width: 150 },
                          { text: 'Nome', value: 'name' },
                          { text: 'Nº Amostra', value: 'sampleNumber', width: 120 },
                          { text: 'Vol. (L)', value: 'vol', width: 120 },
                          { text: 'Vincular Descarte', value: 'select', width: 300 },
                          { text: 'Status', value: 'hasError', width: 100 },
                        ]"
                        :items="producers"
                        :search="search"
                        group-by="tank.index"
                        item-key="key"
                        disable-sort
                        hide-default-footer
                        disable-pagination
                        show-expand
                        single-expand
                      >
                        <template #[`group.header`]="{ group, items, headers, isOpen, toggle }">
                          <td :colspan="headers.length - 2">
                            <v-layout
                              row
                              class="pa-0 align-center"
                            >
                              <v-flex class="pa-0 text-left">
                                <v-btn
                                  icon
                                  @click="toggle"
                                >
                                  <v-icon v-if="isOpen">
                                    remove
                                  </v-icon>
                                  <v-icon v-else>
                                    add
                                  </v-icon>
                                </v-btn>
                                <span class="text-overline">
                                  {{ items[0].tank.label }}
                                </span>
                                <template v-if="items[0].tank.plate">
                                  ({{ items[0].tank.plate }})
                                </template>
                              </v-flex>
                            </v-layout>
                          </td>
                          <td>
                            <v-checkbox
                              v-if="!['UNLOADED', 'UNLOADED_RESTRICT'].includes(tanksByIdx[group]?.status)"
                              :input-value="tanksToDiscardComputed"
                              :value="`${group}-driver`"
                              label="Transportador"
                              color="yellow darken-4"
                              hide-details
                              dense
                              class="mt-0 pa-0"
                              :disabled="!canEditConcludedProducerAnalysis"
                              @change="onTankSelect({index: group, linkId: 'driver' })"
                            />
                          </td>
                          <td>
                            <v-icon
                              v-if="tanksByIdx[group]?.problems?.length > 0"
                              color="orange"
                            >
                              warning
                            </v-icon>
                          </td>
                        </template>

                        <template #[`item.vol`]="{ value }">
                          {{ value | Mask(FloatNumberMask) }}
                        </template>

                        <template #[`item.select`]="{ item }">
                          <v-checkbox
                            v-if="!['UNLOADED', 'UNLOADED_RESTRICT'].includes(tanksByIdx[item.tank.index]?.status)"
                            :input-value="tanksToDiscardComputed"
                            :value="`${item.tank.index}-${item.id}`"
                            :disabled="!item.hasError"
                            label="Produtor"
                            color="red"
                            hide-details
                            dense
                            class="mt-0 pa-0"
                            @change="onTankSelect({index: item.tank.index, linkId: item.id })"
                          />
                        </template>

                        <template #[`item.hasError`]="{ value }">
                          <v-icon
                            v-if="value"
                            color="orange"
                          >
                            warning
                          </v-icon>
                        </template>

                        <template #expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            <v-tabs class="my-2">
                              <v-tab>
                                <v-badge
                                  :value="item.hasError"
                                  color="red"
                                  dot
                                >
                                  Padrão
                                </v-badge>
                              </v-tab>
                              <v-tab
                                v-for="additional in item?.additionalAnalysis"
                                :key="additional.analysisId"
                              >
                                <v-badge
                                  :value="additional.hasError"
                                  color="red"
                                  dot
                                >
                                  {{ additional.type }}
                                </v-badge>
                              </v-tab>
                              <v-tab-item>
                                <analysis
                                  v-if="showAllParams || item.problems.length > 0"
                                  :key="item.id"
                                  :value="item.analysis"
                                  :visible="!showAllParams ? item.problems : visibleAnalysisParams"
                                  :vol="item.vol"
                                  :cryoscopys="cryoscopys"
                                  :disabled="!canEditConcludedProducerAnalysis"
                                  @input="onProducerUpdate(item, $event)"
                                />
                              </v-tab-item>
                              <v-tab-item
                                v-for="additional in item?.additionalAnalysis"
                                :key="additional.analysisId"
                              >
                                <analysis
                                  v-if="showAllParams || item.problems.length > 0"
                                  :key="item.id"
                                  :value="additional.analysis"
                                  :visible="!showAllParams ? item.problems : visibleAnalysisParams"
                                  :vol="item.vol"
                                  disabled
                                />
                              </v-tab-item>
                            </v-tabs>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row class="ma-1">
                      <v-col
                        cols="5"
                        sm="4"
                        lg="3"
                        class="pt-0"
                      >
                        <v-checkbox
                          v-model="showAllParams"
                          label="Exibir todos os parâmetros"
                          single-line
                          hide-details
                        />
                      </v-col>
                    </v-row>
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col
                            md="4"
                          >
                            <v-autocomplete
                              ref="producer"
                              v-model="form.producer"
                              :items="producers"
                              label="Produtor"
                              item-text="description"
                              item-value="key"
                              prepend-inner-icon="person_outline"
                              filled
                              return-object
                              auto-select-first
                              clearable
                              :disabled="!canEditConcludedProducerAnalysis"
                              @change="onAnalysisSelectChange()"
                            />
                          </v-col>
                          <v-col
                            md="4"
                          >
                            <v-select
                              v-model="form.type"
                              :items="[
                                { value: 'PADRAO', text: 'Padrão' },
                                { value: 'REANALISE', text: 'Reanálise' },
                                { value: 'ADICIONAL', text: 'Adicional' },
                              ]"
                              label="Tipo"
                              filled
                              auto-select-first
                              :disabled="!canEditConcludedProducerAnalysis"
                              @change="onAnalysisSelectChange()"
                            />
                          </v-col>
                          <v-col
                            v-if="form.type=== 'REANALISE'"
                            md="4"
                          >
                            <template v-for="(otherId, idx) of form.otherIds">
                              <v-btn
                                v-if="otherId !== form.id"
                                :key="otherId"
                                color="cyan darken-1"
                                outlined
                                large
                                class="mt-2 mr-2"
                                @click="onAnalysisSelectChange(false, otherId)"
                              >
                                Reanálise {{ idx + 1 }}
                              </v-btn>
                            </template>
                            <v-btn
                              v-if="form.id"
                              color="orange darken-1"
                              outlined
                              large
                              class="mt-2"
                              @click="onAnalysisSelectChange(true)"
                            >
                              Nova Reanálise
                            </v-btn>
                          </v-col>
                        </v-row>
                        <analysis
                          v-if="form.producer?.key"
                          ref="analysisForm"
                          :key="`${form.type}-${form.id}-${form.producer.key}`"
                          v-model="form.analysis"
                          :visible="!showAllParams ? form.producer.problems : visibleAnalysisParams"
                          :vol="form.producer.vol"
                          :cryoscopys="cryoscopys"
                        />
                        <v-divider class="my-2" />
                        <div class="d-flex">
                          <div v-if="form.pending.length">
                            <span class="d-block grey--text">Análises pendentes para salvar: </span>
                            <v-chip
                              v-for="producer in form.pending"
                              :key="producer.id"
                              small
                              dark
                              color="orange"
                              class="mr-1"
                            >
                              {{ producer.description }}
                            </v-chip>
                          </div>
                          <v-spacer />
                          <v-btn
                            v-if="form.producer"
                            color="blue darken-1"
                            outlined
                            class="mt-3"
                            @click="nextAnalysis"
                          >
                            Concluir
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="grey darken-3"
        text
        @click="close()"
      >
        VOLTAR
      </v-btn>

      <v-spacer />

      <v-btn
        v-if="tanksToDiscard.length > 0"
        color="red darken-1"
        text
        outlined
        @click="saveAndClose"
      >
        SALVAR E DESCARTAR
      </v-btn>

      <v-btn
        v-else
        color="blue darken-1"
        class="ml-3"
        text
        outlined
        :disabled="!showSaveButton"
        @click="saveAndClose"
      >
        SALVAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as _ from "lodash";
import Analysis from "@/Domains/Platform/Unload/Components/Analysis.vue";import AnalysisPatternValidator from "@/Domains/Platform/Unload/Services/AnalysisPatternValidator.js";
import IMask from "imask";

export default {

  components: {
    Analysis,
  },

  filters: {

    Mask(value, mask) {
      return IMask.pipe((value || '').toString(), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },

  },

  props: {

    unload: {
      type: Object,
      default: () => ({}),
    },

    tanks: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
    },

    visibleAnalysisParams: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      searchIn: '',

      // Mascara usada nos campos numéricos float
      FloatNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      // Tanques para descartar vinculado ao produtor ou motorista
      tanksToDiscard: [],

      // Lista de produtores para análise
      producers: [],

      // Lista de produtores dos tanques descartados
      discardedProducers: [],

      // Checkbox para exibir todos os parâmetros
      showAllParams: true,

      // Lista de crioscopias
      cryoscopys: [],

      tanksByIdx: {},

      form: {
        type: 'PADRAO',

        producer: null,

        id: null,

        analysis: null,

        pending: [],

        otherIds: [],
      }

    };
  },

  computed: {

    search: {
      get() {
        return this.searchIn;
      },
      set(newValue) {
        return newValue;
      },
    },

    isLoading: {
      get() {
        return this.loading;
      },
      set(newValue) {
        return this.$emit('update:loading', newValue)
      },
    },

    invalidTanks() {
      return this.tanks.filter(tank => !_.isEmpty(tank.problems));
    },

    hasUnloadCompleted() {
      return ['LOADED', 'UNLOADED'].includes(this.unload.status);
    },

    showSaveButton() {
      if (this.form.analysis) {
        return false;
      }

      if (this.hasUnloadCompleted) {
        return this.canEditConcludedProducerAnalysis;
      }

      return this.showAllParams || this.producers.some(producer => producer.problems.length > 0);
    },

    tanksToDiscardComputed() {
      return this.tanksToDiscard.map(tank => `${tank.index}-${tank.linkId}`);
    },

    dicardedTanksByIndex() {
      return this.tanks
        .filter(tank => tank.status === 'DISCARDED')
        .reduce((acc, tank) => ({ ...acc, [tank.index]: tank }), {});
    },

    /**
     * Recupera o role do usuário
     * @returns {String}
     */

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    canEditConcludedProducerAnalysis() {
      if (!this.hasUnloadCompleted) {
        return true;
      }

      return this.isAdmin || this.userResources.some(o => o.recurso === 'edit-concluded-producer-analysis' && o.tipo === 'COMPONENTE');
    },

  },

  watch: {

    invalidTanks: {
      deep: true,
      immediate: true,

      handler(newValue) {

        if (_.isEmpty(newValue)) {
          this.producers = [];
          this.discardedProducers = [];
        }

        const producers = this.tanks.map(tank => {
          return tank.producers
            .map(producer => ({
              ...producer,
              tank,
              problems: tank.problems,
              key: `t${tank.index}-${producer.id}`
            }));
        }).flat();

        this.producers = _.cloneDeep(producers).filter(producer => producer.tank.status !== 'DISCARDED');
        this.discardedProducers = _.cloneDeep(producers).filter(producer => producer.tank.status === 'DISCARDED');

      },
    },

  },

  created() {
    this.loadCryoscopys();
  },

  async mounted() {
    await this.$nextTick();

    await this.checkAllProducerAnalysis()

    this.tanksByIdx = this.tanks.reduce((acc, tank) => ({ ...acc, [tank.index]: tank }), {})
  },

  methods: {
    onFocusPersist() {
      this.$refs.searchIn.focus();
    },
    onTankSelect(item) {
      const alreadySelectedProducerIndex = this.tanksToDiscard.findIndex(tank => tank.linkId === item.linkId && tank.index === item.index);

      if (alreadySelectedProducerIndex !== -1) {
        this.tanksToDiscard.splice(alreadySelectedProducerIndex, 1);
        return;
      }
      else {
        if (item.linkId === 'driver') {
          this.tanksToDiscard = this.tanksToDiscard.filter(tank => tank.index !== item.index);
        }
        else {
          this.tanksToDiscard = this.tanksToDiscard.filter(tank => tank.index !== item.index || (tank.index === item.index && tank.linkId !== 'driver'));
        }

        this.tanksToDiscard.push(item);
      }
    },

    async onProducerUpdate(updatedProducer, analysis, type = null, id = null) {
      this.producers = this.producers.map(producer => {
        if (producer.tank.index !== updatedProducer.tank.index) {
          return producer;
        }

        if (producer.id !== updatedProducer.id) {
          return producer;
        }

        if (['REANALISE', 'ADICIONAL'].includes(type)) {
          const additionalAnalysis = producer.additionalAnalysis || []

          const idx = additionalAnalysis.findIndex(additional => additional.type == type && additional.analysisId == id)

          if (idx > -1) {
            additionalAnalysis[idx].analysis = analysis
          } else {
            additionalAnalysis.push({ type, analysis, analysisId: id })
          }

          return {
            ...updatedProducer,
            additionalAnalysis,
          }
        }

        return {
          ...updatedProducer,
          analysis,
        };
      });

      await this.$nextTick();

      return this.checkAllProducerAnalysis();
    },

    async checkAllProducerAnalysis() {
      return this.tanks.forEach(tank => {

        const producers = tank.producers.map(producer => {
          const updatedProducer = this.producers.find(updatedProducerRow => {
            if (updatedProducerRow.tank.index !== tank.index) {
              return false;
            }

            return updatedProducerRow.id === producer.id;
          });

          if (!updatedProducer) {
            return producer;
          }

          if (updatedProducer.additionalAnalysis?.length) {
            updatedProducer.additionalAnalysis = updatedProducer.additionalAnalysis.map(additional => ({
              ...additional,
              hasError: this.hasProducerError(updatedProducer.problems, additional.analysis)
            }))
          }

          const hasError = this.hasProducerError(updatedProducer.problems, updatedProducer.analysis)

          return  {
            ...updatedProducer,
            hasError,
          }
        });

        return this.onTankUpdated({
          ...tank,
          producers,
        });
      });
    },

    hasProducerError(visible, analysis) {
      const problems = _.map(analysis, ({ value, rule }, key) => {

        if (!visible.includes(key)) {
          return false;
        }

        return !(new AnalysisPatternValidator().fromRule(rule).validate(value));
      });

      return !(problems.every(result => !result));
    },

    async saveAllProducerAnalysis() {
      try {
        this.isLoading = true;

        const req = {
          id_descarga_coletas: this.unload.id,
          id_itinerario: this.unload.itinerary.id,
          tanques: this.tanks.map(tank => {

            const producers = this.producers
              .filter(producer => producer.tank.index === tank.index)
              .flatMap(producer => {

                if (producer.additionalAnalysis?.length) {
                  return [
                    producer,
                    ...producer.additionalAnalysis
                      .map((additional) => ({
                        ...producer,
                        ...additional,
                      })),
                  ];
                }

                return producer;
              });

            if (_.isEmpty(producers)) {
              return;
            }

            return {
              tanque: `tanque${tank.index}`,
              volume_liquido: tank.vol,
              volume_total: tank.capacity,
              analises: producers.map(({ id, unloadId, vol, sampleNumber, name, analysis, analysisId, type }) => {

                const cryoscopy = analysis.cryoscopy.value !== -0.5 ? analysis.cryoscopy.value : null;

                return {
                  tanque: `tanque${tank.index}`,
                  id_descarga_analise: analysisId,
                  id_produtor: id,
                  id_coleta: unloadId,
                  nome_produtor: name,
                  tipo: type,
                  temperatura: analysis.temp.value,
                  acidez: analysis.acidity.value,
                  alizarol: analysis.alizarol.value,
                  crioscopia: cryoscopy,
                  volume_liquido: vol,
                  numero_amostra: sampleNumber,
                  porcentagem_agua: analysis.waterPercentage.value,
                  volume_agua: analysis.waterLiters.value,
                  densidade: analysis.density.value,
                  esd: analysis.esd.value,
                  gordura: analysis.fat.value,
                  proteina: analysis.protein.value,
                  mastite: analysis.mastitis.value,
                  sensorial: analysis.sensorial.value,
                  fosfatase: analysis.alkalinePhosphatase.value,
                  solidos_totais: analysis.totalSolids.value,
                  brix: analysis.brix.value,
                  est: analysis.est.value,
                  alizarol_qualit: analysis.alizarolQualit.value,
                  alcool: analysis.alcohol.value,
                  lactose: analysis.lactose.value,
                  grumos: analysis.clumps.value,
                  coagulos: analysis.clots.value,
                  redutase: analysis.reductase.value,
                  peroxidase: analysis.peroxidase.value,
                  outras_especies: analysis.otherSpecies.value,
                  solubilidade: analysis.solubilidade.value,
                  base_seca: analysis.base_seca.value,
                  ph: analysis.ph.value,
                  cefalosporina: analysis.cefalosporina.value,
                  outros: analysis.others.value,

                  // Antibióticos
                  antibiotico: analysis.antibiotic.value,
                  betalactamico: analysis.betaLactams.value,
                  tetraciclina: analysis.tetracycline.value,
                  sulfonamida: analysis.sulphonamide.value,
                  quinolonas: analysis.quinolones.value,
                  aminoglicosideos: analysis.aminoglykosides.value,
                  macrolideos: analysis.macrolides.value,
                  anfenicois: analysis.anfenicois.value,
                  neomicina: analysis.neomicina.value,

                  // Neutralizantes
                  neutralizantes: analysis.neutralising.value,
                  soda: analysis.sodiumHydroxide.value,
                  bicarbonato: analysis.bicarbonateOfSoda.value,
                  alcalino: analysis.alkali.value,

                  // Reconstituintes
                  reconstituintes: analysis.restoratives.value,
                  sacarose: analysis.sucrose.value,
                  amido: analysis.starch.value,
                  cloretos: analysis.chlorides.value,
                  cloro: analysis.chlorine.value,
                  ureia: analysis.ureia.value,

                  // Conservantes
                  conservantes: analysis.conservatives.value,
                  peroxido: analysis.peroxide.value,
                  formol: analysis.formol.value,
                }
              }),
            };
          }).filter(n => n),
        }

        const { data } = await this.$axios.post(`/descargaColeta/salvaAnalise`, req);

        if (!data.codigo) {
          throw 'Erro ao salvar';
        }
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao atualizar as análises!", "Atenção");
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    async updateDiscardedTanks() {
      try {
        this.isLoading = true;

        const tankStatus = {
          'PENDING': 'PENDENTE',
          'TO_DISCARD': 'DESCARTAR',
          'DISCARDED': 'DESCARTADO',
          'TO_UNLOAD': 'DESCARREGAR',
          'UNLOADED': 'DESCARREGADO',
          'LOADED': 'CARREGADO',
          'TO_UNLOAD_RESTRICT': 'DESCARREGAR_RESTRITO',
          'UNLOADED_RESTRICT': 'DESCARREGADO_RESTRITO',
        };

        const request = {
          id_descarga_coletas: this.unload.id,
          tanques: this.tanks.map(tank => {

            let status = tankStatus[tank.status];
            let driverNonconformityLink = tank.driverNonconformityLink;
            let discardedProducers = [];

            const tanksToDiscard = this.tanksToDiscard.filter(t => t.index === tank.index);
            if (tanksToDiscard.length > 0) {
              status = 'DESCARTADO';
              driverNonconformityLink = tanksToDiscard.find(t => t.linkId === 'driver');
              discardedProducers = tanksToDiscard
                .filter(t => t.linkId !== 'driver')
                .map(t => ({
                  id_produtor: t.linkId,
                  status: 'DESCARTADO',
                }));
            }

            return {
              tanque: `tanque${tank.index}`,
              status,
              produtores: discardedProducers,
              vincula_inconformidade_transportador: driverNonconformityLink,
              observacao: '',
              anexos: [],
            }
          }),
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/salvaDescarte`,
          request,
        );

        const { codigo } = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }

        return this.$emit('onTanksDiscarded', this.tanksToDiscard);
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao atualizar as análises!", "Atenção");
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    onTankUpdated(updatedTank) {
      return this.$emit('onTankUpdated', updatedTank);
    },

    async revertTankDiscard(tankIndex) {
      try {
        this.isLoading = true;

        this.tanksToDiscard = this.tanksToDiscard.filter(tank => tank.index !== tankIndex);

        const tank = this.tanks.find(tank => tank.index === tankIndex);

        this.onTankUpdated({
          ...tank,
          status: 'PENDING',
          producers: tank.producers.map(producer => ({
            ...producer,
            status: 'PENDING',
          })),
        });
        this.$nextTick(() => this.checkAllProducerAnalysis());
      } catch (e) {

        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },

    async saveAndClose() {
      await this.saveAllProducerAnalysis();

      await this.updateDiscardedTanks();

      return this.close();
    },

    close() {
      return this.$emit('close');
    },

    async loadCryoscopys() {
      try {
        const { data } = await this.$axios.get('registrations/cryoscopy');
        this.cryoscopys = data;

      } catch (err) {
        this.cryoscopys = [];
      }
    },

    async onAnalysisSelectChange(isNew = false,  otherId = null) {
      this.form.id = null;
      this.form.analysis = null;
      this.form.otherIds = [];

      if (!this.form.producer) {
        return;
      }

      let id = this.form.producer.analysisId;
      let analysis = { ...this.form.producer.analysis };

      if (['REANALISE', 'ADICIONAL'].includes(this.form.type)) {
        const additionals = this.form.producer?.additionalAnalysis?.filter(item => item.type === this.form.type) || [];

        this.form.otherIds = additionals.filter(item => item.analysisId).map(item => item.analysisId);

        // Cria uma nova analise
        if (additionals.length === 0 || isNew) {
          id = null;
          analysis = Object.keys(analysis).reduce((acc, key) => ({
            ...acc,
            [key]: { ...analysis[key], value: null }
          }), {})
        } else {
          // Busca a análise sem ID (aberta recentemente) ou a primeira análise
          let additionalAnalysis = additionals.find(item => !item.analysisId) || additionals[0];

          // Edição de análise adicional
          if (otherId) {
            additionalAnalysis = additionals.find(item => item.analysisId === otherId)
          }

          id = additionalAnalysis.analysisId;
          analysis = { ...additionalAnalysis.analysis };
        }
      }

      this.form.id = id;
      this.form.analysis = analysis;

      await this.$nextTick();
      this.$refs.analysisForm?.focusFirst()
    },

    async nextAnalysis() {
      this.onProducerUpdate(this.form.producer, this.form.analysis, this.form.type, this.form.id);

      const { key, description } = this.form.producer;

      if (!this.form.pending.find(p => p.key === key)) {
        this.form.pending.push({ key, description });
      }

      this.form.producer = null;
      this.form.id = null;
      this.form.otherIds = [];
      this.form.analysis = null;

      await this.$nextTick();

      return this.$refs.producer?.focus();
    }
  },
}
</script>
