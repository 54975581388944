<template>
  <div>
    <v-form
      ref="superiorForm"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="6"
          sm="3"
          md
          lg
          class="pb-0"
        >
          <masked-text-field
            v-model="unload.arrivalTime"
            label="Data de Recebimento (PCP)"
            :disabled="!settings.showUnloadDateField && (type =='coleta')"
            :mask="{ mask: '00/00/0000 00:00' }"
            :rules="[dateValidateArrivalTime]"
            :max-length="16"
            manual
            filled
          />
        </v-col>
        <v-col
          v-if="unload.itinerary.type === 'spot'"
          cols="6"
          sm="3"
          md
          lg
          class="pb-0"
        >
          <v-text-field
            v-model="unload.invoiceNumber"
            label="Número Nota"
            :rules="[invoiceNumberRequired ? rules.required : rules.notRequired]"
            prepend-inner-icon="receipt"
            clearable
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
          md
          lg
          class="pb-0"
        >
          <date-time-text-field
            v-model="unload.sampleTakenAt"
            label="Retirada de Amostra"
            icon="access_time"
            input-format="DD/MM/YYYY HH:mm"
            :rules="[dateValidateStartedAt]"
            manual
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
          md
          lg
          class="pb-0"
        >
          <date-time-text-field
            v-model="unload.releasedAt"
            label="Liberação de resultado"
            icon="access_time"
            input-format="DD/MM/YYYY HH:mm"
            :rules="[releaseDateRule, dateValidateReleasedAt]"
            manual
            filled
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
          md
          lg
          class="pb-0"
        >
          <person-autocomplete-filter
            v-model="unload.analysts"
            label="Analista"
            placeholder=" "
            type="ANALYST"
            multiple
            chips
          />
        </v-col>
        <v-col
          v-if="$store.state.settings.plataforma.carga_lacre"
          cols="6"
          sm="3"
          md
          lg
          class="pb-0"
        >
          <masked-input
            v-model="unload.cargaLacre"
            label="Lacre da Carga"
            :mask="IntNumberMask"
            type="number"
            currency
            return-unmasked
            filled
            clearable
          />
        </v-col>
        <v-col
          v-if="$store.state.settings.plataforma.cip"
          cols="6"
          sm="3"
          md
          lg
          class="pb-0"
        >
          <masked-input
            v-model="unload.cip"
            label="CIP"
            :mask="IntNumberMask"
            type="number"
            currency
            return-unmasked
            filled
            clearable
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col
        v-for="(tank, index) in localTanks"
        :key="tank.index"
        :cols="colSize"
        class="pt-0"
      >
        <v-card outlined>
          <tank-analysis-elm
            :key="tank.index"
            ref="tankAnalysisElm"
            :tank.sync="localTanks[index]"
            :raw-product="unload.rawProduct"
            :single-form="localTanks.length === 1"
            :visible="visibleAnalysisParams"
            :ignore-errors="ignoreErrors"
            :ignore-empty="ignoreEmpty"
            :restriction-settings="restrictionSettings"
            :raw-products="rawProducts"
            :show-return-input="unload.itinerary.type === 'spot' && unload.operation === 'DESCARGA' && !unload.originId"
            :cryoscopys="cryoscopys"            
            @onAnalysisInputEnter="onAnalysisInputEnter"
            @onTankUpdated="onTankUpdated"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="hasOthersUnconformitiesChecked">
      <v-col cols="12">
        <v-textarea
          v-model="unload.obs"
          label="Observações:"
          placeholder=" "
          filled
          hide-details
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="justifyDialog.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Justifique o motivo da alteração</span>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-form
            ref="justifyForm"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="justifyDialog.motive"
                  filled
                  label="Motivo da alteração"
                  auto-grow
                  counter="25"
                  :rules="[
                    rules.required,
                    rules.length(25),
                  ]"
                  class="counter-success"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="cancelJustify()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay v-model="loading">
        Carregando...
      </v-overlay>
    </v-dialog>

    <v-overlay
      v-if="hasToInformTanks"
      :absolute="true"
      :opacity="0.5"
      :value="true"
    >
      <v-alert
        type="info"
      >
        Informe o volume dos tanques!
      </v-alert>
      <inform-tanks-volume
        v-model="localTanks"
        :volume-total="unload.measured || unload.vale"
        @cancel="close"
        @save="onSaveTanksVolume"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">
.counter-success {
  .v-counter.error--text {
    color: #43A047 !important;
    caret-color: #43A047 !important;
  }
}

</style>

<script>
import * as _ from "lodash";

import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import TankAnalysisElm from "@/Domains/Platform/Unload/Components/UnloadSteps/Components/TankAnalysisElm.vue";
import InformTanksVolume from '@/Domains/Platform/Unload/Components/UnloadSteps/Components/InformTanksVolume.vue';
import DateTimeTextField from '@/Support/Components/DateTimeTextField.vue';
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import moment from "moment";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";


export default {

  components: {
    PersonAutocompleteFilter,
    TankAnalysisElm,
    InformTanksVolume,
    DateTimeTextField,
    MaskedInput,
    MaskedTextField,
  },

  props: {

    unload: {
      type: Object,
      default: () => ({}),
    },

    tanks: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
    },

    editing: {
      type: Boolean,
    },

    type: {
      type: String
    },

    visibleAnalysisParams: {
      type: Array,
      default: () => ([]),
    },

    ignoreErrors: {
      type: Array,
      default: () => ([]),
    },

    ignoreEmpty: {
      type: Array,
      default: () => ([]),
    },

    restrictionSettings: {
      type: Object,
      default: () => ({}),
    },

    rawProducts: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      localTanks: [],

      hasToInformTanks: false,
      saveTanksAfterEdit: false,

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        length: len => v => (v || '').length >= len || 'Justificativa muito curta',
        notRequired: () => true,
      },

      justifyDialog: {
        show: false,
        motive: null
      },
      IntNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            min: -999999,
            max: 999999,
          },
        },
      },
      
      cryoscopys: [],
    }
  },

  computed: {

    settings() {
      const settings = this.$store.state.settings.plataforma || {};
      return {
        measurementParam: settings.medicao_descarga_plataforma || 'balanca',
        showUnloadDateField: settings.altera_data_efetivacao || false,
        download_date_validation: parseInt(settings.tolerancia_dias_recebimento),
      };
    },

    isLoading: {
      get() {
        return this.loading;
      },
      set(newValue) {
        return this.$emit('update:loading', newValue)
      },
    },

    colSize() {

      if (_.isEmpty(this.localTanks)) {
        return 12;
      }

      let totalTanks = this.localTanks.length

      if (this.$vuetify.breakpoint.mdAndDown && totalTanks > 3) {
        totalTanks = 3
      }

      let size = Math.trunc(12 / totalTanks) || 0;

      // Menor tamanho de coluna disponível
      if (size <= 2) {
        size = 2;
      }

      if (totalTanks === 5) {
        size = undefined
      }

      return size;
    },

    hasOthersUnconformitiesChecked() {
      return this.tanks.some(tank => {
        return tank.analysis.others.value;
      });
    },

    invoiceNumberRequired() {
      return this.$store.state.settings.plataforma.invoice_number;
    },
  },

  created() {
    this.loadCryoscopys();
  },

  mounted() {
    this.localTanks = [...this.tanks];
    this.hasToInformTanks = this.localTanks.length === 0;
  },

  methods: {

    onSaveTanksVolume() {
      const capacity = this.localTanks.reduce((acc, cur) => (cur.vol > acc ? cur.vol : acc), 0);
      this.localTanks = this.localTanks.map(tank => ({ ...tank, capacity }));
      this.$emit('update:tanks', this.localTanks);
      this.hasToInformTanks = false;

      if (this.saveTanksAfterEdit === true) {
        this.saveTanksAfterEdit = false;
        this.save();
      }
    },

    async save() {
      try {
        if (this.hasToJustify()) {
          this.justifyDialog.show = true;
          return;
        }
        this.isLoading = true;

        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta || moment();
        const arrivalTime = this.unload.arrivalTime ? moment(this.unload.arrivalTime, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null;
        if ( moment(arrivalTime) < moment(dataBlock)) {
          this.$snotify.warning(
            "Não é possivel editar/inserir coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
            "Atenção"
          );
          return false;
        }

        const isValid = this.$refs.superiorForm.validate();

        if (!isValid) {
          this.$snotify.error(
            "Oops, existem dados inválidos no formulário!",
            "Atenção"
          );

          return;
        }

        const req = {
          id_descarga_coletas: this.unload.id,
          id_itinerario: this.unload.itinerary.id,
          data_hora_descarga: this.unload.arrivalTime ? moment(this.unload.arrivalTime, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null,
          hora_retirada_amostra: this.unload.sampleTakenAt ? moment(this.unload.sampleTakenAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null,
          hora_liberacao: this.unload.releasedAt ? moment(this.unload.releasedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null,
          motivo_alteracao: this.justifyDialog.motive,
          numero_nota: this.unload.invoiceNumber,
          carga_lacre: this.unload.cargaLacre ? this.unload.cargaLacre : null,
          cip: this.unload.cip ? this.unload.cip : null,
          analistas: this.unload.analysts.map(analyst => ({
            id_pessoa: analyst.id,
            nome_pessoa: analyst.name,
            assinatura: analyst.signature,
          })),
          id_materia_prima: this.unload.rawProductInput.id,
          tanques: this.localTanks.map(tank => {

            const { analysis } = tank;

            let status = 'TO_UNLOAD';

            if (this.unload.operation === 'CARGA') {
              status = 'LOADED';
            }

            if (['UNLOADED', 'DISCARDED', 'UNLOADED_RESTRICT', 'RETURNED'].includes(tank.status)) {
              status = tank.status;
            }

            if (this.editing) {
              status = tank.status;
            }

            if (tank.restriction && !['TO_UNLOAD_RESTRICT', 'UNLOADED_RESTRICT', 'UNLOADED'].includes(tank.status)) {
              status = 'TO_UNLOAD_RESTRICT';
            }

            if (tank.discard && !['DISCARDED', 'UNLOADED', 'UNLOADED_RESTRICT'].includes(tank.status)) {
              status = 'DISCARDED';
            }

            if (tank.return && !['RETURNED', 'UNLOADED', 'UNLOADED_RESTRICT'].includes(tank.status)) {
              status = 'RETURNED';
            }

            if (!tank.discard && !tank.restriction && !tank.return && ['DISCARDED', 'TO_UNLOAD_RESTRICT', 'RETURNED'].includes(tank.status)) {
              status = 'TO_UNLOAD';
            }

            if (['UNLOADED', 'UNLOADED_RESTRICT'].includes(tank.status)) {
              status = tank.restriction ? 'UNLOADED_RESTRICT' : 'UNLOADED';
            }

            // Caso a crioscopia for -0.5 não salvar este valor
            const cryoscopy = analysis.cryoscopy.value !== -0.5 ? analysis.cryoscopy.value : null;

            const tankStatus = {
              'PENDING': 'PENDENTE',
              'TO_DISCARD': 'DESCARTAR',
              'DISCARDED': 'DESCARTADO',
              'TO_UNLOAD': 'DESCARREGAR',
              'UNLOADED': 'DESCARREGADO',
              'LOADED': 'CARREGADO',
              'TO_UNLOAD_RESTRICT': 'DESCARREGAR_RESTRITO',
              'UNLOADED_RESTRICT': 'DESCARREGADO_RESTRITO',
              'TO_RETURN': 'DEVOLVER',
              'RETURNED': 'DEVOLVIDO',
            };

            return {
              tanque: `tanque${tank.index}`,
              volume_liquido: tank.vol,
              volume_total: tank.capacity,
              status: tankStatus[status],
              pesar_tanque: tank.measureTankWeight,
              id_materia_prima: tank.rawMaterial?.id,
              analises: [
                {
                  id_produtor: null,
                  nome_produtor: null,
                  temperatura: analysis.temp.value,
                  numero_lacre: analysis.numero_lacre.value,
                  acidez: analysis.acidity.value,
                  alizarol: analysis.alizarol.value,
                  crioscopia: cryoscopy,
                  porcentagem_agua: analysis.waterPercentage.value,
                  volume_agua: analysis.waterLiters.value,
                  densidade: analysis.density.value,
                  esd: analysis.esd.value,
                  gordura: analysis.fat.value,
                  proteina: analysis.protein.value,
                  antibiotico: analysis.antibiotic.value,
                  ph: analysis.ph.value,
                  betalactamico: analysis.betaLactams.value,
                  tetraciclina: analysis.tetracycline.value,
                  cefalosporina: analysis.cefalosporina.value,
                  sulfonamida: analysis.sulphonamide.value,
                  cloretos: analysis.chlorides.value,
                  mastite: analysis.mastitis.value,
                  soda: analysis.sodiumHydroxide.value,
                  bicarbonato: analysis.bicarbonateOfSoda.value,
                  formol: analysis.formol.value,
                  peroxido: analysis.peroxide.value,
                  cloro: analysis.chlorine.value,
                  amido: analysis.starch.value,
                  sacarose: analysis.sucrose.value,
                  neutralizantes: analysis.neutralising.value,
                  reconstituintes: analysis.restoratives.value,
                  conservantes: analysis.conservatives.value,
                  sensorial: analysis.sensorial.value,
                  quinolonas: analysis.quinolones.value,
                  aminoglicosideos: analysis.aminoglykosides.value,
                  macrolideos: analysis.macrolides.value,
                  anfenicois: analysis.anfenicois.value,
                  fosfatase: analysis.alkalinePhosphatase.value,
                  solidos_totais: analysis.totalSolids.value,
                  brix: analysis.brix.value,
                  est: analysis.est.value,
                  alizarol_qualit: analysis.alizarolQualit.value,
                  alcool: analysis.alcohol.value,
                  lactose: analysis.lactose.value,
                  grumos: analysis.clumps.value,
                  coagulos: analysis.clots.value,
                  redutase: analysis.reductase.value,
                  alcalino: analysis.alkali.value,
                  outros: analysis.others.value,
                  peroxidase: analysis.peroxidase.value,
                  outras_especies: analysis.otherSpecies.value,
                  solubilidade: analysis.solubilidade.value,
                  base_seca: analysis.base_seca.value,
                  neomicina: analysis.neomicina.value,
                  ureia: analysis.ureia.value,
                },
              ]
            };
          }),
        }

        const { data } = await this.$axios.post(`/descargaColeta/salvaAnalise`, req);

        if (!data.codigo) {
          throw new Error(data.mensagem);
        }

        this.close();

        return this.$emit('onTanksAnalysisSave');
      } catch (err) {
        const message = err.message || err;

        console.error(err);

        this.$snotify.error(
          message,
          "Atenção"
        );
      } finally {
        this.isLoading = false;
      }
    },

    hasToJustify() {
      const settings = this.$store.state.settings.plataforma || {};

      if (!settings.justificar_edicao_analise) {
        return false
      }

      if (!['UNLOADED', 'LOADED'].includes(this.unload.status)) {
        return false
      }

      const isValid = (this.$refs.justifyForm && this.$refs.justifyForm.validate()) || false;

      if (isValid) {
        return false;
      }

      return true;
    },

    cancelJustify() {
      this.$refs.justifyForm && this.$refs.justifyForm.resetValidation();
      this.justifyDialog.motive = null;
      this.justifyDialog.show = false;
    },

    onTankUpdated(updatedTank) {
      this.localTanks = this.localTanks.map(tank => {
        if (updatedTank.index === tank.index) {
          return updatedTank;
        }

        return tank;
      });

      return this.$emit('onTankUpdated', updatedTank);
    },

    onAnalysisInputEnter({ input, tank }) {

      const nextTank = this.$refs.tankAnalysisElm.find(elm => elm.$vnode.key === (tank.index + 1));
      const firstTank = _.first(this.$refs.tankAnalysisElm);
      const lastTank = _.last(this.$refs.tankAnalysisElm);

      const isLastTank = lastTank.$vnode.key === tank.index;

      if (isLastTank) {
        return firstTank.goToNextAnalysis(input);
      }

      return nextTank.focus(input);
    },

    close() {
      return this.$emit('close');
    },

    dateValidateArrivalTime(v) {
      if (!v || v.length !== 16) {
        return true;
      }
      const tolerance = this.settings.download_date_validation;
      const currentDate = moment();

      if (!isNaN(tolerance) && tolerance !== null) {
        const limitDateMax = tolerance === 0 ? currentDate.clone().endOf('hours') : currentDate.clone().add(tolerance, 'hours');
        const limitDateMin = tolerance === 0 ? currentDate.clone().startOf('hours') : currentDate.clone().subtract(tolerance, 'hours');

        const arrivalAt = moment(this.unload.arrivalTime, 'DD/MM/YYYY HH:mm', true);

        if (!isNaN(arrivalAt) && arrivalAt !== null) {
          const arrivalAtValidation = arrivalAt.isBefore(limitDateMax) && arrivalAt.isAfter(limitDateMin);
          if (!arrivalAtValidation) {
            return 'DATA de Recebimento(PCP) está fora do permitido!';
          }
        }
      }
      return true;
    },

    dateValidateStartedAt(v) {
      if (!v || v.length !== 16) {
        return true;
      }
      const tolerance = this.settings.download_date_validation;
      const currentDate = moment();

      if (!isNaN(tolerance) && tolerance !== null) {
        const limitDateMax = tolerance === 0 ? currentDate.clone().endOf('hours') : currentDate.clone().add(tolerance, 'hours');
        const limitDateMin = tolerance === 0 ? currentDate.clone().startOf('hours') : currentDate.clone().subtract(tolerance, 'hours');

        const startedAt = moment(this.unload.sampleTakenAt, 'DD/MM/YYYY HH:mm', true);

        if (!isNaN(startedAt) && startedAt !== null) {
          const startedAtValidation = startedAt.isBefore(limitDateMax) && startedAt.isAfter(limitDateMin);
          if (!startedAtValidation) {
            return 'A data e hora de retirada de amostra está fora do permitido!';
          }
        }
      }
      return true;
    },

    dateValidateReleasedAt(v) {
      if (!v || v.length !== 16) {
        return true;
      }
      const tolerance = this.settings.download_date_validation;
      const currentDate = moment();

      if (!isNaN(tolerance) && tolerance !== null) {
        const limitDateMax = tolerance === 0 ? currentDate.clone().endOf('hours') : currentDate.clone().add(tolerance, 'hours');
        const limitDateMin = tolerance === 0 ? currentDate.clone().startOf('hours') : currentDate.clone().subtract(tolerance, 'hours');

        const releasedAt = moment(this.unload.releasedAt, 'DD/MM/YYYY HH:mm', true);

        if (!isNaN(releasedAt) && releasedAt !== null) {
          const releasedAtValidation = releasedAt.isBefore(limitDateMax) && releasedAt.isAfter(limitDateMin);
          if (!releasedAtValidation) {
            return 'A data e hora de liberação de resultado está fora do permitido!';
          }
        }
      }
      return true;
    },

    releaseDateRule(v) {
      if (!v || v.length !== 16) {
        return true;
      }
      const endedAt = moment(v, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      const startedAt = moment(this.unload.sampleTakenAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');

      if (endedAt < startedAt) {
        return 'A data de resultado de amostra é menor que a data de analise!';
      }

      return true;
    },

    editSpotTanks() {
      this.hasToInformTanks = true;
      this.saveTanksAfterEdit = true;
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

    async loadCryoscopys() {
      try {
        const { data } = await this.$axios.get('registrations/cryoscopy');
        this.cryoscopys = data;

      } catch (err) {
        this.cryoscopys = [];
      }
    },
  },
}
</script>
