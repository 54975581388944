export default {
  data() {
    return {
      hasSecondaryRawMaterial: false,
      primaryRawMaterial: this.$store.state.settings.gerais.id_materia_prima_principal,
      secondaryRawMaterial: this.$store.state.settings.gerais.id_materia_prima_secundaria,

      rawMaterialItems: [],
      rawMaterial: null,
    };
  },

  mounted() {
    this.checkSecondaryRawMaterial();
    this.getItems();
  },

  methods: {
    async getItems() {
      try {
        if (this.hasSecondaryRawMaterial) {
          const { data } = await this.$axios.get(`/getItems`, { params: {
            items: [this.primaryRawMaterial, this.secondaryRawMaterial]
          } });

          this.rawMaterialItems = data
            .map(item => ({
              value: item.id_item,
              text: item.nome,
            }));
        }
      } catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os items de materia prima!', 'Atenção');
      }
    },

    checkSecondaryRawMaterial() {
      console.log('this.secondaryRawMaterial: ', this.secondaryRawMaterial);
      console.log('this.$store.state.settings.gerais.id_materia_prima_secundaria: ', this.$store.state.settings.gerais.id_materia_prima_secundaria);
      if (this.secondaryRawMaterial !== null) {
        this.hasSecondaryRawMaterial = true;
      }
    },

    addRawMaterialToGrid(header, newColumn) {
      if (this.hasSecondaryRawMaterial && Array.isArray(this[header])) {
        this[header].push(newColumn);
      }
    },
  },
};
