<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="80%"
      :attach="attach"
      hide-overlay
      eager
      scrollable
      persistent
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card class="text-left">
        <v-card-title>
          <span class="text-h6">Parâmetros</span>
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="close()"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text class="pb-0">
          <v-card
            :flat="localParams.appSettingsType !== 'ROTA'"
            :outlined="localParams.appSettingsType === 'ROTA'"
            class="my-3"
          >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <div class="text-caption full-width ml-8">
                    Tempo médio da coleta
                  </div>

                  <v-slider
                    v-model="localParams.collectTime"
                    prepend-icon="access_time"
                    thumb-label
                    class="pa-0"
                    min="5"
                    max="40"
                  >
                    <template #thumb-label="{ value }">
                      {{ value }}m
                    </template>

                    <template #append>
                      <span class="text-overline">
                        {{ localParams.collectTime }}m
                      </span>
                    </template>
                  </v-slider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <raw-material-autocomplete
                    v-model="localParams.rawMaterial"
                    auto-select-raw-milk
                    only-parents
                  />
                </v-col>
                <v-col
                  v-if="showAppSettings"
                  cols="6"
                  md="3"
                >
                  <person-autocomplete-filter
                    v-model="localParams.dairyDestination"
                    type="DAIRY"
                    label="Empresa de Destino"
                    prepend-inner-icon="account_balance"
                    :filled="false"
                    :hide-details="false"
                  />
                </v-col>
                <v-col
                  v-if="showAppSettings"
                  cols="6"
                  md="3"
                >
                  <v-select
                    v-model="localParams.appSettingsType"
                    label="Configurações App Coleta"
                    :items="[
                      { text: 'Laticínio', value: 'LATICINIO' },
                      { text: 'Rota', value: 'ROTA' },
                    ]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <collect-app-settings
            v-show="showAppSettings && localParams.appSettingsType === 'ROTA'"
            ref="collectAppSettingsRef"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="close()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- workaround para o modo fullscreen -->
    <v-overlay
      v-model="dialog"
      absolute
      :attach="attach"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue'
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue'
import CollectAppSettings from '@/Domains/Settings/Components/CollectAppSettings.vue'

const dialog = ref(false)

// eslint-disable-next-line no-undef
const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
  attach: String,
  showAppSettings: Boolean,
})

// eslint-disable-next-line no-undef
const emit = defineEmits([
  'params',
])

const localParams = computed({
  get() {
    return props.params
  },
  set(newValue) {
    emit('update:params', newValue)
  }
})

const collectAppSettingsRef = ref()

const show = async () => {
  dialog.value = true

  collectAppSettingsRef.value.setData(props.params?.appSettings)
}

const close = () => {
  dialog.value = false

  if (localParams.value.appSettingsType === 'ROTA') {
    localParams.value.appSettings = collectAppSettingsRef.value.getData()
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
