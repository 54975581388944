<template>
  <div>
    <v-col
      v-if="isDairySettings"
      cols="12"
      class="px-0 pt-0"
    >
      <card-title-settings>
        Parâmetros de Itinerário
      </card-title-settings>
    </v-col>
    <v-card
      :dark="isDairySettings"
      outlined
      :color="isDairySettings ? 'rgba(255, 255, 255, 0.08)' : undefined"
      class="mb-3"
    >
      <v-card-title
        v-if="!isDairySettings"
        class="text-h5"
      >
        Parâmetros de Itinerário
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="assinatura_itinerario"
              :items="optionalTextRules"
              label="Assinatura"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="mostra_justificativa_itinerario"
              :items="booleanRules"
              label="Justificar atraso de itinerário"
              dense
            />
          </v-col>

          <template v-if="isDairySettings">
            <v-col
              cols="6"
              sm="4"
              md="3"
            >
              <v-select
                v-model="velocimetro_itinerario_motorista"
                :items="textRules"
                label="Foto do Odômetro"
                dense
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="3"
            >
              <v-select
                v-model="selecionar_equipamento_itinerario_com_qr_code_motorista"
                :items="optionalTextRules"
                label="Permitir escolher veículo por QR Code"
                dense
              />
            </v-col>

            <v-col
              cols="6"
              sm="4"
              md="3"
            >
              <v-select
                v-model="troca_rota_coleta"
                :items="booleanRules"
                label="Permitir troca de rota com itinerário aberto"
                dense
              />
            </v-col>

            <v-col
              cols="6"
              sm="4"
              md="3"
            >
              <v-select
                v-model="horas_auto_fechamento_itinerario"
                :items="[24, 36, 48, 60, 72]"
                label="Horas para fechamento automático do itinerário"
                dense
                clearable
              />
            </v-col>

            <v-col
              cols="6"
              sm="4"
              md="3"
            >
              <v-select
                v-model="relatorio_entrega_coletas"
                :items="booleanRules"
                label="Relatório de Entrega de Coletas"
                dense
                clearable
              />
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <v-col
      v-if="isDairySettings"
      cols="12"
      class="px-0 pt-0"
    >
      <card-title-settings>
        Parâmetros de Coleta
      </card-title-settings>
    </v-col>
    <v-card
      :dark="isDairySettings"
      outlined
      :color="isDairySettings ? 'rgba(255, 255, 255, 0.08)' : undefined"
      class="mb-3"
    >
      <v-card-title
        v-if="!isDairySettings"
        class="text-h5"
      >
        Parâmetros de Coleta
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_foto_regua"
              :items="textRules"
              label="Foto da Régua"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_valor_regua"
              :items="textRules"
              label="Digitar valor da Régua"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_foto_temperatura"
              :items="textRules"
              label="Foto da Temperatura"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_fora_rota"
              :items="alternativeTextRules"
              label="Coleta fora de rota"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="imprime_analise_recibo"
              :items="booleanRules"
              label="Imprimir análise no recibo"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="imprime_geometrica_recibo"
              :items="booleanRules"
              label="Imprimir média geométrica no recibo"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="imprime_qr_code_recibo"
              :items="booleanRules"
              label="Imprimir QR Code no recibo"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="impressao_itinerario_coleta_detalhada"
              :items="booleanRules"
              label="Impressão relatório de coletas detalhado"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_produtor_novamente_no_itinerario"
              :items="booleanRules"
              label="Coleta produtor novamente no itinerário"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="preencher_numero_amostra"
              :items="sampleCollectTextRules"
              label="Preencher número de amostra automaticamente"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_com_qr_code"
              :items="qrCodeTextRules"
              label="Seleção de Coleta (QR Code/Tag NFC)"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_foto_coleta"
              :items="optionalPhotoRules"
              label="Fotos extras na coleta"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="justifica_coleta"
              :items="booleanRules"
              label="Obrigar a coletar todos os produtores"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="mostra_amostra_coleta"
              :items="booleanRules"
              label="Preenche campo de número de amostra"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="rejeitar_coleta_automaticamente"
              :items="booleanRules"
              label="Rejeitar coleta automaticamente (alizarol e temperatura)"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="utiliza_graduacao_de_alizarol"
              :items="booleanRules"
              label="Utiliza graduação de alizarol?"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="permitir_edicao_coleta"
              :items="booleanRules"
              label="Permitir edição de coleta"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="validar_volume_coletado"
              :items="booleanRules"
              label="Validar volume coletado com a média do produtor"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="foto_justificativa_coleta"
              :items="booleanRules"
              label="Foto na justificativa obrigatório"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-text-field
              v-model.number="coleta_temperatura_maxima"
              label="Temperatura máxima coleta"
              type="number"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_justif_temp_obrigatorio"
              :items="booleanRules"
              label="Justificar coleta acima da temperatura"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="resfriador_exibir_justificativa"
              :items="booleanRules"
              label="Permitir justificativa de resfriador"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="permite_lancamento_mais_amostra"
              :items="booleanRules"
              label="Permitir lançamento de amostra"
              dense
              clearable
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="ativa_texto_livre_amostra"
              :items="booleanRules"
              label="Permitir texto livre no lançamento de Amostra"
              dense
              clearable
            />
          </v-col>

          <v-col
            v-if="utiliza_graduacao_de_alizarol"
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="graduacoes_alizarol"
              :items="['74', '76', '78', '80']"
              label="Graduações de Alizarol"
              hint="Por padrão o 72 será o valor rejeitado"
              persistent-hint
              dense
              multiple
            />
          </v-col>

          <v-col
            cols="12"
          >
            <v-combobox
              v-model="motivos_impedimento_coleta"
              multiple
              label="Motivos para impedimento de Coleta"
              append-icon
              chips
              deletable-chips
              dense
            />
          </v-col>

          <v-col
            v-if="resfriador_exibir_justificativa"
            cols="12"
          >
            <v-combobox
              v-model="resfriador_justificativas"
              multiple
              label="Motivos para justificativas de resfriador"
              append-icon
              chips
              deletable-chips
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-col
      v-if="isDairySettings"
      cols="12"
      class="px-0 pt-0"
    >
      <card-title-settings>
        Parâmetros de Coleta em Grupo/Condomínio
      </card-title-settings>
    </v-col>

    <v-card
      :dark="isDairySettings"
      outlined
      :color="isDairySettings ? 'rgba(255, 255, 255, 0.08)' : undefined"
    >
      <v-card-title
        v-if="!isDairySettings"
        class="text-h5"
      >
        Parâmetros de Grupo/Condomínio
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="exibir_divisao_volume_condomino"
              :items="booleanRules"
              label="Exibir divisão de volume de grupo/condomínio"
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-text-field
              v-model.number="coleta_volume_min_produtor_grupo"
              label="Volume mínimo para produtor de grupo/condomínio"
              type="number"
              dense
              @keypress="disableDotAndComma"
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            md="3"
          >
            <v-select
              v-model="coleta_volume_validar_divisao_grupo"
              :items="booleanRules"
              label="Validar divisão de volume de grupo/condomínio"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-col
          v-if="isDairySettings"
          cols="12"
          class="px-0 pt-0"
        >
          <card-title-settings>
            Perímetro de Coleta
          </card-title-settings>
        </v-col>
        <v-card
          :dark="isDairySettings"
          outlined
          :color="isDairySettings ? 'rgba(255, 255, 255, 0.08)' : undefined"
        >
          <v-card-title
            v-if="!isDairySettings"
            class="text-h5"
          >
            Perímetro de Coleta
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-radio-group
                  v-model="perimetro_coleta_modo"
                  row
                  hide-details
                >
                  <v-radio
                    label="Bloquear"
                    value="BLOQUEAR"
                  />
                  <v-radio
                    label="Avisar"
                    value="AVISAR"
                  />
                  <v-radio
                    label="Ignorar"
                    value="IGNORAR"
                  />
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="perimetro_coleta_raio"
                  label="Raio do perímetro (Metros)"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-col
          v-if="isDairySettings"
          cols="12"
          class="px-0 pt-0"
        >
          <card-title-settings>
            Seleção Automática de Produtor
          </card-title-settings>
        </v-col>
        <v-card
          :dark="isDairySettings"
          outlined
          :color="isDairySettings ? 'rgba(255, 255, 255, 0.08)' : undefined"
        >
          <v-card-title
            v-if="!isDairySettings"
            class="text-h5"
          >
            Seleção Automática de Produtor
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="distancia_selecao_produtor_automatico_coleta"
                  label="Raio do perímetro (Metros)"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

export default {
  components: {
    CardTitleSettings
  },

  props: {
    isDairySettings: Boolean
  },

  data() {
    return {
      distancia_selecao_produtor_automatico_coleta: null,

      perimetro_coleta_modo: 'IGNORAR',

      perimetro_coleta_raio: null,
      justifica_coleta: null,
      coleta_fora_rota: null,
      troca_rota_coleta: null,
      permitir_edicao_coleta: null,
      validar_volume_coletado: null,
      foto_justificativa_coleta: null,
      exibir_divisao_volume_condomino: null,
      coleta_volume_min_produtor_grupo: null,
      coleta_volume_validar_divisao_grupo: null,
      coleta_foto_regua: null,
      coleta_valor_regua: null,
      coleta_foto_coleta: null,
      coleta_com_qr_code: null,
      mostra_amostra_coleta: null,
      assinatura_itinerario: null,
      imprime_analise_recibo: null,
      motivos_impedimento_coleta: null,
      coleta_foto_temperatura: null,
      coleta_temperatura_maxima: null,
      coleta_justif_temp_obrigatorio: null,
      imprime_geometrica_recibo: null,
      imprime_qr_code_recibo: null,
      impressao_itinerario_coleta_detalhada: null,
      preencher_numero_amostra: null,
      rejeitar_coleta_automaticamente: null,
      utiliza_graduacao_de_alizarol: null,
      graduacoes_alizarol: [],
      mostra_justificativa_itinerario: null,
      velocimetro_itinerario_motorista: null,
      horas_auto_fechamento_itinerario: null,
      relatorio_entrega_coletas: null,
      permite_lancamento_mais_amostra: null,
      ativa_texto_livre_amostra: null,
      coleta_produtor_novamente_no_itinerario: null,
      selecionar_equipamento_itinerario_com_qr_code_motorista: null,
      resfriador_exibir_justificativa: null,
      resfriador_justificativas: null,

      booleanRules: [
        { text: 'Ativo', value: true },
        { text: 'Inativo', value: false },
      ],

      textRules: [
        { text: 'Ativo', value: 'OBRIGATORIO' },
        { text: 'Inativo', value: 'NAO_PERMITIDO' },
      ],

      alternativeTextRules: [
        { text: 'Permitir', value: 'PERMITIDO' },
        { text: 'Não Permitir', value: 'NAO_PERMITIDO' },
      ],

      optionalTextRules: [
        { text: 'Ativo', value: 'PERMITIDO' },
        { text: 'Inativo', value: 'NAO_PERMITIDO' },
        { text: 'Obrigatório', value: 'OBRIGATORIO' },
      ],

      optionalPhotoRules: [
        { text: 'Inativo', value: 'NAO_PERMITIDO' },
        { text: 'Obrigatório quando temperatura alta ou alizarol positivo', value: 'PERMITIDO' },
        { text: 'Obrigatório', value: 'OBRIGATORIO' },
      ],

      qrCodeTextRules: [
        { text: 'Somente Lista', value: 'NAO_PERMITIDO' },
        { text: 'Somente QrCode', value: 'OBRIGATORIO' },
        { text: 'Somente Tag NFC', value: 'NFC_OBRIGATORIO' },
        { text: 'QrCode ou Lista', value: 'PERMITIDO' },
        { text: 'Tag NFC ou Lista', value: 'NFC_PERMITIDO' },
      ],

      sampleCollectTextRules: [
        { text: 'Auto-incremento', value: 'AUTOINCREMENTO' },
        { text: 'Código do Produtor', value: 'CODIGO_PRODUTOR' },
        { text: 'Texto Livre', value: 'TEXTO_LIVRE' },
        { text: 'Inativo', value: null },
      ],

    };
  },

  methods: {
    setData(data) {
      if (!data) {
        return;
      }

      this.justifica_coleta = data.justifica_coleta;
      this.coleta_fora_rota = data.coleta_fora_rota;
      this.troca_rota_coleta = data.troca_rota_coleta;
      this.permitir_edicao_coleta = data.permitir_edicao_coleta;
      this.validar_volume_coletado = data.validar_volume_coletado;
      this.foto_justificativa_coleta = data.foto_justificativa_coleta;
      this.exibir_divisao_volume_condomino = data.exibir_divisao_volume_condomino;
      this.coleta_volume_min_produtor_grupo = data.coleta_volume_min_produtor_grupo;
      this.coleta_volume_validar_divisao_grupo = data.coleta_volume_validar_divisao_grupo;
      this.coleta_foto_regua = data.coleta_foto_regua;
      this.coleta_valor_regua = data.coleta_valor_regua;
      this.coleta_foto_coleta = data.coleta_foto_coleta;
      this.coleta_com_qr_code = data.coleta_com_qr_code;
      this.perimetro_coleta_modo = data.perimetro_coleta.modo;
      this.perimetro_coleta_raio = data.perimetro_coleta.raio;
      this.mostra_amostra_coleta = data.mostra_amostra_coleta;
      this.assinatura_itinerario = data.assinatura_itinerario;
      this.imprime_analise_recibo = data.imprime_analise_recibo;
      this.motivos_impedimento_coleta = data.motivos_nao_coletas;
      this.coleta_foto_temperatura = data.coleta_foto_temperatura;
      this.coleta_temperatura_maxima = data.coleta_temperatura_maxima ?? 4.0;
      this.coleta_justif_temp_obrigatorio = data.coleta_justif_temp_obrigatorio || false;
      this.imprime_geometrica_recibo = data.imprime_geometrica_recibo;
      this.imprime_qr_code_recibo = data.imprime_qr_code_recibo;
      this.impressao_itinerario_coleta_detalhada = data.impressao_itinerario_coleta_detalhada;
      this.preencher_numero_amostra = data.ativa_autoincremento_amostra ? 'AUTOINCREMENTO' : (data.ativa_codigo_produtor_amostra ? 'CODIGO_PRODUTOR' : null);
      this.rejeitar_coleta_automaticamente = data.rejeitar_coleta_automaticamente;
      this.utiliza_graduacao_de_alizarol = data.utiliza_graduacao_de_alizarol;
      this.graduacoes_alizarol = data.graduacoes_alizarol || [];
      this.mostra_justificativa_itinerario = data.mostra_justificativa_itinerario;
      this.velocimetro_itinerario_motorista = data.velocimetro_itinerario_motorista;
      this.horas_auto_fechamento_itinerario = data.horas_auto_fechamento_itinerario;
      this.relatorio_entrega_coletas = data.relatorio_entrega_coletas;
      this.permite_lancamento_mais_amostra = data.permite_lancamento_mais_amostra;
      this.ativa_texto_livre_amostra = data.ativa_texto_livre_amostra;
      this.coleta_produtor_novamente_no_itinerario = data.coleta_produtor_novamente_no_itinerario;
      this.distancia_selecao_produtor_automatico_coleta = data.distancia_selecao_produtor_automatico_coleta;
      this.selecionar_equipamento_itinerario_com_qr_code_motorista = data.selecionar_equipamento_itinerario_com_qr_code_motorista;
      this.resfriador_exibir_justificativa = data.resfriador_exibir_justificativa;
      this.resfriador_justificativas = data.resfriador_justificativas;
    },

    getData() {
      const payload = {
        coleta_fora_rota: this.coleta_fora_rota,
        justifica_coleta: this.justifica_coleta,
        permitir_edicao_coleta: this.permitir_edicao_coleta,
        validar_volume_coletado: this.validar_volume_coletado,
        foto_justificativa_coleta: this.foto_justificativa_coleta,
        exibir_divisao_volume_condomino: this.exibir_divisao_volume_condomino,
        coleta_volume_min_produtor_grupo: this.coleta_volume_min_produtor_grupo,
        coleta_volume_validar_divisao_grupo: this.coleta_volume_validar_divisao_grupo,
        coleta_foto_regua: this.coleta_foto_regua,
        coleta_valor_regua: this.coleta_valor_regua,
        coleta_foto_coleta: this.coleta_foto_coleta,
        perimetro_coleta: {
          raio: parseInt(this.perimetro_coleta_raio),
          modo: this.perimetro_coleta_modo
        },
        assinatura_itinerario: this.assinatura_itinerario,
        mostra_amostra_coleta: this.mostra_amostra_coleta,
        coleta_com_qr_code: this.coleta_com_qr_code,
        imprime_analise_recibo: this.imprime_analise_recibo,
        motivos_nao_coletas: this.motivos_impedimento_coleta,
        coleta_foto_temperatura: this.coleta_foto_temperatura,
        coleta_temperatura_maxima: this.coleta_temperatura_maxima,
        coleta_justif_temp_obrigatorio: this.coleta_justif_temp_obrigatorio,
        imprime_geometrica_recibo: this.imprime_geometrica_recibo,
        imprime_qr_code_recibo: this.imprime_qr_code_recibo,
        impressao_itinerario_coleta_detalhada: this.impressao_itinerario_coleta_detalhada,
        ativa_autoincremento_amostra: this.preencher_numero_amostra === 'AUTOINCREMENTO',
        ativa_codigo_produtor_amostra: this.preencher_numero_amostra === 'CODIGO_PRODUTOR',
        mostra_justificativa_itinerario: this.mostra_justificativa_itinerario,
        rejeitar_coleta_automaticamente: this.rejeitar_coleta_automaticamente,
        utiliza_graduacao_de_alizarol: this.utiliza_graduacao_de_alizarol,
        graduacoes_alizarol: this.graduacoes_alizarol,
        permite_lancamento_mais_amostra: this.permite_lancamento_mais_amostra,
        ativa_texto_livre_amostra: this.ativa_texto_livre_amostra,
        coleta_produtor_novamente_no_itinerario: this.coleta_produtor_novamente_no_itinerario,
        distancia_selecao_produtor_automatico_coleta: parseInt(this.distancia_selecao_produtor_automatico_coleta),
        resfriador_exibir_justificativa: this.resfriador_exibir_justificativa,
        resfriador_justificativas: this.resfriador_justificativas,
      };

      if (this.isDairySettings) {
        payload.troca_rota_coleta = this.troca_rota_coleta
        payload.velocimetro_itinerario_motorista = this.velocimetro_itinerario_motorista
        payload.selecionar_equipamento_itinerario_com_qr_code_motorista = this.selecionar_equipamento_itinerario_com_qr_code_motorista
        payload.horas_auto_fechamento_itinerario = parseInt(this.horas_auto_fechamento_itinerario) || null
        payload.relatorio_entrega_coletas = this.relatorio_entrega_coletas
      }

      return payload
    },

    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>