import _ from "lodash";
import moment from "moment";
import MomentDuration from "moment-duration-format";
import { v4 as uuidv4 } from "uuid";

import { default as $axios, queries as $queries } from "@/Support/Resources/axios-instance.js";

MomentDuration(moment);

export default class RouteService {
  fromSystem(system) {
    this.system = system;

    return this;
  }

  /**
   * Gera todos os waypoints da rota
   *
   * @param routeId{string}
   * @param dairyPosition{Object}
   * @param startPosition{Object}
   * @param endPosition{Object}
   * @param persons{Array}
   * @param waitingTime{Number}
   * @param color{String}
   * @param useCodeAsLabel{Boolean}
   * @returns {Array}
   */
  getWaypoints(
    routeId,
    dairyPosition,
    startPosition,
    endPosition,
    persons,
    waitingTime,
    color = null,
    useCodeAsLabel = null
  ) {

    const formatNumber = (value, decimals) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: decimals }).format(value)

    const parsePerson = (person, index) => ({
      ...person,
      key: `${routeId}_${index}_${person.id}_person`,
      location: person.location,
      waiting: (waitingTime || 0) * 60, // Converte o tempo de coleta para segundos
      draggable: person.draggable || false,
      label: useCodeAsLabel ? person.code : `${index + 1}`,
      color,
      visible: true,
      richMeta: person.richMeta || {
        Produtor: `#${person.code || ''} - ${person.name || ''}`,
        Volume: person.vol ? `${formatNumber(person.vol, 0)} L` : undefined,
        Distância: person.distance ? `${formatNumber(person.distance, 1)} Km` : undefined,
      }
    })

    const parseStoppingPoint = (point, index) => ({
      ...point,
      key: `${routeId}_${index}_${point.id}_stopping_point`,
      location: point.location,
      waiting: (waitingTime || 0) * 60, // Converte o tempo de coleta para segundos
      draggable: true,
      label: `${index + 1} - PARADA`,
      color,
      visible: true,
    })

    // Deve-se fazer o map primeiro para preservar a ordem
    const waypoints = persons
      .filter(person => !person.remove && !person.groupingId && person.location.lat && person.location.lng)
      .map((person, index) => {
        if (person.type === 'PARADA') {
          return parseStoppingPoint(person, index);
        }
        return parsePerson(person, index);
      });

    // Adiciona o ponto de início da rota
    waypoints.unshift({
      key: `${routeId}-start`,
      location: {
        lat: startPosition.lat || dairyPosition.lat,
        lng: startPosition.lng || dairyPosition.lng,
      },
      draggable: true,
      label: `PARTIDA`,
      color: color,
      visible: Boolean(startPosition.lat) && Boolean(startPosition.lng), // Caso não exista o ponto de partida no mapa, computa o laticínio como ponto de partida
    });

    // Adiciona o laticínio
    waypoints.push({
      key: `${routeId}-dairy`,
      location: {
        lat: dairyPosition.lat,
        lng: dairyPosition.lng,
      },
      draggable: false,
      label: `LATICÍNIO`,
      color: 'red',
      visible: true,
    });

    // Adiciona o ponto de chegada da rota
    waypoints.push({
      key: `${routeId}-end`,
      location: {
        lat: endPosition.lat || dairyPosition.lat,
        lng: endPosition.lng || dairyPosition.lng,
      },
      draggable: true,
      label: `CHEGADA`,
      color,
      visible: Boolean(endPosition.lat) && Boolean(endPosition.lng), // Caso não exista o ponto de chegada no mapa, computa o laticínio como ponto de chegada
    });

    return waypoints;
  }

  /**
   * Carrega os dados da rota
   *
   * @param id string
   * @returns {Promise<Object>}
   */
  async getRoute(id, allProducers) {
    const { data } = await $axios.post(
      `/rota/listaProdutorRotaJson`,
      {
        id_rota: id,
        allProducers: allProducers
      }
    );
    if (_.isString(data)) {
      throw data;
    }

    const response = data[0];

    const isCommercialRoute = (response.tipos || []).map(tipo => tipo.id_tipo).includes(6);

    let mapPersons = response.produtores
      .map(point =>
        point.tipo === "PARADA"
          ? this.parseStoppingPoint(point)
          : (
            isCommercialRoute
              ? this.parseCustomer(point)
              : this.parseProducer(point)
          ));

    const groupingProducers = mapPersons.flatMap(p => p.grouping || []).map(item => item.id_pessoa)

    // Ajusta a exibição de rotas onde o produtor de agrupamento foi adicionado separadamente
    mapPersons = mapPersons.filter(point => {
      // Verifica se o produtor está incluso em um agrupamento
      if (point.groupingId && !point.grouping?.length && !groupingProducers.includes(point.id)) {
        return false
      }

      return true
    })

    const equipments = (response.equipamento || []).map(eqp => {
      return {
        id: eqp.id_equipamento,
        description: eqp.nome,
        capacity: parseInt(eqp.capacidade) || 0,
        cargoType: eqp.tipo_carga === "leite" ? "milk" : "general",
        averageFuelConsumption: parseFloat(eqp.km_litros) || 0.0,
        plate: eqp.placa,
      };
    });

    const persons = (response.pessoa || []).map(person => {
      return {
        id: person.id_pessoa,
        name: person.nome,
        code: person.codigo_laticinio,
        vol: parseInt(person.media_ultimas_coletas) || 0,
        accessAll: person.acesso_todos,
        roleId: person.id_cargo,
        cooperatives: person.cooperativas,
      };
    });

    const types = (response.tipos || []).map(type => {
      return {
        id: type.id_tipo,
        description: type.descricao_tipo,
        allowedCargo: type.id_tipo === 3 ? "milk" : "general",
      };
    });

    const tempo = response.tempo ? moment.duration(response.tempo).format("HH:mm") : '00:00';

    return {
      id: response.id_rota,
      description: response.descricao,
      routeCode: response.codigo_rota,
      published: response.publicado,
      stats: {
        distance: parseFloat(response.distancia) || 0,
        duration: tempo,
      },
      params: {
        routeStartPosition: {
          lat: parseFloat(response.end_lat_ini) || null,
          lng: parseFloat(response.end_lng_ini) || null,
        },
        routeEndPosition: {
          lat: parseFloat(response.end_lat_fim) || null,
          lng: parseFloat(response.end_lng_fim) || null,
        },
        collectTime: parseInt(response.tempo_coleta) || 0,
        dieselCostPerLitre: parseFloat(response.valor_combustivel) || null,
        routePolyline: response.polyline,
        rawMaterial: response.id_materia_prima,
        dairyDestination: {
          id: response.id_empresa_destino,
          name: response.nome_empresa_destino,
        },
        appSettingsType: response.tipo_configuracao_app,
        appSettings: response.configuracao_app,
      },
      lastItinerary: {
        vol: _.get(response, 'itinerario.volume_total'),
        unloadedAt: _.get(response, 'itinerario.data_hora_descarga'),
      },
      mapPersons,
      equipments,
      persons,
      types,
    };
  }

  /**
   * Salva a criação/edição de uma rota
   * @param route Object
   * @return {Promise<*>}
   */
  async saveRoute(route) {
    const produtores = route.mapPersons
      .filter(item => !item.groupingId)
      .flatMap(item => {
        if (item.type === 'PARADA') {
          return [
            {
              tipo: 'PARADA',
              id_rota_parada: item.id,
              ativo: !item.remove,
              nome: item.name,
              end_latitude: item.location.lat,
              end_longitude: item.location.lng,
              distancia: item.distance,
            }
          ];
        }

        const producer = {
          tipo: 'PESSOA',
          id_produtor: item.id,
          id_rota: item.id_route,
          ativo: !item.remove,
          id_regiao: item.region.id,
          descricao_regiao: item.region.description,
          id_cooperativa: item.cooperative,
          end_latitude: item.location.lat,
          end_longitude: item.location.lng,
          distancia: item.distance,
          atualizar: item.originalLocation != undefined,
        };

        const producers = [producer];

        if (item.grouping?.length > 0) {
          for (const producerGroup of item.grouping) {
            producers.push({
              ...producer,
              id_produtor: producerGroup.id_pessoa,
            })
          }
        }

        return producers
      });

    const equipamento = route.equipments
      .filter(eqp => eqp.id)
      .map(eqp => ({ id_equipamento: eqp.id }));

    const pessoa = route.persons
      .filter(pes => pes.id)
      .map(pes => ({ id_pessoa: pes.id, ativo: !pes.remove, acesso_todos: pes.accessAll, cooperativas: pes.cooperatives }));

    const tipos = route.types
      .filter(type => type.id)
      .map(type => ({ id_tipo: type.id, descricao_tipo: type.description }));

    const { data } = await $axios.post(`/rota/salva`, {
      rotas: [
        {
          id_rota: route.id,
          descricao: route.description,
          codigo_rota: route.routeCode,
          distancia: route.stats.distance,
          tempo: route.stats.duration,
          end_lat_ini: route.params.routeStartPosition.lat,
          end_lng_ini: route.params.routeStartPosition.lng,
          end_lat_fim: route.params.routeEndPosition.lat,
          end_lng_fim: route.params.routeEndPosition.lng,
          polyline: route.params.routePolyline,
          tempo_coleta: route.params.collectTime,
          valor_combustivel: route.params.dieselCostPerLitre,
          id_materia_prima: route.params.rawMaterial,
          id_empresa_destino: route.params.dairyDestination?.id,
          nome_empresa_destino: route.params.dairyDestination?.name,
          tipo_configuracao_app: route.params.appSettingsType,
          configuracao_app: route.params.appSettings,
          produtores,
          equipamento,
          pessoa,
          tipos,
          ativo: 1,
          publicado: route.published,
        },
      ],
    });

    if (_.isString(data)) {
      throw data;
    }

    if (data.length > 0 && data[0].codigo !== 1) {
      throw "Erro ao salvar a rota";
    }

    return data;
  }

  /**
   * Recupera a lista completa de produtores da base de dados
   * @todo Substituir por um typeahead e usar esse método para a busca
   *
   * @return {Promise<Array>}
   */
  async getAllProducers(allProducers) {

    const payload = { allProducers };

    const { data } = await $axios.post(`/pessoa/allProdutores`, payload);

    if (_.isString(data)) {
      throw data;
    }

    return data
      .filter(producer => !producer.id_condominio)
      .map(producer => this.parseProducer(producer));
  }

  /**
   * Faz o parse do produtor para facilitar o trabalho no front
   *
   * @param producer Object
   * @return {Object}
   */
  parseProducer(producer) {

    let richMeta = null;

    let status = producer.status;

    if (['LEAD', 'NEGOCIACAO', 'APROVACAO'].includes(status)) {
      status = 'PROSPECTADO';
      richMeta = {
        "Produtor": producer.nome,
        "Volume": producer.litros ? `${producer.litros} L` : 'não informado',
        "Status": status
      };
    }
    return {
      id: producer.id_pessoa,
      type: 'PESSOA',
      id_route: producer.id_rota,
      cooperative: producer.id_cooperativa,
      code: producer.codigo_laticinio,
      company: producer.laticinio,
      name: [producer.codigo_laticinio, producer.nome].filter(x => x).join(' - '),
      description: [producer.descricao_regiao, producer.laticinio].filter(x => x).join(' - '),
      details: `${ parseInt(producer.media_ultimas_coletas) || 0 } L | CCS: ${ parseFloat(producer.ccs) || 0 } | CPP: ${ parseFloat(producer.cbt) || 0 }`,
      ccs: parseInt(producer.ccs) || 0,
      cbt: parseInt(producer.cbt) || 0,
      vol: parseInt(producer.media_ultimas_coletas) || 0,
      status: status,
      grouping: producer.condominio ? JSON.parse(producer.condominio) : null,
      groupingId: producer.id_condominio,
      richMeta,
      distance: parseFloat(producer.distancia) || null,
      region: {
        id: producer.id_regiao,
        description: producer.descricao_regiao,
      },
      address: {
        city: producer.end_cidade || '',
        state: producer.end_estado || '',
      },
      location: {
        lat: parseFloat(producer.end_latitude),
        lng: parseFloat(producer.end_longitude),
      },
      hasLocation:
        !_.isEmpty(producer.end_latitude) &&
        !_.isEmpty(producer.end_longitude),
    };
  }

  /**
   * Recupera a lista completa de clientes da base de dados
   *
   * @return {Promise<Array>}
   */
  async getAllCustomers() {
    const { data } = await $axios.post(`/pessoa/listaClientes`);

    if (_.isString(data)) {
      throw data;
    }

    return data.map(customer => this.parseCustomer(customer));
  }

  /**
   * Faz o parse do cliente para facilitar o trabalho no front
   *
   * @param customer Object
   * @return {Object}
   */
  parseCustomer(customer) {
    return {
      id: customer.id_pessoa,
      type: 'PESSOA',
      id_route: customer.id_rota,
      code: customer.codigo_laticinio,
      company: customer.laticinio,
      name: [customer.codigo_laticinio, customer.nome].filter(x => x).join(' - '),
      description: customer.regiao || customer.descricao_regiao,
      distance: parseFloat(customer.distancia) || null,
      region: {
        id: customer.id_regiao,
        description: customer.regiao || customer.descricao_regiao,
      },
      address: {
        city: customer.end_cidade || '',
        state: customer.end_estado || '',
      },
      location: {
        lat: parseFloat(customer.end_latitude),
        lng: parseFloat(customer.end_longitude),
      },
      hasLocation:
        !_.isEmpty(customer.end_latitude) &&
        !_.isEmpty(customer.end_longitude),
    };
  }

  /**
   * Faz o parse do ponto de parada para facilitar o trabalho no front
   *
   * @param point Object
   * @return {Object}
   */
  parseStoppingPoint(point) {
    return {
      id: point.id_rota_parada,
      type: 'PARADA',
      name: point.nome,
      description: 'PARADA',
      distance: parseFloat(point.distancia) || null,
      location: {
        lat: parseFloat(point.end_latitude),
        lng: parseFloat(point.end_longitude),
      },
      hasLocation: true,
      region: {},
    };
  }

  async generateRoute(waypoints) {
    const coordinates = waypoints.map(({ location }) => `${location.lng},${location.lat}`).join(';');

    const { data } = await $queries.get(
      `/maps/osrm/route/v1/driving/${coordinates}`,
      {
        params: {
          steps: false,
          continue_straight: false,
          alternatives: false,
          overview: 'full',
          geometries: 'polyline',
        }
      }
    );

    if (data.code !== "Ok") {
      throw new Error(data.code);
    }

    return data.routes;
  }

  createStoppingPoint(location) {
    return {
      id: uuidv4(),
      type: 'PARADA',
      name: 'PONTO DE PARADA',
      description: 'PARADA',
      location,
      hasLocation: true,
      region: {},
    }
  }
}
