var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"text-xs-center":"","grid-list-lg":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"menu-header white--text"},[_vm._v(" Média de Qualidade Por Mês ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("arrow_left")]),_c('div',{staticClass:"pr-3"},[_vm._v(" Voltar ")])],1)],1)],1),_c('v-card',{ref:"analysis-filter",attrs:{"color":"transparent","flat":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"md":"3"}},[_c('date-range-picker',{attrs:{"clearable":false},on:{"change":_vm.onDateFilter},model:{value:(_vm.filter.date.input),callback:function ($$v) {_vm.$set(_vm.filter.date, "input", $$v)},expression:"filter.date.input"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('person-autocomplete-filter',{attrs:{"type":"TECHNICAL","label":"Técnico","dark":""},on:{"change":_vm.loadItems},model:{value:(_vm.filter.technician),callback:function ($$v) {_vm.$set(_vm.filter, "technician", $$v)},expression:"filter.technician"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('routes-autocomplete-filter',{attrs:{"label":"Rota","dark":"","multiple":""},on:{"change":_vm.loadItems},model:{value:(_vm.filter.routes),callback:function ($$v) {_vm.$set(_vm.filter, "routes", $$v)},expression:"filter.routes"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-autocomplete',{attrs:{"items":[
            { text: 'Ativos', value: 'ATIVOS' },
            { text: 'Desvinculados', value: 'DESVINCULADOS' },
          ],"label":"Exibir","placeholder":" ","hide-details":"","dark":"","filled":""},on:{"change":_vm.loadItems},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1)],1)],1),_c('v-card',{attrs:{"color":"transparent","dark":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1)],1),_c('data-table',{ref:"report",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.filter.search,"loading":_vm.loading,"show-expand":_vm.showExpand,"show-custom-group":"","item-key":"id_produtor"},scopedSlots:_vm._u([{key:`item.data-table-expand`,fn:function({ item, expand, isExpanded }){return [(item.condominio?.length > 1)?_c('v-btn',{attrs:{"icon":"","link":"","transition":"fab-transition"},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v(" "+_vm._s(isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down')+" ")])],1):_vm._e()]}},{key:"expanded-item",fn:function({ headers, item }){return [(item.condominio?.length > 1)?_c('td',{staticClass:"pa-0 text-center",attrs:{"colspan":12}},[_c('v-data-table',{staticClass:"elevation-1 ma-3",attrs:{"items":item.condominio,"headers":[
              { text:'Código', value:'codigo_laticinio', width: '90', align:'center'},
              { text:'Produtor', value:'nome', align:'center'},
              { text:'Data', value:'data', align:'center'},
              { text:'CCS', value:'ccs', align:'center'},
              { text:'CPP', value:'cbt', align:'center'},
              { text:'Gordura', value:'gordura', align:'center'},
              { text:'Proteína', value:'proteina', align:'center'},
              { text:'Volume', value:'volume', align: 'center'},
            ],"items-per-page":-1,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.codigo_laticinio",fn:function({ item }){return [_vm._v(" "+_vm._s(item.codigo_laticinio)+" ")]}},{key:"item.nome",fn:function({ item }){return [_vm._v(" "+_vm._s(item.nome)+" ")]}},{key:"item.data",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.data, 'DD/MM/YYYY'))+" ")]}},{key:"item.cbt",fn:function({ item }){return [_vm._v(" "+_vm._s(item.cbt)+" ")]}},{key:"item.ccs",fn:function({ item }){return [_vm._v(" "+_vm._s(item.ccs)+" ")]}},{key:"item.gordura",fn:function({ item }){return [_vm._v(" "+_vm._s(item.gordura)+" ")]}},{key:"item.proteina",fn:function({ item }){return [_vm._v(" "+_vm._s(item.proteina)+" ")]}},{key:"item.volume",fn:function({ item }){return [_vm._v(" "+_vm._s(item.volume)+" ")]}}],null,true)})],1):_vm._e()]}}],null,true)})],1),_c('v-speed-dial',{staticClass:"mr-5",attrs:{"fixed":"","dark":"","bottom":"","right":"","open-on-hover":"","direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-2","dark":"","large":"","fab":""}},[_c('v-icon',[_vm._v("menu")])],1)]},proxy:true}])},[_c('v-btn',{attrs:{"fab":"","dark":"","color":"green darken-1"},on:{"click":_vm.exportExcel}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" backup_table ")])]}}])},[_vm._v(" Download (Excel) ")])],1),_c('v-btn',{attrs:{"fab":"","dark":"","color":"orange darken-1"},on:{"click":_vm.print}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" print ")])]}}])},[_vm._v(" Imprimir ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }