<template>
  <div>
    <!-- Pagina de Cadastro  -->
    <v-dialog
      v-model="show"
      width="1060"
      max-width="1060"
    >
      <v-card
        flat
        class="pa-0"
      >
        <v-toolbar
          flat
          color="#1976D2"
          height="45px"
          class="pa-0"
        >
          <img
            src="@/Assets/logo-transparent-dark.png"
            height="30"
          >
          <v-spacer />
        </v-toolbar>
        <v-tabs
          v-model="tab"
          fixed-tabs
          background-color="#1976D2"
          dark
        >
          <v-tab>Dados Pessoais</v-tab>
          <v-tab href="#address-tab">
            Endereço
          </v-tab>
          <v-tab
            v-if="isCommercialUser"
            href="#commercial-tab"
          >
            Comercial
          </v-tab>
          <v-tab
            v-if="hasInsertEditUserAccess"
            href="#access-tab"
            @click="loadUserAccess()"
          >
            Acessos
          </v-tab>
          <v-tab
            href="#finance-tab"
            @click="loadUserFinanceAccess()"
          >
            Config. Financeira
          </v-tab>
          <v-tab
            v-if="hasInsertEditUserPermit"
            href="#permission-tab"
            @click="loadPermissions()"
          >
            Permissões
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-form
                ref="formUser"
                v-model="validForm"
                lazy-validation
              >
                <v-card-text>
                  <input
                    v-model="dataUser.id_pessoa"
                    type="hidden"
                  >
                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="dataUser.nome"
                        type="text"
                        label="Nome"
                        :rules="[v => !!v || 'Campo obrigatório']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-select
                        v-model="dataUser.arrayCargo"
                        :items="officeItems"
                        label="Cargo"
                        item-value="id_cargo"
                        item-text="cargo"
                        return-object
                        :rules="[v => !!v || 'Campo obrigatório']"
                      />
                    </v-col>
                    <v-col
                      md="2"
                      class="col-style"
                    >
                      <masked-text-field
                        v-model="dataUser.telefone"
                        label="Telefone"
                        :mask="PhoneMask"
                        maxlength="15"
                      />
                    </v-col>
                    <v-col
                      md="2"
                      class="col-style"
                    >
                      <masked-text-field
                        v-model="dataUser.celular"
                        label="Celular"
                        :mask="PhoneMask"
                        maxlength="15"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="dataUser.email"
                        type="text"
                        label="Email"
                        autocomplete="username"
                        :rules="[
                          v => !!v || 'Campo obrigatório',
                          v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail inválido'
                        ]"
                        @change="validaEmail"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="dataUser.senha"
                        :append-icon="showSenha ? 'visibility' : 'visibility_off'"
                        :type="showSenha ? 'text' : 'password'"
                        label="Senha"
                        autocomplete="new-password"
                        name="input-10-1"
                        :rules="[checkWeakPassword]"
                        @click:append="showSenha = !showSenha"
                      />
                      <v-progress-linear
                        v-if="dataUser.senha"
                        v-bind="passwordProgress(dataUser.senha)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <masked-text-field
                        v-model.trim="dataUser.cnpj_cpf"
                        label="CPF"
                        :mask="CPFMask"
                        max-length="14"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <v-text-field
                        v-model="dataUser.rg"
                        label="N° de Identidade (RG)"
                      />
                    </v-col>
                    <v-col
                      md="2"
                    >
                      <v-text-field
                        v-model="dataUser.orgao_expedidor"
                        label="Órgão expedidor"
                      />
                    </v-col>
                    <v-col
                      md="3"
                    >
                      <v-text-field
                        v-model="dataUser.uf_expedicao"
                        label="UF Expedição"
                      />
                    </v-col>
                    <v-col
                      md="2"
                    >
                      <v-text-field
                        v-model="dataUser.cnh"
                        label="Nº CNH"
                      />
                    </v-col>
                    <v-col
                      md="2"
                    >
                      <masked-text-field
                        v-model="dataUser.data_vencimento_cnh"
                        label="Validade da CNH"
                        prepend-inner-icon="event"
                        placeholder="dd/mm/aaaa"
                        :mask="{
                          mask: Date,
                          pattern: 'd/m/00000',
                          lazy: true,
                        }"
                        :rules="[v => {
                          if(v && (v.trim().length > 0 && v.trim().length < 10)) {
                            return 'Data inválida!';
                          } else {
                            return true;
                          }
                        }]"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-autocomplete
                        v-model="routeColect"
                        disabled
                        chips
                        small-chips
                        multiple
                        hide-details
                        label="Rotas de coleta"
                        :items="routeColect"
                        return-object
                        item-text="descricao"
                        item-value="id_rota"
                      >
                        <template #selection="data">
                          <v-chip
                            small
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            color="primary"
                            @click="data.select"
                          >
                            <v-icon
                              left
                              small
                            >
                              map
                            </v-icon>
                            {{ data.item.descricao }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-autocomplete
                        v-model="routeAssistance"
                        disabled
                        chips
                        small-chips
                        multiple
                        hide-details
                        label="Rotas de Assistência"
                        :items="routeAssistance"
                        return-object
                        item-text="descricao"
                        item-value="id_rota"
                      >
                        <template #selection="data">
                          <v-chip
                            small
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            color="primary"
                            @click="data.select"
                          >
                            <v-icon
                              left
                              small
                            >
                              map
                            </v-icon>
                            {{ data.item.descricao }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <span class="subtitle-1 mb-4">Assinatura</span>
                      <v-card
                        v-if="!signature"
                        class="text-center"
                        outlined
                      >
                        <v-card-text>
                          <v-btn
                            outlined
                            class="mb-5 mt-5"
                            @click="showFileUpload = !showFileUpload"
                          >
                            <v-icon>add_a_photo</v-icon> Adicionar assinatura
                          </v-btn>
                        </v-card-text>
                      </v-card>
                      <file-viewer
                        v-else
                        :value="signature"
                        :preview-height="165"
                        @removeFile="onRemoveImage"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-autocomplete
                        v-model="dataUser.cooperatives"
                        clearable
                        prepend-icon="apartment"
                        hide-details
                        label="Cooperativas"
                        :items="cooperatives"
                        multiple
                        item-text="name"
                        item-value="id"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <person-autocomplete-filter
                        v-model="dataUser.transportadores"
                        type="CARRIER"
                        clearable
                        prepend-inner-icon="local_shipping"
                        hide-details
                        label="Transportadores"
                        :filled="false"
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="black--text"
                  text
                  @click="show = false;"
                >
                  cancelar
                </v-btn>
                <v-btn
                  v-if="resourceInsertEdit"
                  class="blue--text"
                  text
                  @click.stop="onUserSave"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item
              value="commercial-tab"
              justify-center
              class="pa-3"
            >
              <commercial-tab
                :data-user.sync="dataUser"
                @cancel="show = false;"
                @save="onUserSave"
              />
            </v-tab-item>
            <v-tab-item
              value="address-tab"
              justify-center
              class="pa-3"
            >
              <v-form
                ref="form"
              >
                <input
                  v-model="dataUser.id_pessoa"
                  type="hidden"
                >
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="dataUser.end_cep"
                      type="text"
                      label="CEP"
                      :mask="CEPMask"
                      maxlength="9"
                      @change="searchAddress();"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="dataUser.end_numero"
                      type="text"
                      label="Número"
                    />
                  </v-col>
                  <v-col
                    md="4"
                    class="col-style"
                  >
                    <v-text-field
                      v-model="dataUser.end_rua"
                      type="text"
                      label="Rua"
                    />
                  </v-col>
                  <v-col
                    md="4"
                    class="col-style"
                  >
                    <v-text-field
                      v-model="dataUser.end_cidade"
                      type="text"
                      label="Cidade"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="dataUser.end_bairro"
                      type="text"
                      label="Bairro"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="dataUser.end_estado"
                      type="text"
                      label="Estado"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="black--text"
                  text
                  @click="show = false;"
                >
                  cancelar
                </v-btn>
                <v-btn
                  v-if="resourceInsertEdit"
                  class="blue--text"
                  text
                  @click.stop="onUserSave"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item
              value="access-tab"
              justify-center
              class="pa-3"
            >
              <v-overlay
                :value="!dataUser.id_pessoa"
                absolute
              >
                <v-alert
                  dense
                  light
                  prominent
                  colored-border
                  border="left"
                  elevation="2"
                  type="info"
                >
                  <div class="text-h6">
                    Atenção
                  </div>
                  Para configurar os acessos, você deve salvar o usuário primeiro
                </v-alert>
              </v-overlay>

              <v-card-text>
                <v-form
                  ref="form"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-autocomplete
                        v-model="laticinioSelected"
                        outlined
                        chips
                        small-chips
                        multiple
                        hide-details
                        label="Unidades com Acesso"
                        :items="listLaticinios"
                        item-text="nome"
                        item-value="id_pessoa"
                        :disabled="!hasPermissionToAccessUserUnits"
                        @change="onDairiesChange()"
                      >
                        <template #selection="data">
                          <v-chip
                            small
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            color="primary"
                            :disabled="!hasPermissionToAccessUserUnits"
                            @click="data.select"
                            @click:close="removeChipDairy(data.item)"
                          >
                            <v-icon
                              left
                              small
                            >
                              account_balance
                            </v-icon>
                            {{ data.item.nome }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-autocomplete
                        v-model="modulosMobile"
                        outlined
                        chips
                        small-chips
                        multiple
                        hide-details
                        return-object
                        label="Apps / Mobile"
                        :items="listModulosMobile"
                      >
                        <template #selection="data">
                          <v-chip
                            small
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            color="primary"
                            @click="data.select"
                            @click:close="removeChipModule(data.item)"
                          >
                            <v-icon
                              left
                              small
                            >
                              settings_cell
                            </v-icon>
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="showVisitSubModules"
                      cols="12"
                    >
                      <v-card outlined>
                        <v-card-title>
                          <span style="color:rgba(0, 0, 0, 0.6)">Módulos de visita</span>
                        </v-card-title>
                        <v-card-text>
                          <v-treeview
                            ref="submodules"
                            v-model="submodulesMobile"
                            selectable
                            :items="listSubmodules"
                            open-on-click
                          />
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <template v-if="!isCommercialUser">
                      <v-col cols="12">
                        <v-checkbox
                          v-model="dataUser.acesso_todos"
                          label="Visualizar todos os produtores ativos"
                        />
                      </v-col>
                      <v-col
                        v-if="!dataUser.acesso_todos"
                        cols="12"
                      >
                        <v-autocomplete
                          v-model="producersSelected"
                          outlined
                          multiple
                          label="Visualizar somente os produtores ativos"
                          :items="listProducers"
                          return-object
                          item-text="nome"
                          item-value="id_pessoa"
                          counter
                          :hint="totalGroupsSelected > 0 ? `${totalProducersWithoutGroupSelected} produtores - ${totalGroupsSelected} grupos` : undefined"
                          persistent-hint
                          :filter="(person, queryText, itemText) => person.search.indexOf(queryText.toLocaleLowerCase()) > -1"
                        >
                          <template #item="data">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.nome" />
                              <template v-if="data.item.producers?.length > 0">
                                <v-list-item-subtitle
                                  v-for="producer of data.item.producers"
                                  :key="producer.id_pessoa"
                                  class="text-body-1 mt-2"
                                >
                                  {{ producer.codigo_laticinio }} - {{ producer.nome }}
                                </v-list-item-subtitle>
                              </template>
                              <v-list-item-subtitle v-else>
                                <span
                                  v-if="data.item.codigo_laticinio"
                                  class="mx-1"
                                >
                                  {{ data.item.codigo_laticinio }}
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                          <template #selection="data">
                            <template v-if="data.index < 10">
                              <v-tooltip
                                v-if="data.item.producers?.length > 0"
                                top
                                attach
                                color="black"
                              >
                                <template #activator="{ on }">
                                  <v-chip
                                    small
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    close
                                    dark
                                    color="green darken-1"
                                    v-on="on"
                                    @click="data.select"
                                    @click:close="removeChipProducer(data.item)"
                                  >
                                    <v-icon
                                      left
                                      small
                                    >
                                      groups
                                    </v-icon>
                                    <span
                                      v-if="data.item.codigo_laticinio"
                                      class="mr-1 font-weight-light"
                                    >
                                      {{ data.item.codigo_laticinio }} -
                                    </span>
                                    {{ data.item.nome }}
                                  </v-chip>
                                </template>

                                <div>
                                  <div
                                    v-for="(producer, p) of data.item.producers"
                                    :key="producer.id_pessoa"
                                    class="d-block"
                                  >
                                    {{ producer.codigo_laticinio }} - {{ producer.nome }}
                                  </div>
                                </div>
                              </v-tooltip>
                              <v-chip
                                v-else
                                small
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                dark
                                color="green darken-1"
                                @click="data.select"
                                @click:close="removeChipProducer(data.item)"
                              >
                                <v-icon
                                  left
                                  small
                                >
                                  person
                                </v-icon>
                                <span
                                  v-if="data.item.codigo_laticinio"
                                  class="mr-1 font-weight-light"
                                >
                                  {{ data.item.codigo_laticinio }} -
                                </span>
                                {{ data.item.nome }}
                              </v-chip>
                            </template>
                            <span
                              v-if="data.index === 10"
                              class="grey--text text-caption"
                            >
                              (+{{ totalProducersSelected - 10 }} produtores)
                            </span>
                          </template>
                          <template #append-outer>
                            <v-btn
                              x-small
                              fab
                              color="red"
                              :disabled="totalProducersSelected === 0"
                              @click="clearProducerAccess()"
                            >
                              <v-icon color="white">
                                delete
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <template v-if="!isDriverUser">
                        <v-col cols="12">
                          <v-checkbox
                            v-model="dataUser.acesso_todos_prospectados"
                            label="Visualizar todos os produtores prospectados"
                          />
                        </v-col>
                        <v-col
                          v-if="!dataUser.acesso_todos_prospectados"
                          cols="12"
                        >
                          <v-autocomplete
                            v-model="prospectedsSelected"
                            outlined
                            multiple
                            label="Visualizar somente os produtores prospectados"
                            :items="listProspecteds"
                            return-object
                            item-text="nome"
                            item-value="id_pessoa"
                            counter
                            :filter="(person, queryText, itemText) => (`${person.codigo_laticinio || ''} - ${person.nome.toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
                          >
                            <template #item="data">
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.nome" />
                                <v-list-item-subtitle>
                                  <span
                                    v-if="data.item.codigo_laticinio"
                                    class="mx-1"
                                  >
                                    {{ data.item.codigo_laticinio }}
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                            <template #selection="data">
                              <v-chip
                                v-if="data.index < 10"
                                small
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                dark
                                color="blue darken-1"
                                @click="data.select"
                                @click:close="removeChipProspected(data.item)"
                              >
                                <v-icon
                                  left
                                  small
                                >
                                  person
                                </v-icon>
                                <span
                                  v-if="data.item.codigo_laticinio"
                                  class="mr-1 font-weight-light"
                                >
                                  {{ data.item.codigo_laticinio }} -
                                </span>
                                {{ data.item.nome }}
                              </v-chip>
                              <span
                                v-if="data.index === 10"
                                class="grey--text text-caption"
                              >
                                (+{{ totalProspectedSelected - 10 }} produtores)
                              </span>
                            </template>
                            <template #append-outer>
                              <v-btn
                                x-small
                                fab
                                color="red"
                                :disabled="totalProspectedSelected === 0"
                                @click="clearProspectedAccess()"
                              >
                                <v-icon color="white">
                                  delete
                                </v-icon>
                              </v-btn>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </template>
                    </template>
                    <template v-else>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="dataUser.acesso_todos"
                          label="Visualizar todos os clientes"
                        />
                      </v-col>
                      <v-col
                        v-if="!dataUser.acesso_todos"
                        cols="12"
                      >
                        <v-autocomplete
                          v-model="customersSelected"
                          outlined
                          multiple
                          label="Visualizar somente os clientes"
                          :items="listCustomers"
                          return-object
                          item-text="nome"
                          item-value="id_pessoa"
                          counter
                        >
                          <template #selection="{attrs, selected, select, item, index}">
                            <v-chip
                              v-if="index < 10"
                              small
                              v-bind="attrs"
                              :input-value="selected"
                              close
                              color="primary"
                              @click="select"
                              @click:close="removeChipCustomer(item)"
                            >
                              <v-icon
                                left
                                small
                              >
                                person
                              </v-icon>
                              {{ item.nome }}
                            </v-chip>
                            <span
                              v-if="index === 10"
                              class="grey--text text-caption"
                            >
                              (+{{ listCustomers.length - 10 }} clientes)
                            </span>
                          </template>
                          <template #append-outer>
                            <v-btn
                              x-small
                              fab
                              color="red"
                              :disabled="listCustomers.length === 0"
                              @click="clearCustomerAccess()"
                            >
                              <v-icon color="white">
                                delete
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </template>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-btn
                  v-if="resourceRemakeAccess"
                  class="blue--text"
                  text
                  left
                  @click.stop="onAccessSaveFS"
                >
                  Reenviar APP
                </v-btn>
                <v-spacer />
                <v-btn
                  class="black--text"
                  text
                  @click="show = false;"
                >
                  cancelar
                </v-btn>
                <v-btn
                  v-if="resourceInsertEdit"
                  class="blue--text"
                  text
                  @click.stop="onAccessSave"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item
              value="finance-tab"
              justify-center
              class="pa-3"
            >
              <v-overlay
                :value="!dataUser.id_pessoa"
                absolute
              >
                <v-alert
                  dense
                  light
                  prominent
                  colored-border
                  border="left"
                  elevation="2"
                  type="info"
                >
                  <div class="text-h6">
                    Atenção
                  </div>
                  Para configurar as configurações do financeiro, você deve salvar o usuário primeiro
                </v-alert>
              </v-overlay>

              <v-card-text>
                <v-form
                  ref="form"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="pb-0"
                    >
                      <v-checkbox
                        v-model="dataUser.considerar_acesso_rotas"
                        label="Considerar acesso por rotas no pagamento"
                        @click="listAllRoutesAccess"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="dataUser.considerar_acesso_rotas"
                      cols="12"
                      md="12"
                    >
                      <v-autocomplete
                        v-model="listRoutesProducerAccessSelected"
                        outlined
                        chips
                        small-chips
                        multiple
                        clearable
                        prepend-inner-icon="icon-rota"
                        hide-details
                        label="Rotas"
                        :items="listRoutesProducerAccess"
                        item-text="name"
                        item-value="id_route"
                      >
                        <template #selection="{item}">
                          <v-chip
                            small
                            text-color="white"
                            color="blue darken-1"
                          >
                            <v-icon
                              left
                              small
                            >
                              map
                            </v-icon>
                            {{ item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-checkbox
                        v-model="dataUser.considerar_acesso_regiao"
                        label="Considerar acesso por região no pagamento"
                        @click="listAllRegionsProducers"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="dataUser.considerar_acesso_regiao"
                      cols="12"
                      md="12"
                    >
                      <!--                      marcando somente durante o desenvolvimento-->
                      <v-autocomplete
                        v-model="listRegionsProducersSelected"
                        outlined
                        chips
                        small-chips
                        multiple
                        prepend-inner-icon="route"
                        hide-details
                        label="Região"
                        :items="listRegionsProducers"
                        item-text="name"
                        item-value="id_regiao"
                        clearable
                        return-object
                      >
                        <template #selection="{item}">
                          <v-chip
                            small
                            text-color="white"
                            color="green darken-1"
                          >
                            <v-icon
                              left
                              small
                            >
                              map
                            </v-icon>
                            {{ item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                      <!--                aqui finaliza todos-->
                    </v-col>
                  </v-row>
                </v-form>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    class="black--text"
                    text
                    @click="show = false;"
                  >
                    cancelar
                  </v-btn>
                  <v-btn
                    v-if="resourceInsertEdit"
                    class="blue--text"
                    text
                    @click.stop="onAccessSave"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-tab-item>
            <v-tab-item
              value="permission-tab"
              justify-center
              class="pa-3"
            >
              <v-overlay
                :value="!dataUser.id_pessoa"
                absolute
              >
                <v-alert
                  dense
                  light
                  prominent
                  colored-border
                  border="left"
                  elevation="2"
                  type="info"
                >
                  <div class="text-h6">
                    Atenção
                  </div>
                  Para configurar as permissões, você deve salvar o usuário primeiro
                </v-alert>
              </v-overlay>

              <v-card-text>
                <v-form
                  ref="form"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="searchPermission"
                        label="Pesquisar"
                        flat
                        hide-details
                        clearable
                        @input="handleSearchPermission"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-treeview
                        ref="tree"
                        v-model="permissionSelected"
                        selectable
                        :items="listPermissions"
                        :search="searchPermission"
                        :open.sync="open"
                        open-on-click
                        return-object
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="black--text"
                  text
                  @click="show = false;"
                >
                  cancelar
                </v-btn>
                <v-btn
                  v-if="resourceInsertEdit"
                  class="blue--text"
                  text
                  @click.stop="onPermissionSave"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <v-overlay
          :value="loadingDialogUser"
          absolute
        >
          <v-card-text>
            Aguarde...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-overlay>
      </v-card>
    </v-dialog>

    <file-upload
      v-model="showFileUpload"
      extensions="jpe,jpeg,jpg,tiff,png,webp,bmp"
      accept="image/*"
      :multiselect="false"
      @insertFiles="onSelectImage"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import CommercialTab from "@/Domains/Registrations/User/Components/Form/CommercialTab.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

export default {
  name: "DialogUser",

  components: {
    CommercialTab,
    MaskedTextField,
    FileUpload,
    FileViewer,
    PersonAutocompleteFilter,
  },

  props: {
    value: {
      type: Boolean,
    },
    idUser: {
      type: String,
    },
  },

  data() {
    return {

      validForm: true,

      CPFMask: {
        mask: "000.000.000-00",
        maxlength: 14,
      },
      CEPMask: {
        mask: "00000-000",
        maxlength: 9,
      },
      PhoneMask: {
        mask: "(00) 00000-0000",
      },

      tab: null,

      loadingDialogUser: false,

      showSenha: false,

      dataUser: {
        arrayCargo: {},
        transportadores: []
      },

      officeItems: [],

      listLaticinios: [],

      laticinioSelected: [],

      listModulosMobile: ['visita', 'coleta', 'plataforma', 'comercial'],

      listSubmodules: [],

      modulosMobile: [],

      submodulesMobile: [],

      listProducers: [],

      listProspecteds: [],

      producersSelected: [],

      prospectedsSelected: [],

      listCustomers: [],

      customersSelected: [],

      listPermissions: [],

      permissionSelected: [],

      routeColect: [],

      routeAssistance: [],

      searchPermission: '',

      allOpened: false,

      open: [1],

      lastOpen: [],

      resourceInsertEdit: false,

      resourceRemakeAccess: false,

      cooperatives: [],

      showFileUpload: false,

      signature: null,

      listAllRoutes: [{ name: 'Todas', id_route: 'Todas' }],

      listRoutesProducerAccess: [],

      listRoutesProducerAccessSelected: [],

      listRegionsProducers: [],

      listRegionsProducersSelected: [],
    };
  },

  computed: {
    show: {
      get() {
        if (this.value && this.idUser) {
          this.loadEditUser();
        }
        return this.value;
      },
      set(value) {
        if (!value) {
          this.reset();
        }
        this.$emit("input", value);
      },
    },

    isCommercialUser() {
      return this.dataUser.arrayCargo.id_cargo === 13;
    },

    isAnalystUser() {
      return this.dataUser.arrayCargo.id_cargo === 12;
    },

    isTechnicianOrDriverUser() {
      return [3, 4, 6, 15, 16].includes(this.dataUser.arrayCargo.id_cargo);
    },

    isDriverUser() {
      return this.modulosMobile.length === 1 && this.modulosMobile.includes('coleta');
    },

    showVisitSubModules() {
      return this.modulosMobile.includes('visita') && this.$store.state.settings.gerais.app_visita_com_submodulos
    },

    totalProducersSelected() {
      return this.producersSelected.length
    },

    totalGroupsSelected() {
      return this.producersSelected.filter(p => p.tipo_condominio).length
    },

    totalProducersWithoutGroupSelected() {
      return this.producersSelected.reduce((acc, p) => acc + (!p.tipo_condominio ? 1 : p.producers.length), 0)
    },

    totalProspectedSelected() {
      return this.prospectedsSelected.length
    },

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasInsertEditUserAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "user-edit-access" && o.tipo === "COMPONENTE");
    },

    hasInsertEditUserPermit() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "user-edit-permission" && o.tipo === "COMPONENTE");
    },

    hasPermissionToAccessUserUnits() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "permission-to-access-user-units" && o.tipo === "COMPONENTE");
    },
  },

  mounted() {
    this.loadResourcesUser();
    this.loadCooperatives();
    this.loadRoles();
  },

  methods: {

    reset() {
      this.tab = null;
      this.dataUser = { arrayCargo: {} };
      this.routeColect = [];
      this.routeAssistance = [];
      this.listLaticinios = [];
      this.laticinioSelected = [];
      this.modulosMobile = [];
      this.listSubmodules = [];
      this.submodulesMobile = [];
      this.listProducers = [];
      this.listProspecteds = [];
      this.producersSelected = [];
      this.prospectedsSelected = [];
      this.listPermissions = [];
      this.listCustomers = [];
      this.customersSelected = [];
      this.permissionSelected = [];
      this.$refs.formUser && this.$refs.formUser.resetValidation();
      this.signature = null;
      this.listRoutesProducerAccess = [];
      this.listRoutesProducerAccessSelected = [];
      this.listRegionsProducers = [];
      this.listRegionsProducersSelected = [];
      this.transportadores = [];
    },

    /**
     * carrega os recursos que usuario tem permissao para acessar
     *
     */
    loadResourcesUser() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      const resourceInsertEdit = recursosUsuario.find(recurso => recurso.recurso === "user-insert-edit" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(resourceInsertEdit)) {
        this.resourceInsertEdit = false;
      } else {
        this.resourceInsertEdit = true;
      }

      const remakeAccess = recursosUsuario.find(recurso => recurso.recurso === "user-remake-access-fs" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(remakeAccess)) {
        this.resourceRemakeAccess = false;
      } else {
        this.resourceRemakeAccess = true;
      }

    },

    /**
     * Lista as cooperativas cadastradas
     */
    async loadCooperatives() {
      try {

        const { data } = await this.$axios.post(`/cooperativa/listaJson`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.cooperatives = data.map(item => ({
          id: item.id_cooperativa,
          name: item.nome_cooperativa,
        }));
      } catch (error) {
        console.log(error);
      }
    },

    handleSearchPermission: function (val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },

    async validaEmail() {
      try {

        const { data } =  await this.$axios.post(
          `/pessoa/validaEmail`,
          this.$qs.stringify({
            id_pessoa: this.dataUser.id_pessoa,
            email: this.dataUser.email.trim(),
          })
        );

        if (!_.isEmpty(data)) {
          this.$snotify.error("Esse email já esta cadastrado!", "Atenção");
          this.dataUser.email = '';
        }

      } catch (error) {
        console.warn(error);
      }
    },

    async searchAddress() {
      let cep = this.dataUser.end_cep;
      let data;

      cep = cep.replace("-", "");
      cep = cep.replace(".", "");

      await this.$axios.get(`///viacep.com.br/ws/${cep}/json`)
        .then(function (response) {
          // handle success
          data = response.data;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      if (data) {
        this.handleAddress(data);
      }

    },

    handleAddress(data) {
      this.dataUser.end_rua = data.logradouro;
      this.dataUser.end_bairro = data.bairro;
      this.dataUser.end_cidade = data.localidade;
      this.dataUser.end_estado = data.uf;
    },

    async onUserSave() {

      if (!this.resourceInsertEdit) {
        this.$snotify.error("Usuário sem permissão para inserir ou editar !", "Atenção");
        return;
      }

      if (!await this.$refs.formUser.validate()) {
        return;
      }

      let pes = this.dataUser;

      try {

        this.loadingDialogUser = true;
        const request = {
          id_pessoa: pes.id_pessoa,
          tipo_usuario: 1,
          nome: pes.nome,
          id_cargo: pes.arrayCargo.id_cargo,
          cargo: pes.arrayCargo.cargo,
          telefone: pes.telefone,
          celular: pes.celular,
          email: pes.email.trim(),
          senha: pes.senha,
          cnpj_cpf: pes.cnpj_cpf,
          rg: pes.rg,
          orgao_expedidor: pes.orgao_expedidor,
          uf_expedicao: pes.uf_expedicao,
          tipo_admin: pes.tipo_admin,
          end_rua: pes.end_rua,
          end_bairro: pes.end_bairro,
          end_numero: pes.end_numero,
          end_cep: pes.end_cep,
          end_cidade: pes.end_cidade,
          end_estado: pes.end_estado,
          cooperativas: pes.cooperatives ? JSON.stringify(pes.cooperatives) : null,
          assinatura: pes.assinatura,
          transportadores: pes.transportadores ? JSON.stringify(pes.transportadores) : null,
          cnh: pes.cnh,
          data_vencimento_cnh: pes.data_vencimento_cnh,
        };

        if (this.isCommercialUser) {
          request.desconto_maximo_comercial = pes.desconto_maximo_comercial;
          request.percentual_comissao = pes.percentual_comissao;
          request.id_perfil_comercial = pes.id_perfil_comercial;
        }

        const { data } = await this.$axios.post(
          `/pessoa/usuarioSalva`,
          this.$qs.stringify({
            ...request,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }

        this.$emit('save');
        this.$snotify.success("Usúario salvo!", "Sucesso");

        if (!this.dataUser.id_pessoa && pes.arrayCargo && (pes.arrayCargo.cargo == 'Técnico' || pes.arrayCargo.cargo == 'Motorista')) {
          const [ { id_pessoa } ] = data;
          const [ { id_laticinio } ] = data;

          let isNewUser = { id_pessoa, id_laticinio };

          this.onAccessSave(isNewUser);
        }
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar !", "Atenção");
      } finally {
        this.show = false;
        this.loadingDialogUser = false;
      }
    },

    async loadEditUser() {
      try {
        this.loadingDialogUser = true;

        const { data } = await this.$axios.post(`/pessoa/usuarioDetalhe`, {
          id_pessoa: this.idUser,
        });

        if (!data) {
          throw 'Erro ao buscar dados do usuário';
        }

        this.dataUser  = data[0];

        this.dataUser.senha = null;
        this.dataUser.data_vencimento_cnh = this.dataUser.data_vencimento_cnh ? moment(this.dataUser.data_vencimento_cnh).format('DD/MM/YYYY') : null;

        this.dataUser.arrayCargo = {
          id_cargo: this.dataUser.id_cargo,
          cargo: this.dataUser.cargo
        };

        this.dataUser.cooperatives = this.dataUser.cooperativas ? JSON.parse(this.dataUser.cooperativas) : null;

        this.dataUser.transportadores = this.dataUser.transportadores ? JSON.parse(this.dataUser.transportadores) : null;

        this.signature = this.dataUser.assinatura ? {
          success: true,
          name: this.dataUser.assinatura.split('/').at(-1),
          url: this.dataUser.assinatura
        } : null;

        this.loadRoute();

      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro  !", "Atenção");
      } finally {
        this.loadingDialogUser = false;
      }

    },

    async loadRoles() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaCargo`);

        this.officeItems = data;
      } catch (error) {
        console.warn(error);
      }
    },

    async loadRoute() {
      try {

        const request = {
          id_pessoa: this.idUser,
        };
        const { data } = await this.$axios.post(
          `/pessoa/listaRotaUsuario`,
          this.$qs.stringify({
            ...request,
          }),
        );

        data.map((route) => {
          if (route.id_tipo == 3) {
            this.routeColect.push(route);
          }
        });

        data.map((route) => {
          if (route.id_tipo == 1) {
            this.routeAssistance.push(route);
          }
        });

      } catch (error) {
        console.warn(error);
      }
    },

    //remove chip do autocomplete
    removeChipDairy(lat) {
      this.laticinioSelected = this.laticinioSelected.filter((dairyId) => dairyId !== lat.id_pessoa);
      //remove os produtores ativos do laticinio removido
      this.producersSelected = _.remove(this.producersSelected, (data) => data.id_laticinio !== lat.id_pessoa);
      //remove os produtores prospectados do laticinio removido
      this.prospectedsSelected = _.remove(this.prospectedsSelected, (data) => data.id_laticinio !== lat.id_pessoa);
      //remove da lista geral de produtores
      this.listProducers = _.remove(this.listProducers, (data) => data.id_laticinio !== lat.id_pessoa);

      this.listProspecteds = _.remove(this.listProspecteds, (data) => data.id_laticinio !== lat.id_pessoa);

      this.onDairiesChange();
    },

    removeChipModule(modulo) {
      this.modulosMobile = _.remove(this.modulosMobile, (data) => data !== modulo);
    },

    /**
     * Retorna os submódulos com o nó pai selecionado. Ex: PQFL, PMLS
     */
    getSubmodulesSelected() {
      const submodules = this.$refs.submodules ? this.$refs.submodules.nodes : {};

      return Object.keys(submodules).filter(item => submodules[item].isSelected || submodules[item].isIndeterminate)
    },

    async onAccessSave(isNewUser) {

      this.loadingDialogUser = true;

      try {

        if ((this.listRegionsProducersSelected ?? []).length === 0 && this.dataUser.considerar_acesso_regiao) {
          this.$snotify.error("Você optou por considerar acesso por região. É necessário selecionar ao menos uma região.", "Atenção");
          return false;
        }

        if (this.listRoutesProducerAccessSelected.length === 0 && this.dataUser.considerar_acesso_rotas) {
          this.$snotify.error("Você optou por considerar acesso por rota. É necessário selecionar ao menos uma rota.", "Atenção");
          return false;
        }

        let acesso_todos_prospectados = this.dataUser.acesso_todos_prospectados;
        let prospectadoAcesso = this.prospectedsSelected.map(produtor => produtor.id_pessoa);

        if (this.isDriverUser) {
          acesso_todos_prospectados = false;
          prospectadoAcesso = [];
        }

        if (isNewUser.id_pessoa) {
          this.dataUser.id_pessoa = isNewUser.id_pessoa;
          this.dataUser.id_laticinio = isNewUser.id_laticinio;
          this.LoadModulos();
        }

        const request = {
          id_pessoa: this.dataUser.id_pessoa,
          laticinios: this.laticinioSelected.length > 0 ? this.laticinioSelected  : [this.dataUser.id_laticinio],
          produtorAcesso: this.producersSelected.flatMap(produtor => {
            const ids = [produtor.id_pessoa];

            if (produtor.producers?.length > 0) {
              ids.push(...produtor.producers.map(producer => producer.id_pessoa));
            }

            return ids;
          }),
          prospectadoAcesso,
          pessoaAcesso: this.customersSelected.map(customer => customer.id_pessoa),
          acesso_todos: this.dataUser.acesso_todos,
          acesso_todos_prospectados,
          considerar_acesso_rotas: this.dataUser.considerar_acesso_rotas,
          considerar_acesso_regiao: this.dataUser.considerar_acesso_regiao,
          acesso_regiao: (this.listRegionsProducersSelected ?? []).length > 0 ? this.listRegionsProducersSelected : this.dataUser.acesso_regiao,
          acesso_rotas: (this.listRoutesProducerAccessSelected.length ?? []) > 0 ? this.listRoutesProducerAccessSelected : this.dataUser.acesso_rotas,
          modulosMobile: this.modulosMobile,
          submodulosMobile: this.getSubmodulesSelected(),
        };

        const { data } = await this.$axios.post(`/pessoa/salvaAcessos`, request);

        if (!data.codigo) {
          throw 'Erro ao salvar';
        }

        if (!isNewUser.id_pessoa) {
          this.$snotify.success("Acesso Salvo!", "Sucesso");
        }

      } catch (e) {
        console.log(e);
        this.$snotify.error("Oops, ocorreu um erro ao salvar acessos", "Atenção");

      } finally {
        this.loadingDialogUser = false;
      }
    },

    /*metodo usado somente pelos usuario milkup para refazer todo o acesso no FS (apaga tudo e grava*/
    async onAccessSaveFS() {

      this.loadingDialogUser = true;

      try {

        let acesso_todos_prospectados = this.dataUser.acesso_todos_prospectados;
        let prospectadoAcesso = this.prospectedsSelected.map(produtor => produtor.id_pessoa);

        if (this.isDriverUser) {
          acesso_todos_prospectados = false;
          prospectadoAcesso = [];
        }

        const request = {
          id_pessoa: this.dataUser.id_pessoa,
          id_cargo: this.dataUser.arrayCargo.id_cargo,
          cooperativas: this.dataUser.cooperatives,
          laticinios: this.laticinioSelected,
          produtorAcesso: this.producersSelected.flatMap(produtor => {
            const ids = [produtor.id_pessoa];

            if (produtor.producers?.length > 0) {
              ids.push(...produtor.producers.map(producer => producer.id_pessoa));
            }

            return ids;
          }),
          prospectadoAcesso,
          pessoaAcesso: this.customersSelected.map(customer => customer.id_pessoa),
          acesso_todos: this.dataUser.acesso_todos,
          acesso_todos_prospectados,
          considerar_acesso_rotas: this.dataUser.considerar_acesso_rotas,
          considerar_acesso_regiao: this.dataUser.considerar_acesso_regiao,
          acesso_regiao: this.dataUser.acesso_regiao,
          modulosMobile: this.modulosMobile,
          submodulosMobile: this.getSubmodulesSelected(),

        };

        const data = await this.$socket.proxy({
          method: 'post',
          url: '/pessoa/salvaAcessosRefazFS',
          data: request,
        });

        if (!data.codigo) {
          throw 'Erro ao salvar';
        }

        this.$snotify.success("Acesso Salvo!", "Sucesso");

      } catch (e) {
        console.log(e);
        this.$snotify.error("Oops, ocorreu um erro ao salvar acessos", "Atenção");

      } finally {
        this.loadingDialogUser = false;
      }
    },

    async loadUserAccess() {
      if (!this.dataUser.id_pessoa) {
        return;
      }

      try {
        this.loadingDialogUser = true;

        const [ dairies, userDairies, userModules ] = await Promise.all([
          this.getDairies(),
          this.getUserDairies(),
          this.getUserModules(),
        ]);

        this.listLaticinios = dairies;
        this.laticinioSelected = userDairies;

        if (userModules.modulos.length > 0) {
          this.modulosMobile = userModules.modulos;
        } else {
          this.LoadModulos();
        }

        this.listSubmodules = this.getSubModules();

        // Remove seleção automática de nó pai
        this.submodulesMobile = userModules.submodulos
          .filter(item => ![
            'VISITA_PADRAO',
            'VISITA_PQFL',
            'VISITA_PMLS',
            'VISITA_EMPRESA',
            'VISITA_PROSPECCAO',
            'VISITA_PEDIDO',
            'ATENDIMENTO',
            'ATEND_PADRAO',
            'ATEND_PQFL',
            'ATEND_PMLS',
            'ATEND_EMPRESA',
            'ATEND_PROSPECCAO',
            'RELATORIO',
            'RELATORIO_PQFL',
            'PEDIDO'
          ].includes(item));

      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
        this.onDairiesChange();
      }
    },

    LoadModulos() {
      if (this.dataUser.arrayCargo.cargo === 'Técnico') {
        this.modulosMobile = ['visita'];
      } else if (this.dataUser.arrayCargo.cargo === 'Motorista') {
        this.modulosMobile = ['coleta'];
      }
    },

    getSubModules() {
      const settings = this.$store.state.settings.gerais;
      const modulos = settings.modulos || [];

      const STANDARD = {
        id: 'VISITA_PADRAO',
        name: 'Visita Padrão',
        children: [],
      };

      const ASSISTANCE = {
        id: 'ATENDIMENTO',
        name: 'Atendimento Remoto',
        children: [],
      };

      const ASSIST_STANDARD = {
        id: 'ATEND_PADRAO',
        name: 'Padrão',
        children: [],
      };

      const PQFL = {
        id: 'VISITA_PQFL',
        name: 'PQFL',
        children: [
          { id: 'PQFL_CHECKLIST', name: 'Checklist Inicial' },
          { id: 'PQFL_PAE', name: 'PAE' },
          { id: 'PQFL_BPA', name: 'BPA' },
          { id: 'PQFL_QUESTIONARIO', name: 'Questionários' },
          { id: 'PQFL_FOTO', name: 'Fotos/Anexos' },
          { id: 'PQFL_RECOMENDACAO', name: 'Recomendações' },
          { id: 'PQFL_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
          { id: 'PQFL_EXAME_SANITARIO', name: 'Brucelose/Tuberculose' },
          { id: 'PQFL_VACINA', name: 'Vacinas' },
          { id: 'PQFL_INVENTARIO_REBANHO', name: 'Inventário de Rebanho' },
          { id: 'PQFL_VISITA_DEFINIDA', name: 'Visitas Definidas' },
          { id: 'PQFL_VETERINARIO', name: 'Atendimento Veterinário' },
          { id: 'PQFL_AGRONOMICO', name: 'Atendimento Agronômico' },
        ],
      };

      const ASSIST_PQFL = {
        id: 'ATEND_PQFL',
        name: 'PQFL',
        children: [
          { id: 'ATEND_PQFL_CHECKLIST', name: 'Checklist Inicial' },
          { id: 'ATEND_PQFL_PAE', name: 'PAE' },
          { id: 'ATEND_PQFL_BPA', name: 'BPA' },
          { id: 'ATEND_PQFL_QUESTIONARIO', name: 'Questionários' },
          { id: 'ATEND_PQFL_FOTO', name: 'Fotos/Anexos' },
          { id: 'ATEND_PQFL_RECOMENDACAO', name: 'Recomendações' },
          { id: 'ATEND_PQFL_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
          { id: 'ATEND_PQFL_EXAME_SANITARIO', name: 'Brucelose/Tuberculose' },
          { id: 'ATEND_PQFL_VACINA', name: 'Vacinas' },
          { id: 'ATEND_PQFL_INVENTARIO_REBANHO', name: 'Inventário de Rebanho' },
        ],
      };

      const PMLS = {
        id: 'VISITA_PMLS',
        name: 'PMLS',
        children: [
          { id: 'PMLS_CHECKLIST', name: 'Questionários' },
          { id: 'PMLS_FOTO', name: 'Fotos/Anexos' },
          { id: 'PMLS_RECOMENDACAO', name: 'Recomendações' },
          { id: 'PMLS_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
          { id: 'PMLS_VISITA_DEFINIDA', name: 'Visitas Definidas' },
          { id: 'PMLS_VETERINARIO', name: 'Atendimento Veterinário' },
          { id: 'PMLS_AGRONOMICO', name: 'Atendimento Agronômico' },
        ],
      };

      const ASSIST_PMLS = {
        id: 'ATEND_PMLS',
        name: 'PMLS',
        children: [
          { id: 'ATEND_PMLS_CHECKLIST', name: 'Questionários' },
          { id: 'ATEND_PMLS_FOTO', name: 'Fotos/Anexos' },
          { id: 'ATEND_PMLS_RECOMENDACAO', name: 'Recomendações' },
          { id: 'ATEND_PMLS_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
        ],
      };

      const COMPANY = {
        id: 'VISITA_EMPRESA',
        name: 'Empresa',
        children: [
          { id: 'EMPRESA_CHECKLIST', name: 'Questionários' },
          { id: 'EMPRESA_NEGOCIACAO', name: 'Negociação' },
          { id: 'EMPRESA_CADASTRO', name: 'Alteração de Cadastro' },
          { id: 'EMPRESA_DOCUMENTO', name: 'Documentos' },
          { id: 'EMPRESA_FOTO', name: 'Fotos/Anexos' },
          { id: 'EMPRESA_RECOMENDACAO', name: 'Recomendações' },
          { id: 'EMPRESA_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
          { id: 'EMPRESA_EMERGENCIAL', name: 'Emergenciais' },
          { id: 'EMPRESA_VISITA_DEFINIDA', name: 'Visitas Definidas' },
          { id: 'EMPRESA_VETERINARIO', name: 'Atendimento Veterinário' },
          { id: 'EMPRESA_AGRONOMICO', name: 'Atendimento Agronômico' },
          { id: 'EMPRESA_SUPERVISAO', name: 'Checklist Supervisão' },
        ],
      };

      const ASSIST_COMPANY = {
        id: 'ATEND_EMPRESA',
        name: 'Empresa',
        children: [
          { id: 'ATEND_EMPRESA_CHECKLIST', name: 'Questionários' },
          { id: 'ATEND_EMPRESA_NEGOCIACAO', name: 'Negociação' },
          { id: 'ATEND_EMPRESA_CADASTRO', name: 'Alteração de Cadastro' },
          { id: 'ATEND_EMPRESA_FOTO', name: 'Fotos/Anexos' },
          { id: 'ATEND_EMPRESA_RECOMENDACAO', name: 'Recomendações' },
          { id: 'ATEND_EMPRESA_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
          { id: 'ATEND_EMPRESA_VETERINARIO', name: 'Atendimento Veterinário' },
          { id: 'ATEND_EMPRESA_AGRONOMICO', name: 'Atendimento Agronômico' },
        ],
      };

      const PROSPECTION = {
        id: 'VISITA_PROSPECCAO',
        name: 'Visita Prospecção',
        children: [
          { id: 'PROSPECCAO_CHECKLIST', name: 'Questionários' },
          { id: 'PROSPECCAO_NEGOCIACAO', name: 'Negociação' },
          { id: 'PROSPECCAO_CADASTRO', name: 'Alteração de Cadastro' },
          { id: 'PROSPECCAO_DOCUMENTO', name: 'Documentos' },
          { id: 'PROSPECCAO_FOTO', name: 'Fotos/Anexos' },
          { id: 'PROSPECCAO_RECOMENDACAO', name: 'Recomendações' },
          { id: 'PROSPECCAO_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
          { id: 'PROSPECCAO_EXAME_SANITARIO', name: 'Brucelose/Tuberculose' },
          { id: 'PROSPECCAO_VETERINARIO', name: 'Atendimento Veterinário' },
          { id: 'PROSPECCAO_AGRONOMICO', name: 'Atendimento Agronômico' },
        ],
      };

      const ASSIST_PROSPECTION = {
        id: 'ATEND_PROSPECCAO',
        name: 'Prospecção',
        children: [
          { id: 'ATEND_PROSPECCAO_CHECKLIST', name: 'Questionários' },
          { id: 'ATEND_PROSPECCAO_NEGOCIACAO', name: 'Negociação' },
          { id: 'ATEND_PROSPECCAO_CADASTRO', name: 'Alteração de Cadastro' },
          { id: 'ATEND_PROSPECCAO_FOTO', name: 'Fotos/Anexos' },
          { id: 'ATEND_PROSPECCAO_RECOMENDACAO', name: 'Recomendações' },
          { id: 'ATEND_PROSPECCAO_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
          { id: 'ATEND_PROSPECCAO_EXAME_SANITARIO', name: 'Brucelose/Tuberculose' },
          { id: 'ATEND_PROSPECCAO_VETERINARIO', name: 'Atendimento Veterinário' },
          { id: 'ATEND_PROSPECCAO_AGRONOMICO', name: 'Atendimento Agronômico' },
        ],
      };

      const CUSTOMER = {
        id: 'VISITA_PEDIDO',
        name: 'Visita Cliente',
        children: [
          { id: 'CLIENTE_CADASTRO', name: 'Alteração de Cadastro' },
          { id: 'CLIENTE_VETERINARIO', name: 'Atendimento Veterinário' },
          { id: 'CLIENTE_AGRONOMICO', name: 'Atendimento Agronômico' },
        ],
      };

      const REPORTS_PQFL = {
        id: 'RELATORIO_PQFL',
        name: 'PQFL',
        children: [
          { id: 'RELATORIO_PQFL_CHECKLIST', name: 'Checklist' },
          { id: 'RELATORIO_PQFL_PAE', name: 'PAE' },
          { id: 'RELATORIO_PQFL_BPA', name: 'BPA' },
          { id: 'RELATORIO_PQFL_EXAME_SANITARIO', name: 'Brucelose/Tuberculose' },
        ],
      };

      const REPORTS = {
        id: 'RELATORIO',
        name: 'Relatórios',
        children: [
          REPORTS_PQFL,
          { id: 'RELATORIO_INDICADOR', name: 'Indicadores' },
          { id: 'RELATORIO_ITINERARIO', name: 'Itinerários' },
          { id: 'RELATORIO_COLETA', name: 'Coletas' },
          { id: 'RELATORIO_VISITA', name: 'Visitas/Atendimento' },
          { id: 'RELATORIO_COLETA_AMOSTRA', name: 'Coleta de Amostras' },
          { id: 'RELATORIO_RECOMENDACAO', name: 'Recomendações' },
          { id: 'RELATORIO_OUTRA_ATIVIDADE', name: 'Outras Atividades' },
          { id: 'RELATORIO_VETERINARIO', name: 'Atendimento Veterinário' },
          { id: 'RELATORIO_AGRONOMICO', name: 'Atendimento Agronômico' },
        ],
      };

      if (modulos.includes('PEDIDOS')) {
        COMPANY.children.push({ id: 'EMPRESA_PEDIDO', name: 'Vendas' });
        ASSIST_COMPANY.children.push({ id: 'ATEND_EMPRESA_PEDIDO', name: 'Vendas' });
        PROSPECTION.children.push({ id: 'PROSPECCAO_PEDIDO', name: 'Vendas' });
        ASSIST_PROSPECTION.children.push({ id: 'ATEND_PROSPECCAO_PEDIDO', name: 'Vendas' });
        CUSTOMER.children.push({ id: 'CLIENTE_PEDIDO', name: 'Vendas' });
      }

      const submodules = [];

      STANDARD.children.push(PQFL, PMLS, COMPANY);
      ASSIST_STANDARD.children.push(ASSIST_PQFL, ASSIST_PMLS, ASSIST_COMPANY);

      submodules.push(STANDARD, PROSPECTION);
      ASSISTANCE.children.push(ASSIST_STANDARD, ASSIST_PROSPECTION);

      submodules.push(
        CUSTOMER,
        ASSISTANCE,
        { id: 'LISTA_PRODUTOR', name: 'Listagem de Produtores' },
        { id: 'MAPA_PRODUTOR', name: 'Mapa de Produtores' },
        { id: 'OUTRA_ATIVIDADE', name: 'Outras Atividades' },
        REPORTS,
        { id: 'CRONOGRAMA', name: 'Cronograma' },
        { id: 'CHECKLIST', name: 'Checklists' },
        { id: 'MANUTENCAO', name: 'Manutenções' },
        { id: 'CHAT', name: 'Chat' },
      );

      if (modulos.includes('PEDIDOS')) {
        submodules.push({
          id: 'PEDIDO',
          name: 'Vendas',
          children: [
            { id: 'PEDIDO_PRODUTOR', name: 'Produtor' },
            { id: 'PEDIDO_COMERCIAL', name: 'Comercial' },
          ],
        });
      }

      return submodules
    },

    async getDairies() {
      if (this.listLaticinios.length > 0) {
        return this.listLaticinios;
      }
      const { data } = await this.$axios.post(`/pessoa/listaLaticinioJson`);
      return data;
    },

    async getUserDairies() {
      const { data } = await this.$axios.post(`/pessoa/usuarioAcessoLaticinio`, {
        id_pessoa: this.dataUser.id_pessoa,
      });
      return data.map(item => item.id_pessoa);
    },

    async getUserModules() {
      const { data } = await this.$axios.post(`/pessoa/usuarioModulosMobile`, {
        id_pessoa: this.dataUser.id_pessoa,
      });
      return data;
    },

    onDairiesChange() {
      if (this.isCommercialUser) {
        this.loadCustomers();
      }
      else {
        this.loadProducers();
      }
    },

    async loadProducers() {
      try {
        this.loadingDialogUser = true;
        const campos = ['codigo_laticinio', 'id_pessoa', 'nome', 'id_laticinio', 'ativo', 'tipo_condominio', 'id_condominio'];
        const laticinios = this.laticinioSelected;
        const cooperativas = this.dataUser.cooperatives;

        const [{ data: producers }, { data: prospecteds }, { data: unlinkeds }] = await Promise.all([
          this.$axios.post(`/pessoa/listaProdutorJson`, { campos, laticinios, cooperativas, status: 'ATIVO' }),
          this.$axios.post(`/pessoa/listaProdutorJson`, { campos, laticinios, cooperativas, status: 'PROSPECTADO' }),
          this.$axios.post(`/pessoa/listaProdutorJson`, { campos, laticinios, cooperativas, status: 'DESVINCULADO' }),
        ]);

        this.listProspecteds = prospecteds.concat(unlinkeds);

        // Adiciona groupId para agrupar os produtores de agrupamento
        const producersWithGroupId = producers.map((producer) => ({
          ...producer,
          groupId: producer.id_condominio || producer.id_pessoa
        }))

        this.listProducers = _(producersWithGroupId)
          .groupBy('groupId')
          .map((items, groupId) => {
            if (items.length === 1) {
              const producer = items[0]

              if (!producer.tipo_condominio) {
                return {
                  ...producer,
                  search: (`${producer.codigo_laticinio || ''} - ${producer.nome}`).toLocaleLowerCase()
                };
              }
            }

            const group = items.find(item => item.id_pessoa === groupId);
            const producers = items.filter(item => item.id_pessoa !== groupId);
            return {
              ...group,
              producers,
              search: items.map(item => `${item.codigo_laticinio || ''} - ${item.nome}`).join(' - ').toLocaleLowerCase()
            }
          }).value();

      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
        this.loadAccessProducers();
      }
    },

    async loadAccessProducers() {
      this.loadingDialogUser = true;
      try {
        const { data } = await this.$axios.post(
          `/pessoa/usuarioAcessoProdutor`,
          {
            id_pessoa: this.dataUser.id_pessoa,
            cooperativas: this.dataUser.cooperatives,
            laticinios: this.laticinioSelected,
          }
        );
        const activeIds = data.filter(prod => prod.ativo).map(prod => prod.id_pessoa);
        this.producersSelected = [...this.listProducers.filter(prod => activeIds.includes(prod.id_pessoa))];
        this.prospectedsSelected = data.filter(prod => !prod.ativo);
      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
      }
    },

    async loadCustomers() {
      this.loadingDialogUser  = true;
      try {
        const { data } = await this.$axios.get(`/pessoa/listaClientes`);

        this.listCustomers = data;

      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
        this.loadAccessPersons();
      }
    },

    async loadAccessPersons() {
      this.loadingDialogUser = true;
      try {
        const { data } = await this.$axios.post(
          `/pessoa/usuarioAcessoPessoa`,
          this.$qs.stringify({
            id_pessoa: this.dataUser.id_pessoa,
          })
        );

        this.customersSelected = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
      }
    },

    //remove chip do autocomplete
    removeChipProducer(producer) {
      this.producersSelected = _.remove(this.producersSelected, (data) => data.id_pessoa !== producer.id_pessoa);
    },

    //remove chip do autocomplete
    removeChipProspected(producer) {
      this.prospectedsSelected = _.remove(this.prospectedsSelected, (data) => data.id_pessoa !== producer.id_pessoa);
    },

    async clearProducerAccess() {
      if (!(await this.$root.$confirm("Atenção", "Deseja remover o acesso de todos os produtores ativos?", { color: "red", noText: 'Não' }))) {
        return;
      }

      this.producersSelected = [];
    },

    async clearProspectedAccess() {
      if (!(await this.$root.$confirm("Atenção", "Deseja remover o acesso de todos os produtores prospectados?", { color: "red", noText: 'Não' }))) {
        return;
      }

      this.prospectedsSelected = [];
    },

    //remove chip do autocomplete
    removeChipCustomer({ id_pessoa }) {
      this.customersSelected = this.customersSelected.filter(data => data.id_pessoa !== id_pessoa);
    },

    async clearCustomerAccess() {
      if (!(await this.$root.$confirm("Atenção", "Deseja remover o acesso de todos os clientes?", { color: "red", noText: 'Não' }))) {
        return;
      }
      this.customersSelected = [];
    },

    async loadPermissions() {
      if (!this.dataUser.id_laticinio) {
        return;
      }
      try {
        this.loadingDialogUser = true;

        const { data } = await this.$axios.post(
          `/permissaoPessoa/listaPermissao`,
          this.$qs.stringify({
            id_laticinio: this.dataUser.id_laticinio,
          })
        );

        this.listPermissions = data;

      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
        this.loadPermissionsUser();
      }
    },

    async onPermissionSave() {

      this.loadingDialogUser = true;

      try {
        const request = {
          id_pessoa: this.dataUser.id_pessoa,
          id_laticinio: this.dataUser.id_laticinio,
          permissoes: JSON.stringify(this.permissionSelected),

        };

        const { data } = await this.$axios.post(
          `/permissaoPessoa/salva`,
          this.$qs.stringify({
            ...request,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }

        this.$snotify.success("Permissões Salvas!", "Sucesso");

      } catch (e) {
        console.log(e);
        this.$snotify.error("Oops, ocorreu um erro ao salvar permissoes!", "Atenção");

      } finally {
        this.loadingDialogUser = false;
      }
    },

    async loadPermissionsUser() {
      this.loadingDialogUser = true;
      try {
        const { data } = await this.$axios.post(
          `/permissaoPessoa/listaPermissaoPessoa`,
          this.$qs.stringify({
            id_pessoa: this.dataUser.id_pessoa,
            id_laticinio: this.dataUser.id_laticinio,
          })
        );
        this.permissionSelected = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
      }
    },

    onSelectImage([img]) {
      this.signature = img;
      this.dataUser.assinatura = img.url;
    },

    onRemoveImage() {
      this.signature = null;
      this.dataUser.assinatura = null;
    },

    async listAllRoutesAccess() {

      this.loadingDialogUser = true;

      await this.loadAccessProducers();

      const producers = this.producersSelected.map((producer) => {
        return producer.id_pessoa
      });

      try {
        const { data } = await this.$axios.post(
          `/permissaoPessoa/listaRotasProdutor`,
          this.$qs.stringify({
            producers: JSON.stringify(producers),
          })
        );

        const listRoutesProducer = data;

        this.listRoutesProducerAccess = listRoutesProducer.map((route) => {
          return {
            name: route.descricao,
            id_route: route.id_rota
          };
        });

        this.listRoutesProducerAccessSelected = this.dataUser.acesso_rotas ? JSON.parse(this.dataUser.acesso_rotas) : this.listRoutesProducerAccess ;

      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
      }
    },

    async listAllRegionsProducers() {

      if (this.dataUser.acesso_todos) {
        this.listRegionsProducers = await this.listAllRegions();
        this.listRegionsProducersSelected = JSON.parse(this.dataUser.acesso_regiao);
      } else {
        this.loadingDialogUser = true;

        await this.loadAccessProducers();

        const producers = this.producersSelected.map((producer) => {
          return producer.id_pessoa
        });

        try {
          const { data } = await this.$axios.post(
            `/permissaoPessoa/listaRegiaoProdutor`,
            this.$qs.stringify({
              producers: JSON.stringify(producers),
            })
          );

          this.listRegionsProducers = data;
          this.listRegionsProducersSelected = JSON.parse(this.dataUser.acesso_regiao);

        } catch (error) {
          console.warn(error);
        } finally {
          this.loadingDialogUser = false;
        }
      }
    },

    async listAllRegions() {
      try {
        this.loadingDialogUser = true;
        const { data } = await this.$axios.post(`/regiao/listaJson`, this.$qs.stringify({
          tipo: ''
        }));

        const regions = data.map((region) => {
          return {
            id_regiao: region.id_regiao,
            name: region.nome.toUpperCase(),
          }
        });

        return regions;

      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogUser = false;
      }
    },

    checkWeakPassword(password) {
      if (!password) {
        return true
      }

      if (password.length < 8) {
        return 'A senha deve ter no mínimo 8 caracteres'
      }

      if (this.passwordStrength(password) < 3) {
        return 'A senha deve conter letras números e símbolos'
      }

      return true
    },

    passwordStrength(password) {
      if (password?.length === 1) {
        return 0
      }

      let strength = 0

      strength += /[A-Z]+/.test(password) ? 1 : 0
      strength += /[a-z]+/.test(password) ? 1 : 0
      strength += /[0-9]+/.test(password) ? 1 : 0
      strength += /[\W]+/.test(password) ? 1 : 0

      return strength
    },

    passwordProgress(password) {
      const strength = this.passwordStrength(password)

      const values = {
        1: 25,
        2: 50,
        3: 75,
        4: 100,
      }

      const colors = {
        1: 'orange',
        2: 'yellow',
        3: 'light-green',
        4: 'green',
      }

      return {
        value: values[strength] || 0,
        color: colors[strength] || 'red',
      }
    },

    async loadUserFinanceAccess() {
      await this.loadUserAccess();
      await this.listAllRoutesAccess();
      await this.listAllRegionsProducers();
    },
  },
}
</script>
