<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.project"
          :items="goalList"
          label="Vigência do Projeto"
          item-value="dateStart"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="loadRange"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <month-range-picker
          v-model="filter.range"
          :ranges.sync="filter.ranges"
          :min="filter.min"
          :max="filter.max"
          dark
        />
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      dark
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#visited-tab">
        <v-icon class="mr-2">
          check_circle_outline
        </v-icon>
        Estão no Projeto
      </v-tab>
      <v-tab href="#not-visited-tab">
        <v-icon class="mr-2">
          error_outline
        </v-icon>
        Saíram do Projeto
      </v-tab>

      <v-tab-item value="visited-tab">
        <v-card
          dark
          class="v-tamanho report-card"
          color="transparent"
        >
          <v-card-title class="py-1">
            <v-col
              cols="4"
              md="2"
            >
              <v-card-text>
                <p class="white--text">
                  Produtores
                </p>
                <v-card
                  class="mx-70 px-20 pt-0"
                  color="transparent"
                  dark
                  flat
                >
                  <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
                    Total {{ formatNumber(totalProducers) }}
                  </v-card-title>
                </v-card>
              </v-card-text>
            </v-col>

            <v-col
              cols="4"
              md="2"
            >
              <v-card-text>
                <p class="white--text">
                  Litros
                </p>

                <v-card
                  class="report-card"
                  color="transparent"
                  dark
                  flat
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-card-title
                        v-bind="attrs"
                        class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold "
                        v-on="on"
                      >
                        Média {{ formatNumber(litersAverage) }}
                      </v-card-title>
                    </template>
                    Volume média dos ultimos 6 dias
                  </v-tooltip>
                </v-card>
              </v-card-text>
            </v-col>

            <v-spacer />

            <v-col
              cols="4"
              md="3"
              class="pa-0"
            >
              <v-text-field
                v-model="search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
              />
            </v-col>
          </v-card-title>

          <data-table
            ref="report"
            name="Produtores PMLS"
            :headers="headers"
            :items="listProducer"
            :loading="loading"
            :search="search"
            :items-per-page="-1"
            dark
            show-custom-group
          >
            <template #[`item.data_cadastro_app`]="{ value }">
              <v-tooltip
                v-if="value"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    small
                    class="ma-2"
                    v-on="on"
                  >
                    <v-icon>
                      phone_iphone
                    </v-icon>
                  </v-btn>
                </template>
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </v-tooltip>
              <v-chip
                v-else
                class="ma-2"
                outlined
                x-small
              >
                -
              </v-chip>
            </template>

            <template #[`item.tecnicos`]="{ value }">
              <div
                v-for="(tec, i) in value"
                :key="i"
              >
                <v-chip
                  v-if="tec.tecnico"
                >
                  {{ tec.tecnico }} {{ tec.rota }}
                </v-chip>
              </div>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="hasUnlinkAccess"
                    @click="openRegisterProducer('unlink', item)"
                  >
                    <v-list-item-icon>
                      <v-icon>person_add_disabled</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Desvincular</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasInsertUpdateAccess"
                    @click="openRegisterProducer('link', item)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="!hasUnlinkAccess && !hasInsertUpdateAccess"
                  >
                    <v-list-item-icon>
                      <v-icon>visibility_off</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Usuário sem permissões!</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-card>

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template #activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-btn
            v-if="hasInsertUpdateAccess"
            fab
            color="#008890"
            @click.stop="openRegisterProducer('link')"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  person_add
                </v-icon>
              </template>
              Incluir Produtor
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  print
                </v-icon>
              </template>

              Imprimir
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
      </v-tab-item>

      <v-tab-item value="not-visited-tab">
        <v-card
          dark
          class="v-tamanho report-card"
          color="transparent"
        >
          <v-card-title class="py-1">
            <v-col
              cols="4"
              md="2"
            >
              <v-card-text>
                <p class="white--text">
                  Produtores
                </p>
                <v-card
                  class="mx-70 px-20 pt-0"
                  color="transparent"
                  dark
                  flat
                >
                  <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
                    Total {{ formatNumber(totalProducersNotVisited) }}
                  </v-card-title>
                </v-card>
              </v-card-text>
            </v-col>

            <v-col
              cols="4"
              md="2"
            >
              <v-card-text>
                <p class="white--text">
                  Litros
                </p>

                <v-card
                  class="report-card"
                  color="transparent"
                  dark
                  flat
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-card-title
                        v-bind="attrs"
                        class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold "
                        v-on="on"
                      >
                        Média {{ formatNumber(litersAverageNotVisited) }}
                      </v-card-title>
                    </template>
                    Volume média dos ultimos 6 dias
                  </v-tooltip>
                </v-card>
              </v-card-text>
            </v-col>

            <v-spacer />

            <v-col
              cols="4"
              md="3"
              class="pa-0"
            >
              <v-text-field
                v-model="search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
              />
            </v-col>
          </v-card-title>

          <data-table
            ref="reportNotVisited"
            name="Produtores que sairam do projeto"
            :headers="headersNotPMLS"
            :items="listProducerNotProject"
            :loading="loading"
            :search="search"
            :items-per-page="-1"
            dark
            show-custom-group
          >
            <template #[`item.tecnicos`]="{ value }">
              <div
                v-for="(tec, i) in value"
                :key="i"
              >
                <v-chip
                  v-if="tec.tecnico"
                >
                  {{ tec.tecnico }} {{ tec.rota }}
                </v-chip>
              </div>
            </template>
            <template #[`item.data_cadastro_app`]="{ value }">
              <v-tooltip
                v-if="value"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    small
                    class="ma-2"
                    v-on="on"
                  >
                    <v-icon>
                      phone_iphone
                    </v-icon>
                  </v-btn>
                </template>
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </v-tooltip>
              <v-chip
                v-else
                class="ma-2"
                outlined
                x-small
              >
                -
              </v-chip>
            </template>
          </data-table>
        </v-card>

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template #activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcelNotVisited()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="printNotVisited()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  print
                </v-icon>
              </template>

              Imprimir
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
      </v-tab-item>
    </v-tabs>

    <v-dialog
      v-model="modalView.show"
      :item="modalView"
      max-width="350px"
      hide-overlay
    >
      <v-card
        light
        elevation="1"
      >
        <v-card-text>
          <v-flex
            xs12
            class="font-weight-black"
          >
            <br>
            Deseja {{ typeTitle }} um Produtor?
            <br>
          </v-flex>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-row v-if="typeTitle === 'vincular'">
              <v-col sm6>
                <v-autocomplete
                  v-model="producer.selected"
                  :items="producer.list"
                  label="Produtor"
                  return-object
                  prepend-icon="person_outline"
                  :rules="[v => !!v || 'Campo obrigatório']"
                  item-text="name"
                  item-value="id_produtor"
                />
              </v-col>
            </v-row>

            <v-row v-if="typeTitle === 'desvincular' || typeTitle ==='editar'">
              <v-col sm6>
                <v-text-field
                  v-model="producer.selected.nome_produtor"
                  prepend-icon="person_outline"
                  item-text="name"
                  item-value="id_produtor"
                  disabled
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm6>
                <date-text-field
                  v-model="data"
                  :label="label"
                  :min="formatDate(filter.range[0], 'YYYY-MM-DD')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm6>
                <div>*somente grupos cadastrados nas configurações estarão disponíveis </div>
                <v-select
                  v-model="producer.selected.id_projeto_grupo"
                  :items="listGroup"
                  label="Grupo"
                  item-value="id"
                  item-text="description"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row v-if="typeTitle === 'desvincular'">
              <v-col sm6>
                <v-text-field
                  v-model="reason"
                  label="Motivo"
                  :rules="[v => !!v || 'Campo obrigatório']"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="pa-0 ma-0" />

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="v-btt2 black--text pa-0"
            text
            color="red darken-1"
            @click="closeRegisterProducer"
          >
            Não
          </v-btn>
          <v-btn
            class="v-btt2 black--text pa-0"
            text
            color="blue darken-1"
            @click="save"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay v-model="loading">
      <v-card-text>
        Aguarde...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import _ from "lodash";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import DateTextField from "@/Support/Components/DateTextField.vue";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";

export default {
  name: "ActiveProducers",

  components: {
    DateTextField,
    MonthRangePicker,
  },

  mixins: [ReportMixin],

  props: {
    aba: String,
    type: {
      type: String,
      default: 'link',
      validator(value) {
        return ['link', 'unlink'].indexOf(value) !== -1
      }
    },
  },

  data() {
    return {
      loading: false,

      tab: null,

      filter: {
        project: {
          dateStart: null,
          dateEnd: null,
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        // producer: [],
      },

      goalList: [],

      search: '',

      headers: [
        { text: "C. Laticínio", value: "codigo_laticinio", align: "center" },
        { text: "Produtor", value: "nome_produtor", width: 250  },
        { text: "Entrada", value: "data_entrada", align: "center" },
        { text: "Última Visita", value: "ultima_visita", align: "center" },
        { text: "Qte Visitas", value: "quantidade_visita", align: "center" },
        { text: "Técnico", value: "tecnicos", width: 250  },
        { text: "CCS", value: "ccs", align: "center" },
        { text: "Grupo", value: "nome_grupo", align: "center" },
        { text: "CPP", value: "cbt", align: "center" },
        { text: "CPF/CNPJ do Titular", value: "cnpj_cpf", align: "center" },
        { text: "Nº Nirf", value: "nirf", align: "center" },
        { text: "Código NRP", value: "nrp", align: "center" },
        { text: "Sif", value: "sif", align: "center" },
        { text: "Estado", value: "end_estado", align: "center" },
        { text: "Inscrição Estadual", value: "inscricao_estadual", align: "center" },
        { text: "Região", value: "regiao", width: 160   },
        { text: "CEP", value: "end_cep", align: "center" },
        // { text: "Rota", value: "rota",  width: 250  },
        { text: "Latitude", value: "end_latitude", align: "center" },
        { text: "Longitude", value: "end_longitude", align: "center" },
        { text: "Distância", value: "distancia_industria", align: "center" },
        { text: "Opções", value: "action", sortable: false, align: "end" },
      ],

      headersNotPMLS: [
        { text: "C. Laticínio", value: "codigo_laticinio", align: "center" },
        { text: "Produtor", value: "nome_produtor", width: 250  },
        { text: "Entrada", value: "data_entrada", align: "center" },
        { text: "Saída", value: "data_saida", align: "center" },
        { text: "Motivo", value: "motivo", align: "center" },
        { text: "Última Visita", value: "ultima_visita", align: "center" },
        { text: "Qte Visitas", value: "quantidade_visita", align: "center" },
        { text: "Técnico", value: "tecnicos", width: 250  },
        { text: "CCS", value: "ccs", align: "center" },
        { text: "CPP", value: "cbt", align: "center" },
        { text: "CPF/CNPJ do Titular", value: "cnpj_cpf", align: "center" },
        { text: "Nº Nirf", value: "nirf", align: "center" },
        { text: "Código NRP", value: "nrp", align: "center" },
        { text: "Sif", value: "sif", align: "center" },
        { text: "Estado", value: "end_estado", align: "center" },
        { text: "Inscrição Estadual", value: "inscricao_estadual", align: "center" },
        { text: "Região", value: "regiao", width: 160   },
        { text: "CEP", value: "end_cep", align: "center" },
        // { text: "Rota", value: "rota",  width: 250  },
        { text: "Latitude", value: "end_latitude", align: "center" },
        { text: "Longitude", value: "end_longitude", align: "center" },
        { text: "Distância", value: "distancia_industria", align: "center" },
        { text: "Opções", value: "action", sortable: false, align: "end" },
      ],

      listProducer: [],

      listGroup: [],

      producer: {
        selected: {
          id_projeto_grupo: null,
        },
        list: [],
        loading: false,
      },

      modalView: {
        show: false,
        id_produtor: null,
        name: '',
        selected: null
      },

      data: moment().format('YYYY-MM-DD'),

      id_produtor_pmls: null,

      reason: '',

      typeTitle: '',

      label: '',

      listProducerNotProject: [],
    };
  },

  computed: {
    totalProducers() {
      return this.listProducer.length;
    },

    litersAverage() {
      const litros = Math.abs(this.listProducer.reduce((acc, item) => (acc + parseInt(item.litros || 0)), 0));
      return litros ? litros / this.totalProducers : 0;
    },

    totalProducersNotVisited() {
      return this.listProducerNotProject.length;
    },

    litersAverageNotVisited() {
      const litros = Math.abs(this.listProducerNotProject.reduce((acc, item) => (acc + parseInt(item.litros || 0)), 0));
      return litros ? litros / this.totalProducersNotVisited : 0;
    },

    /**
     * Permissões de tela
     */
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    /**
     * As permissões serão liberadas apenas para a DEALE
     */
    isDeale() {
      return [
        "0ba06f62-d52f-4674-81b7-5af0cda6e4ed",
        "c22b3e38-7425-4fb1-be34-aba24c36c0be",
        "7e83b50b-8038-4be3-af5a-7362f40f23d3",
        "62494d93-cb01-4f79-976c-3d9a0ed86a5b"
      ].includes(this.$store.state.settings.gerais.id_laticinio)
    },

    hasInsertUpdateAccess() {
      return this.isAdmin || !this.isDeale || this.userResources.some(o => o.recurso === "producer-insert-edit" && o.tipo === "COMPONENTE");
    },

    hasUnlinkAccess() {
      return this.isAdmin || !this.isDeale || this.userResources.some(o => o.recurso === "producer-unlink" && o.tipo === "COMPONENTE");
    },
  },

  watch: {
    filter: {
      handler() {
        this.loadVisits();
      },
      deep: true
    }
  },

  mounted() {
    this.loadGoal();
  },

  methods: {
    async loadRange() {
      let dateEnd = (moment() < moment(this.filter.project.dateEnd)) ? moment().format('YYYY-MM') : moment(this.filter.project.dateEnd).format('YYYY-MM');

      this.filter.max = dateEnd;
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), dateEnd];
    },

    async loadGoal() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PMLS",
          }
        );

        this.goalList = data.map((x) => {
          return {
            dateStart: x.data,
            dateEnd: x.data_fim,
            text: "De " + moment(x.data).format('MM/YYYY') + " até " + moment(x.data_fim).format('MM/YYYY'),
            quality: x.qualidade,
            visit: x.visita,
            id: x.id_assistencia,
          }
        });

        if (!_.isEmpty(this.goalList)) {
          this.filter.project = this.goalList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
        // this.loadVisits()
        // this.loadProducerSelected();
      }
    },

    async loadVisits() {
      if (this.filter.project.id) {
        if (this.aba == 'producers') {
          await this.loadListProducer();
          await this.loadProducerNotProject();
          await this.loadGrupos();
        }
      } else {
        this.listProducer = [];
        this.listProducerNotProject = [];
      }
    },

    async loadGrupos() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get('/projects/project-group', {
          params: {
            id_assistencia: this.filter.project.id,
            tipo: 'PMLS',
          }
        });

        this.listGroup = data.map(item => {
          return {
            id: item.id_projeto_grupo,
            description: item.descricao,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    async loadProducerSelected() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/produtores/listProdutor`);

        this.producer.list = data.map(producer => {
          let nome_condominio = producer.nome_condominio ? ` [${producer.nome_condominio}]` : '';

          return {
            id_produtor: producer.id_pessoa,
            name: producer.nome_produtor + nome_condominio,
          }
        });

      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    async loadListProducer() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/listaProdutorPMLS`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM'),
            id_assistencia: this.filter.project.id ?? null,
          })
        );

        this.listProducer = data.map(o => {
          let ultima_visita = '';
          let quantidade_visita = '';
          if (!_.isEmpty(o.visitas_realizadas)) {
            ultima_visita = moment(o.visitas_realizadas[0]["data_visita"]).format('DD/MM/YYYY');
            quantidade_visita = (o.visitas_realizadas).length;
          }
          return {
            ...o,
            ultima_visita: ultima_visita,
            quantidade_visita: quantidade_visita,
          }
        });

      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    async loadProducerNotProject() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/listaProdutorNotPMLS`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM'),
          })
        );

        this.listProducerNotProject = data.map(o => {
          let ultima_visita = '';
          let quantidade_visita = '';
          if (!_.isEmpty(o.visitas_realizadas)) {
            ultima_visita = moment(o.visitas_realizadas[0]["data_visita"]).format('DD/MM/YYYY');
            quantidade_visita = (o.visitas_realizadas).length;
          }
          return {
            ...o,
            ultima_visita: ultima_visita,
            quantidade_visita: quantidade_visita,
          }
        });

      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/saveProducerPMLS`,
          this.$qs.stringify({
            id_produtor: this.producer.selected.id_produtor,
            id_projeto_grupo: this.producer.selected.id_projeto_grupo,
            data: this.data,
            id_produtor_pmls: this.id_produtor_pmls,
            vincular: (this.typeTitle == "vincular" || this.typeTitle == "editar") ? 1 : 0,
            motivo: this.typeTitle == "desvincular" ? this.reason : '',
          })
        );

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.modalView.show = false;

        this.loading = true;
        if (this.typeTitle == "vincular" || this.typeTitle == "editar") {
          this.$snotify.success("Produtor vinculado/alterado com sucesso", "Sucesso");
        } else {
          this.$snotify.success("Produtor desvinculado com sucesso", "Sucesso");
        }
      } catch (error) {
        this.$snotify.error("Erro ao vincular produtor", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
        this.loadListProducer();
        this.loadProducerNotProject();
      }

    },

    openRegisterProducer(type, item) {
      this.data = moment().format('YYYY-MM-DD');
      this.id_produtor_pmls = null;

      if (type === 'link') {
        this.producer.selected = item ? item : '';
        this.typeTitle = item ? 'editar' : 'vincular';
        this.label = 'Data de Vinculação';
        if (item) {//data para editar
          this.data = moment(item.data_entrada, 'DD/MM/YYYY').format('YYYY-MM-DD');
          this.id_produtor_pmls = item.id_produtor_pmls;
        }

        this.modalView.show = true;
        this.loadProducerSelected();

      } else {
        this.modalView.id_produtor = item.id_produtor;
        this.producer.selected = item;

        this.typeTitle = 'desvincular';
        this.label = 'Data de Desvinculação';
        this.modalView.show = true;
      }

    },

    closeRegisterProducer() {
      this.modalView.show = false;
    },

    getReportJson() {
      return this.listProducer.map(item => ({
        "C. Laticínio": item.codigo_laticinio,
        "Produtor": item.nome_produtor,
        "Entrada": item.data_entrada,
        "Última Visita": item.ultima_visita,
        "Qtd Visita": item.quantidade_visita,
        "Técnico": item.tecnico,
        "CCS": item.ccs,
        "CPP": item.cbt,
        "CPF/CNPJ": item.cnpj_cpf,
        "Nº Nirf": item.nirf,
        "Código NRP": item.nrp,
        "Sif": item.sif,
        "Estado": item.end_estado,
        "Inscrição Estadual": item.inscricao_estadual,
        "Região": item.regiao,
        "CEP": item.end_cep,
        "Rota": item.rota,
        "Latitude": item.end_latitude,
        "Longitude": item.end_longitude,
        "Distância": item.distancia_industria
      }));
    },

    exportExcel() {
      const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.listProducer));
      const workbook = XLSX.utils.book_new();
      const filename = 'Produtores PMLS';
      XLSX.utils.book_append_sheet(workbook, data, _.truncate(filename, { length: 31 }));
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    getReportJsonNotVisited() {
      return this.listProducerNotProject.map(item => ({
        "C. Laticínio": item.codigo_laticinio,
        "Produtor": item.nome_produtor,
        "Entrada": item.data_entrada,
        "Saída": item.data_saida,
        "Última Visita": item.ultima_visita,
        "Qtd Visita": item.quantidade_visita,
        "Técnico": item.tecnico,
        "CCS": item.ccs,
        "CPP": item.cbt,
        "CPF/CNPJ": item.cnpj_cpf,
        "Nº Nirf": item.nirf,
        "Código NRP": item.nrp,
        "Sif": item.sif,
        "Estado": item.end_estado,
        "Inscrição Estadual": item.inscricao_estadual,
        "Região": item.regiao,
        "CEP": item.end_cep,
        "Rota": item.rota,
        "Latitude": item.end_latitude,
        "Longitude": item.end_longitude,
        "Distância": item.distancia_industria
      }));
    },

    exportExcelNotVisited() {
      const data = this.XLSX.utils.json_to_sheet(this.getReportJsonNotVisited(this.listProducerNotProject));
      const workbook = XLSX.utils.book_new();
      const filename = 'Produtores - Sairam do Projeto';
      XLSX.utils.book_append_sheet(workbook, data, _.truncate(filename, { length: 31 }));
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    print() {
      this.$refs.report.print(null, 'Produtores', true);
    },

    printNotVisited() {
      this.$refs.reportNotVisited.print(null, 'Produtores - Sairam do Projeto', true);
    },
    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
