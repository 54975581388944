<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-title>
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  v-on="on"
                  @click="showPriceDialog = true"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </template>

              <span>Nova particularidade</span>
            </v-tooltip>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            dark
            class="elevation-1 report-table"
          >
            <template #[`item.date`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.literPrice`]="{ value }">
              R$ {{ value | formatCurrency() }}
            </template>
            <template #[`item.price`]="{ value }">
              R$ {{ value | formatCurrency() }}
            </template>
            <template #[`item.finalPrice`]="{ value }">
              R$ {{ value | formatCurrency() }}
            </template>
            <template #[`item.ignoreBonus`]="{ value }">
              {{ value ? 'Sim' : 'Não' }}
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showPriceDialog"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Particularidades do Produtor
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="saveNewParticularity"
            >
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="form.date.picker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="form.date.value | dateFormat('DD/MM/YYYY')"
                        label="Data de início *"
                        prepend-inner-icon="event"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="form.date.value"
                      no-title
                      @input="form.date.picker = false"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <person-autocomplete-filter
                    v-model="form.producer"
                    label="Produtor *"
                    type="PRODUCER"
                    :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    persistent-hint
                    :filled="false"
                    :clearable="false"
                  />
                </v-col>

                <v-col cols="6">
                  <v-switch
                    v-model="form.ignoreBonus"
                    label="Ignorar Bonificação"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="form.price"
                    label="Valor do leite *"
                    prefix="R$"
                    persistent-hint
                    type="number"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    @keypress="disableDot"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="form.motive"
                    label="Motivo *"
                    persistent-hint
                    filled
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="showPriceDialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :loading="form.saving"
            @click="saveNewParticularity"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm ref="confirm" />
  </v-container>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import Confirm from "@/Support/Components/Confirm.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "producer-particularities",

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  components: {
    PersonAutocompleteFilter,
    Confirm
  },

  data() {
    return {
      headers: [
        { text: 'Data', value: 'date' },
        { text: 'Valor Litro', value: 'literPrice' },
        { text: 'Valor', value: 'price' },
        { text: 'Valor Final', value: 'finalPrice' },
        { text: 'Produtor', value: 'name' },
        { text: 'Motivo', value: 'motive' },
        { text: 'Ignorar bonificação', value: 'ignoreBonus' },
        { text: '', value: 'action', width: 30 },
      ],
      items: [],

      showPriceDialog: false,

      form: {
        saving: false,
        date: {
          picker: false,
          value: moment().format('YYYY-MM-DD'),
        },
        price: null
      }
    };
  },

  created() {
    this.loadParticularities()
  },

  methods: {
    async loadParticularities() {
      try {
        const { data } = await this.$axios.get(`/tabelaPreco/listaParticularidades`);

        if (_.isString(data)) {
          throw "PHP error";
        }

        const { valorLeite, particularidades } = data;

        this.items = particularidades.map(item => ({
          id: item.id_valor_leite,
          date: item.data,
          literPrice: parseFloat(valorLeite.valor),
          price: parseFloat(item.valor),
          finalPrice: parseFloat(valorLeite.valor) + parseFloat(item.valor),
          name: item.nome_produtor,
          motive: item.motivo,
          ignoreBonus: item.ignorar_bonificacao,
        }));
      }
      catch (err) {
        console.warn(err)
      }
    },

    async saveNewParticularity() {
      if (this.form.saving) {
        return;
      }
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.form.saving = true;

        let { data } = await this.$axios.post(
          `/tabelaPreco/insereValorLeite`,
          this.$qs.stringify({
            data: moment(this.form.date.value).format('DD/MM/YYYY'),
            valor: this.form.price.toString().replace('.', ','),
            id_produtor: this.form.producer.id,
            nome_produtor: this.form.producer.name,
            motivo: this.form.motive,
            ignorar_bonificacao: this.form.ignoreBonus ? 1 : 0,
          })
        );

        [data] = data

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.showPriceDialog = false;
        this.loadParticularities();
        this.form = {
          saving: false,
          date: {
            picker: false,
            value: moment().format('YYYY-MM-DD'),
          },
          price: null
        };
        this.$snotify.success("Preço salvo com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar preço", "Atenção");
        console.warn(error);
      } finally {
        this.form.saving = false;
      }

    },

    async remove({ id }) {
      if (!(await this.$refs.confirm.open('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/tabelaPreco/inativaValorLeite`, this.$qs.stringify({ id_valor_leite: id }));

        [data] = data

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.loadParticularities();
        this.$snotify.success("Dados excluídos", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir dados", "Atenção");
        console.warn(error);
      }
    },

    disableDot(e) {
      if (e.charCode === 46) {
        e.preventDefault()
      }
    },
  },
};
</script>
