var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tanks.length)?_c('div',[_c('span',{class:{
      'overline': true,
      [`${_vm.color}--text`]: !!_vm.color,
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-divider',{staticClass:"mb-2",staticStyle:{"margin-top":"-8px"}}),_vm._l((_vm.tanks),function(tank){return _c('v-row',{key:tank.index,class:{
      'pa-1': true,
      'ma-0': true,
      'mb-2': true,
      'text-uppercase': true,
      [`${_vm.color} lighten-5`]: !!_vm.color,
      'orange--text': ['TO_UNLOAD_RESTRICT', 'UNLOADED_RESTRICT'].includes(tank.status),
      'blue--text': tank.status === 'UNLOADED',
    },staticStyle:{"border":"1px solid #ccc"}},[_c('v-col',{staticClass:"d-flex pa-0 ma-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(tank.label)+" "),(tank.plate)?_c('span',{staticClass:"ml-1 text-subtitle-2"},[_vm._v(" ("+_vm._s(tank.plate)+") ")]):_vm._e(),_c('span',{staticClass:"ml-1 flex-grow-1 text-right"},[_vm._v(" "+_vm._s(tank.vol)+" L ")])]),(_vm.showMeasured)?[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"5"}},[_vm._v("  - Medidos ")]),_c('v-col',{staticClass:"pa-0 ma-0 text-right",attrs:{"cols":"7"}},[_c('v-text-field',{staticClass:"measured-input",attrs:{"disabled":_vm.measuredDisabled,"flat":"","solo":"","dense":"","hide-details":"","reverse":"","background-color":"transparent"},on:{"input":function($event){return _vm.onTankMeasuredInput($event, tank)}},model:{value:(tank.measured),callback:function ($$v) {_vm.$set(tank, "measured", _vm._n($$v))},expression:"tank.measured"}},[_c('template',{slot:"append-outer"},[_c('span',[_vm._v("L")])])],2)],1)]:_vm._e(),(tank.difference)?_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"5"}},[_vm._v("  - Diferença ")]):_vm._e(),(tank.difference)?_c('v-col',{staticClass:"pa-0 ma-0 text-right",attrs:{"cols":"7"}},[(tank.difference > 0)?_c('span',{staticClass:"green--text text--darken-4"},[_c('span',[_vm._v("+"+_vm._s(tank.difference)+" L")])]):_vm._e(),(tank.difference < 0)?_c('span',{staticClass:"red--text"},[_c('span',[_vm._v(_vm._s(tank.difference)+" L")])]):_vm._e()]):_vm._e(),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"6"}},[_vm._v("  - Disponível ")]),_c('v-col',{staticClass:"pa-0 ma-0 text-right",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(tank.availableVolToTransfer || 0)+" L ")]),(tank.rawMaterial?.id)?_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-chip',{attrs:{"small":"","dark":"","color":['TO_UNLOAD_RESTRICT', 'UNLOADED_RESTRICT'].includes(tank.status) ? 'orange' : undefined}},[_vm._v(" "+_vm._s(tank.rawMaterial.name)+" ")])],1):_vm._e()],2)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }