<template>
  <v-dialog
    v-model="show"
    scrollable
    :max-width="500"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Configurações de Vencimento</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col cols="12">
                <states-select
                  v-model="form.state"
                  prepend-inner-icon="villa"
                  label="Estado *"
                  required
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="form.periodo"
                  label="Período *"
                  :items="period"
                  required
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col
                v-if="hasMilkType"
                cols="12"
              >
                <v-select
                  v-model="form.milkType"
                  label="Tipo de Leite"
                  :items="['B', 'C']"
                  prepend-inner-icon="local_shipping"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="green"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StatesSelect from "@/Support/Components/StatesSelect.vue";

export default {
  components: {
    StatesSelect,
  },

  props: {
    value: Boolean,
    editId: String,
    sanity: String,
  },

  data() {
    return {
      period: [
        { value: 1, text: "Mensal" },
        { value: 2, text: "Bimestral" },
        { value: 3, text: "Trimestral" },
        { value: 6, text: "Semestral" },
        { value: 12, text: "Anual" },
        { value: 24, text: "Bianual" },
        { value: 36, text: "Trianual" },
      ],

      form: [],
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    hasMilkType() {
      const settings = this.$store.state.settings.gerais;

      return settings?.gerador_bonificacao === 'PHP' && settings?.cadastro_produtor?.dados_producao?.tipo_leite?.show;
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = [];

        if (this.editId) {
          this.loadExpirationSetting();
        }
      }
    },
  },

  methods: {
    async loadExpirationSetting() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam-expiration-settings/${this.editId}`);
        this.form = {
          state: data.estado,
          periodo: data.prazo_vencimento,
          milkType: data.tipo_leite,
        };

      } catch (err) {
        console.warn(err);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {
        if (!(await this.$refs.form.validate())) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          estado: this.form.state,
          prazo_vencimento: this.form.periodo,
          tipo_leite: this.form.milkType,
          tipo: this.sanity
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);
        const editId = this.editId || response.id;

        this.$emit("save", editId);
        this.$snotify.success("Registro salvo com sucesso", "Sucesso");
        this.close();
      } catch (e) {
        this.$snotify.error("Erro ao salvar registro", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/health-exam-expiration-settings`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/health-exam-expiration-settings/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    },
  },
};
</script>
