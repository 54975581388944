export const QualityType = {
  'CCS': 1,
  'CPP': 2,
  'UREIA': 3,
  'CASEINA': 4,
  'GORDURA': 5,
  'PROTEINA': 6,
  'ESD': 7,
  'ST': 8,
  'VOLUME': 9,
  'FIDELIDADE': 10,
  'DISTANCIA': 15,
  'BPA': 16,
  'CPP_TRIMESTRAL': 17,
  'CCS_TRIMESTRAL': 18,
  'LACTOSE': 19,
  'BRUCELOSE': 20,
  'TUBERCULOSE': 21,
  'VOLUME_MEDIO': 22,
  'CPP_MENOR_MES': 23,
  'FRETE': 24,
  'GORDURA_TRIMESTRAL': 25,
  'VOLUME_DIFERENCA_AA': 26,
  'TIPO_LEITE_ENTREGA': 27,
  'ORDENHA_MECANIZADA': 28,
}

export const isAnalysisType = (value) => {
  return [
    QualityType.CCS,
    QualityType.CPP,
    QualityType.UREIA,
    QualityType.CASEINA,
    QualityType.GORDURA,
    QualityType.PROTEINA,
    QualityType.ESD,
    QualityType.ST,
    QualityType.LACTOSE,
    QualityType.CPP_TRIMESTRAL,
    QualityType.CCS_TRIMESTRAL,
  ].includes(value)
}

export const getRangeType = (value) => {
  if ([QualityType.BRUCELOSE, QualityType.TUBERCULOSE].includes(value)) {
    return 'EXAME';
  }

  if ([QualityType.TIPO_LEITE_ENTREGA, QualityType.ORDENHA_MECANIZADA].includes(value)) {
    return 'TIPO_LEITE';
  }

  if (value === QualityType.FRETE) {
    return 'FRETE';
  }

  return 'FAIXA';
}
