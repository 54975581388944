<template>
  <v-dialog
    v-model="dialog"
    :max-width="400"
  >
    <v-card>
      <v-card-title class="blue--text text-h5">
        Reclassificação de Tipo de Leite
      </v-card-title>
      <v-card-subtitle>Atualização do Tipo de Leite (Entrega) do cadastro do produtor</v-card-subtitle>
      <v-card-text>
        <v-form ref="formRef">
          <v-row>
            <v-col cols="12">
              <person-autocomplete-filter
                v-model="form.producer"
                prepend-inner-icon="person"
                label="Produtor *"
                type="PRODUCER"
                show-producers-and-groups
                :filled="false"
                :rules="[v => !!v || 'Escolha o Produtor']"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="form.milkType"
                label="Tipo de Leite (Entrega) *"
                :items="['B', 'C']"
                prepend-inner-icon="local_shipping"
                :rules="[v => !!v || 'Escolha o Tipo de Leite']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          outlined
          @click.native="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          outlined
          @click.native="save()"
        >
          Reclassificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue'

const { progressBar, notify } = useUtils()

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const dialog = ref(false)

const formRef = ref()

const form = reactive({
  producer: {},
  milkType: null,
})

const show = () => {
  dialog.value = true
  formRef.value?.reset()
}

const close = () => {
  dialog.value = false
}

const save = async () => {
  try {
    if (!await formRef.value?.validate()) {
      return
    }

    progressBar?.loading()

    await axios.put(`/producer/${form.producer.id}`, {
      tipo_leite_entrega: form.milkType
    })

    emit('save')
    close()
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao reclassificar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show, close })
</script>
