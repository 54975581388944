<template>
  <v-dialog
    v-model="isVisible"
    scrollable
    :max-width="500"
    persistent
  >
    <v-card>
      <v-card-title
        class="blue--text text-h5"
      >
        Recálculo de Descontos de Descargas (Frete)
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-date-range-picker
              v-model="form.dateRange"
            />
          </v-col>
        </v-row>
        <div>
          O recálculo será feito com base nas seguintes configurações:
          <br><b> - Tipo de Tolerância</b>
          <br><b> - Tolerância por Descarga</b>
          <br><b> - Considerar Sobras na tolerância</b>
          <br><b> - Usar Tolerância apenas como Limite de Sobras</b>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save"
        >
          Recalcular
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

import moment from 'moment';
import get from 'lodash/get';

export default {

  components: {
    VDateRangePicker,
  },

  data() {
    return {
      isVisible: false,

      form: {
        dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      }
    }
  },

  methods: {
    show() {
      this.isVisible = true;
      this.$refs.form && this.$refs.form.resetValidation();

      this.form = {
        dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      };
    },
    close() {
      this.isVisible = false;
    },
    async save() {
      try {
        this.$root.$progressBar.saving();

        const [startDate, endDate] = this.form.dateRange;

        const payload = {
          data_inicial: startDate,
          data_final: endDate,
          salvar: 2,
        };

        const { data } = await this.$axios.post(`/ajustes/recalculaDescontoDescarga`, payload);

        this.$snotify.success('', {
          html: `<div class="snotifyToast__title"><b>Sucesso</b></div>
            <div class="snotifyToast__body">
              ${data.descargas} descargas recalculadas!<br>
            </div>`
        });

        this.close();
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao recalcular');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    }
  }
}
</script>
