<template>
  <v-dialog
    v-model="show"
    scrollable
    width="80%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        <span class="text-h6">Ordem de Produção <span v-if="!!form.code">nº {{ form.code }}</span></span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div>
          <v-form
            ref="form"
            lazy-validation
            :disabled="!canEdit"
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="form.code"
                  label="Código"
                  prepend-inner-icon="subtitles"
                  persistent-hint
                  :disabled="!!editId"
                  :hint="!editId ? 'Deixe vazio para ser gerado automaticamente' : undefined"
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <v-autocomplete
                  v-model="form.item"
                  label="Produto *"
                  :items="orderItems"
                  :rules="[v => !!v || 'Campo obrigatório!']"
                  item-value="id"
                  item-text="description"
                  return-object
                  :disabled="!!form.lotFormat"
                  autofocus
                  @change="onProductChange"
                >
                  <template #selection="{ item }">
                    {{ item.description }}
                    <div class="mx-1 caption font-weight-light">
                      ({{ getType(item.type) }})
                    </div>
                  </template>
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip
                          x-small
                          :color="getTypeColor(item.type)"
                          text-color="white"
                        >
                          {{ getType(item.type) }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="hasPlannedQuantityAccess"
                cols="6"
                md="3"
                class="pt-0"
              >
                <masked-text-field
                  v-model="form.quantity"
                  label="Quantidade Planejada"
                  :suffix="form.item?.measurement"
                  prepend-inner-icon="iso"
                  persistent-hint
                  unmask
                  :mask="masks.float"
                  inputmode="numeric"
                  validate-on-blur
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <v-select
                  v-model="form.status"
                  label="Status *"
                  :items="statusItems"
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  v-if="lotPreview.show && lotPreview.value"
                  :value="lotPreview.value"
                  label="Nº Lote"
                  prepend-inner-icon="subtitles"
                  disabled
                >
                  <template #append>
                    <v-btn
                      icon
                      small
                      @click="lotPreview.show = false"
                    >
                      <v-icon small>
                        edit
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="form.lotNumber"
                  label="Nº Lote"
                  prepend-inner-icon="subtitles"
                  :hint="!editId && form.item?.lotFormat ? 'Deixe vazio para ser gerado automaticamente' : undefined"
                  persistent-hint
                  :disabled="!!form.lotFormat || !!form.item?.isRawMaterial"
                >
                  <template #append>
                    <v-tooltip
                      v-if="editId && !form.lotNumber && form.item?.lotFormat"
                      top
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          @click="generateLotNumber()"
                        >
                          <v-icon small>
                            autorenew
                          </v-icon>
                        </v-btn>
                      </template>
                      Gerar Lote
                    </v-tooltip>
                    <v-btn
                      v-else-if="!editId && !form.lotNumber && lotPreview.value"
                      icon
                      small
                      @click="lotPreview.show = true"
                    >
                      <v-icon small>
                        visibility
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                md="3"
                class="pt-0"
              >
                <date-text-field
                  v-model="form.date"
                  label="Data *"
                  manual
                  :rules="[v => !!v || 'Campo obrigatório!']"
                  @input="onDateChange()"
                />
              </v-col>
              <v-col
                v-if="hasDatesAccess"
                cols="6"
                md="3"
                class="pt-0"
              >
                <date-text-field
                  v-model="form.startDate"
                  label="Data Início"
                  manual
                  max=""
                />
              </v-col>
              <v-col
                v-if="hasDatesAccess"
                cols="6"
                md="3"
                class="pt-0"
              >
                <date-text-field
                  v-model="form.dueDate"
                  label="Data Vencimento"
                  manual
                  max=""
                />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-text-field
                  v-model="form.description"
                  label="Descrição"
                  prepend-inner-icon="drive_file_rename_outline"
                  :placeholder="form.item?.name"
                />
              </v-col>
            </v-row>
            <v-tabs
              v-model="tab"
            >
              <v-tab href="#tab-components">
                Componentes
              </v-tab>
              <v-tab href="#tab-byproducts">
                Subprodutos
              </v-tab>
              <v-tab href="#tab-coproducts">
                Coprodutos
              </v-tab>

              <v-tab-item value="tab-components">
                <template
                  v-for="(data, idx) of form.items"
                >
                  <v-row
                    v-if="data.active"
                    :key="idx"
                    style="border-bottom: 1px #eee solid;"
                  >
                    <v-col
                      class="py-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-icon>
                        reorder
                      </v-icon>
                    </v-col>
                    <v-col
                      class="pt-1 pb-0"
                      style="flex-grow: 3"
                    >
                      <v-autocomplete
                        v-model="data.item"
                        label="Item *"
                        :items="items"
                        :rules="[v => (!!v && !!v.id) || 'Campo obrigatório!']"
                        item-value="id"
                        item-text="description"
                        return-object
                        :disabled="data.accomplishedQuantity > 0"
                        @change="onComponentChange(idx)"
                      >
                        <template #selection="{ item }">
                          {{ item.description }}
                          <div class="mx-1 caption font-weight-light">
                            ({{ getType(item.type) }})
                          </div>
                        </template>
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.description }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-chip
                                x-small
                                :color="getTypeColor(item.type)"
                                text-color="white"
                              >
                                {{ getType(item.type) }}
                              </v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        v-model="data.quantity"
                        label="Quantidade Base *"
                        persistent-hint
                        unmask
                        :mask="masks.float"
                        :suffix="data.item?.measurement"
                        inputmode="numeric"
                        :rules="[v => !!v && data.quantity > 0 || 'Campo obrigatório!']"
                        validate-on-blur
                      />
                    </v-col>
                    <v-col
                      v-if="hasPlannedQuantityAccess"
                      class="pt-1 pb-0"
                    >
                      <masked-text-field
                        :value="(data.quantity * form.quantity) || null"
                        label="Quantidade Planejada"
                        unmask
                        :mask="masks.float"
                        disabled
                        persistent-hint
                        :suffix="data.item?.measurement"
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        :value="(data.accomplishedQuantity) || null"
                        label="Quantidade Realizada"
                        unmask
                        :mask="masks.float"
                        disabled
                        persistent-hint
                        :suffix="data.item?.measurement"
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <v-select
                        v-model="data.writeOffType"
                        label="Tipo de Baixa *"
                        :items="['MANUAL', 'AUTOMATICA']"
                        :disabled="!!data.item?.isRawMaterial || !form.quantity"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      v-if="form.item?.steps?.length > 0"
                      class="pt-1 pb-0"
                    >
                      <v-select
                        v-model="data.step"
                        label="Etapa"
                        :items="form.item.steps"
                        item-value="id"
                        item-text="description"
                        return-object
                        clearable
                      />
                    </v-col>
                    <v-col
                      class="px-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-btn
                        icon
                        :disabled="!canEdit || data.accomplishedQuantity > 0"
                        @click.stop="removeComponent(idx)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <div class="d-flex justify-end">
                  <v-btn
                    v-if="canEdit"
                    outlined
                    color="blue"
                    class="my-2"
                    @click="addComponent"
                  >
                    <v-icon>add</v-icon> Adicionar
                  </v-btn>
                </div>
                <v-divider class="mb-2" />
              </v-tab-item>
              <v-tab-item value="tab-byproducts">
                <template
                  v-for="(data, idx) of form.byproducts"
                >
                  <v-row
                    v-if="data.active"
                    :key="idx"
                    style="border-bottom: 1px #eee solid;"
                  >
                    <v-col
                      class="py-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-icon>
                        reorder
                      </v-icon>
                    </v-col>
                    <v-col
                      class="pt-1 pb-0"
                      style="flex-grow: 3"
                    >
                      <v-autocomplete
                        v-model="data.item"
                        label="Item *"
                        :items="items"
                        :rules="[v => (!!v && !!v.id) || 'Campo obrigatório!']"
                        item-value="id"
                        item-text="description"
                        return-object
                        :disabled="data.accomplishedQuantity > 0"
                        @change="onByproductChange(idx)"
                      >
                        <template #selection="{ item }">
                          {{ item.description }}
                          <div class="mx-1 caption font-weight-light">
                            ({{ getType(item.type) }})
                          </div>
                        </template>
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.description }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-chip
                                x-small
                                :color="getTypeColor(item.type)"
                                text-color="white"
                              >
                                {{ getType(item.type) }}
                              </v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        v-model="data.quantity"
                        label="Quantidade Base *"
                        persistent-hint
                        unmask
                        :mask="masks.float"
                        inputmode="numeric"
                        :suffix="data.item?.measurement"
                        :rules="[v => !!v && data.quantity > 0 || 'Campo obrigatório!']"
                        validate-on-blur
                      />
                    </v-col>
                    <v-col
                      v-if="hasPlannedQuantityAccess"
                      class="pt-1 pb-0"
                    >
                      <masked-text-field
                        :value="(data.quantity * form.quantity) || null"
                        label="Quantidade Planejada"
                        unmask
                        :mask="masks.float"
                        disabled
                        persistent-hint
                        :suffix="data.item?.measurement"
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        :value="(data.accomplishedQuantity) || null"
                        label="Quantidade Realizada"
                        unmask
                        :mask="masks.float"
                        disabled
                        persistent-hint
                        :suffix="data.item?.measurement"
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <v-select
                        v-model="data.entryType"
                        label="Tipo de Entrada *"
                        :items="['MANUAL', 'AUTOMATICA']"
                        :disabled="!!data.item?.isRawMaterial || !form.quantity"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      v-if="form.item?.steps?.length > 0"
                      class="pt-1 pb-0"
                    >
                      <v-select
                        v-model="data.step"
                        label="Etapa"
                        :items="form.item.steps"
                        item-value="id"
                        item-text="description"
                        return-object
                        clearable
                      />
                    </v-col>
                    <v-col
                      class="px-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-btn
                        icon
                        :disabled="!canEdit || data.accomplishedQuantity > 0"
                        @click.stop="removeByProduct(idx)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <div class="d-flex justify-end">
                  <v-btn
                    v-if="canEdit"
                    outlined
                    color="blue"
                    class="my-2"
                    @click="addByproduct"
                  >
                    <v-icon>add</v-icon> Adicionar
                  </v-btn>
                </div>
                <v-divider class="mb-2" />
              </v-tab-item>

              <v-tab-item value="tab-coproducts">
                <span class="text-subtitle d-block my-2 mx-4">Usará como base o produto principal para ser produzido</span>
                <template
                  v-for="(data, idx) of form.coproducts"
                >
                  <v-row
                    v-if="data.active"
                    :key="idx"
                    style="border-bottom: 1px #eee solid;"
                  >
                    <v-col
                      class="py-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-icon>
                        reorder
                      </v-icon>
                    </v-col>
                    <v-col
                      class="pt-1 pb-0"
                      style="flex-grow: 3"
                    >
                      <v-autocomplete
                        v-model="data.item"
                        label="Item *"
                        :items="items"
                        :rules="[v => (!!v && !!v.id) || 'Campo obrigatório!']"
                        item-value="id"
                        item-text="description"
                        return-object
                        :disabled="data.accomplishedQuantity > 0"
                      >
                        <template #selection="{ item }">
                          {{ item.description }}
                          <div class="mx-1 caption font-weight-light">
                            ({{ getType(item.type) }})
                          </div>
                        </template>
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.description }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-chip
                                x-small
                                :color="getTypeColor(item.type)"
                                text-color="white"
                              >
                                {{ getType(item.type) }}
                              </v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        v-model="data.quantity"
                        :label="form.item?.measurement && data.item?.measurement ? `Qtde. de ${form.item.measurement} por ${data.item.measurement}` : 'Fator de Conversão'"
                        hint="Fator de Conversão"
                        persistent-hint
                        unmask
                        :mask="masks.float"
                        inputmode="numeric"
                        :suffix="form.item?.measurement"
                        :rules="[v => !!v && data.quantity > 0 || 'Campo obrigatório!']"
                        validate-on-blur
                      />
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <masked-text-field
                        :value="(data.accomplishedQuantity) || null"
                        label="Quantidade Realizada"
                        unmask
                        :mask="masks.float"
                        disabled
                        persistent-hint
                        :suffix="data.item?.measurement"
                      />
                    </v-col>
                    <v-col
                      class="px-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-btn
                        icon
                        :disabled="!canEdit || data.accomplishedQuantity > 0"
                        @click.stop="removeCoProduct(idx)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <div class="d-flex justify-end">
                  <v-btn
                    v-if="canEdit"
                    outlined
                    color="blue"
                    class="my-2"
                    @click="addCoproduct"
                  >
                    <v-icon>add</v-icon> Adicionar
                  </v-btn>
                </div>
                <v-divider class="mb-2" />
              </v-tab-item>
            </v-tabs>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="canEdit"
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import DateTextField from '@/Support/Components/DateTextField.vue';

import moment from 'moment';

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

export default {

  components: {
    MaskedTextField,
    DateTextField,
  },

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      tab: null,
      items: [],

      form: {
        status: 'PLANEJADO',
        date: moment().format('YYYY-MM-DD'),
        items: [],
        byproducts: [],
        coproducts: [],
      },

      lotPreview: {
        show: false,
        value: null,
      },

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    canEdit() {
      return ['PLANEJADO', 'LIBERADO'].includes(this.form.status)
    },

    statusItems() {
      if (this.canEdit) {
        return ['PLANEJADO', 'LIBERADO'];
      }
      return ['PLANEJADO', 'LIBERADO', 'FECHADO', 'CANCELADO'];
    },

    orderItems() {
      return this.items.filter(item => item.productionItem);
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasDatesAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-datas' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.tab = null;

        this.form = {
          status: 'PLANEJADO',
          date: moment().format('YYYY-MM-DD'),
          items: [],
          byproducts: [],
          coproducts: [],
        };

        this.lotPreview = {
          show: false,
          value: null,
        };

        if (this.editId) {
          this.loadOrder();
        }
      }
    }
  },

  created() {
    this.loadItems()
  },

  methods: {

    async loadItems() {
      const { data } = await this.$axios.get(`/item`, { params: {
        exibir_formulacao: true,
      } });

      const dairyId = this.$store.state.settings?.laticinio?.id;

      this.items = data
        .map(item => {
          const formulation = item.formulacao || {};

          return {
            id: item.id_item,
            code: item.cod_item,
            name: item.nome,
            description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
            productionItem: item.exibir_no_silo,
            type: item.tipo,
            lotFormat: item.parametros_producao?.formato_lote,
            measurement: item.unidade,
            stockControl: item.controle_estoque,
            rawMaterialGroupId: item.id_agrupador_materia_prima,
            isRawMaterial: item.controle_estoque === 'SILO' || item.id_agrupador_materia_prima,
            steps: (item.etapas_producao || []).map((item) => ({
              id: item.id,
              description: item.descricao,
              duration: item.duracao,
            })),
            formulation: (formulation && item.exibir_no_silo) ?
              {
                items: (formulation.itens || []).map((item) => ({
                  type: item.tipo,
                  item: {
                    id: item.id_item,
                    code: item.cod_item,
                    name: item.nome,
                    type: item.tipo,
                    description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
                    measurement: item.unidade,
                  },
                  quantity: parseFloat(item.quantidade_base),
                  writeOffType: item.tipo_baixa,
                  step: item.id_etapa ? { id: item.id_etapa, description: item.etapa } : undefined,
                  active: 1,
                })),
                byproducts: (formulation.subprodutos || [])
                  .filter(item => !item.coproduto)
                  .map((item) => ({
                    type: item.tipo,
                    item: {
                      id: item.id_item,
                      code: item.cod_item,
                      name: item.nome,
                      type: item.tipo,
                      description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
                      measurement: item.unidade,
                    },
                    quantity: parseFloat(item.quantidade_base),
                    entryType: item.tipo_entrada,
                    step: item.id_etapa ? { id: item.id_etapa, description: item.etapa } : undefined,
                    active: 1,
                  })),
                coproducts: (formulation.subprodutos || [])
                  .filter(item => item.coproduto && (!item.laticinios?.length || item.laticinios.includes(dairyId)))
                  .map((item) => ({
                    type: item.tipo,
                    item: {
                      id: item.id_item,
                      code: item.cod_item,
                      name: item.nome,
                      type: item.tipo,
                      description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
                      measurement: item.unidade,
                    },
                    quantity: parseFloat(item.quantidade_base),
                    entryType: 'MANUAL',
                    active: 1,
                    isCoproduct: true,
                  }))
              } : null
          }
        });
    },

    async loadOrder() {
      try {
        this.$root.$progressBar.loading();

        this.form = await api.show(this.editId);
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita uma ordem no banco de dados
     */
    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          codigo: this.form.code,
          id_item: this.form.item.id,
          status: this.form.status,
          descricao: this.form.description || this.form.item.name,
          quantidade_planejada: this.form.quantity,
          numero_lote: this.form.item.isRawMaterial ? null : this.form.lotNumber,
          formato_lote: !this.editId ? (this.form.lotFormat || this.form.item.lotFormat) : this.form.lotFormat,
          data_pedido: this.form.date,
          data_inicio: this.form.startDate,
          data_vencimento: this.form.dueDate,
          itens: this.form.items.map((item) => ({
            id_ordem_producao_item: item.id || null,
            tipo: item.item.type,
            id_item: item.item.id,
            unidade: item.measurement,
            quantidade_base: parseFloat(item.quantity),
            tipo_baixa: (item.item?.isRawMaterial || !this.form.quantity) ? 'MANUAL' : item.writeOffType,
            id_etapa: item.step?.id,
            etapa: item.step?.description,
            ativo: item.active,
          })),
          subprodutos: [...this.form.byproducts, ...this.form.coproducts].map((item) => ({
            id_ordem_producao_subproduto: item.id || null,
            tipo: item.item.type,
            id_item: item.item.id,
            unidade: item.measurement,
            quantidade_base: parseFloat(item.quantity),
            tipo_entrada: (item.item?.isRawMaterial || !this.form.quantity) ? 'MANUAL' : item.entryType,
            id_etapa: item.step?.id,
            etapa: item.step?.description,
            ativo: item.active,
            coproduto: item.isCoproduct,
          })),
        };

        const response = this.editId ? await api.update(this.editId, payload) : await api.store(payload);

        const editId = this.editId || response.id;

        this.$emit('update:editId', editId);
        this.$emit('save', editId);
        this.$snotify.success('Ordem cadastrada com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        this.$snotify.error('Erro ao cadastrar ordem', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    removeComponent(idx) {
      const item = this.form.items[idx];
      /**
       * Se possui ID então é uma edição,
       * marca como active = 0 para inativar no BD
       */
      if (item.id) {
        this.form.items[idx].active = 0;
      }
      else {
        this.form.items.splice(idx, 1);
      }
    },

    addComponent() {
      this.form.items.push({
        active: 1,
        writeOffType: 'AUTOMATICA'
      })
    },

    onComponentChange(idx) {
      const item = this.form.items[idx];

      if (item.item?.isRawMaterial || !this.form.quantity) {
        item.writeOffType = 'MANUAL';
      }
    },

    removeByProduct(idx) {
      const item = this.form.byproducts[idx];
      /**
       * Se possui ID então é uma edição,
       * marca como active = 0 para inativar no BD
       */
      if (item.id) {
        this.form.byproducts[idx].active = 0;
      }
      else {
        this.form.byproducts.splice(idx, 1);
      }
    },

    addByproduct() {
      this.form.byproducts.push({
        active: 1,
        entryType: 'MANUAL',
      })
    },

    onByproductChange(idx) {
      const item = this.form.byproducts[idx];

      if (item.item?.isRawMaterial || !this.form.quantity) {
        item.entryType = 'MANUAL';
      }
    },

    removeCoProduct(idx) {
      const item = this.form.coproducts[idx];
      /**
       * Se possui ID então é uma edição,
       * marca como active = 0 para inativar no BD
       */
      if (item.id) {
        this.form.coproducts[idx].active = 0;
      }
      else {
        this.form.coproducts.splice(idx, 1);
      }
    },

    addCoproduct() {
      this.form.coproducts.push({
        active: 1,
        entryType: 'MANUAL',
        isCoproduct: true,
      })
    },

    onProductChange({ formulation }) {
      this.previewLotNumber();

      if (formulation) {
        this.form.items.splice(
          0,
          this.form.items.length,
          ...formulation.items
        );

        this.form.byproducts.splice(
          0,
          this.form.byproducts.length,
          ...formulation.byproducts
        );

        this.form.coproducts.splice(
          0,
          this.form.coproducts.length,
          ...formulation.coproducts
        );
      }
    },

    async onDateChange() {
      setTimeout(() => this.previewLotNumber(), 500);
    },

    async previewLotNumber() {
      const { item, date } = this.form || {};

      if (this.editId) {
        return;
      }

      if (!item?.lotFormat) {
        this.lotPreview.show = false;
        this.lotPreview.value = null;
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const response = await api.previewLot({ id_item: item.id, data: date });

        this.form.lotNumber = null;
        this.lotPreview.show = true;
        this.lotPreview.value = response.prox_lote;
      } catch (e) {
        this.$snotify.error('Erro ao previsualizar lote', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async generateLotNumber() {
      if (!this.editId) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const { data } = await api.generateLot(this.editId);

        this.form.lotNumber = data.numero_lote;
        this.form.lotFormat = data.formato_lote;

        this.$snotify.success('Lote gerado com sucesso', 'Sucesso');
      } catch (e) {
        this.$snotify.error('Erro ao gerar lote', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getType(value) {
      const types = {
        'PRODUTO_ACABADO': 'PRODUTO ACABADO',
        'MATERIA_PRIMA': 'MATÉRIA PRIMA',
        'INSUMO_PRODUCAO': 'INSUMO DE PRODUÇÃO',
        'INSUMO_AGRICOLA': 'INSUMO AGRÍCOLA',
      };

      return types[value] || value;
    },

    getTypeColor(value) {
      const statusColors = {
        'PRODUTO_ACABADO': 'blue',
        'MATERIA_PRIMA': 'orange darken-1',
        'INSUMO_PRODUCAO': 'green darken-1',
      }
      return statusColors[value] || 'grey'
    },

    close() {
      this.show = false;
    }
  }

}
</script>
