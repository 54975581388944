<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <card-title-settings class="mb-3">
          Produtores sem coletas
        </card-title-settings>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="mt-0"
              >
                <v-switch
                  v-model="form.collects.settings.notificar"
                  label="Enviar notificação de produtores sem coleta?"
                  dense
                  hide-details
                  class="mt-0"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                class="mt-0"
              >
                <v-select
                  v-model="form.collects.settings.intervalo"
                  label="Intervalo de notificações"
                  :items="[
                    { text: '1 hora', value: 1 },
                    { text: '2 horas', value: 2 },
                    { text: '3 horas', value: 3 },
                    { text: '4 horas', value: 4 },
                    { text: '6 horas', value: 6 },
                    { text: '12 horas', value: 12 },
                    { text: '24 horas', value: 24 },
                  ]"
                  class="mt-0"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                class="mt-0"
              >
                <v-text-field
                  v-model.number="form.collects.settings.horas_sem_coleta"
                  label="Quantidade de horas sem coleta"
                  class="mt-0"
                  @keypress="disableDotAndComma"
                />
              </v-col>

              <v-col
                cols="12"
                class="mt-0"
              >
                <v-combobox
                  v-model="form.collects.settings.emails"
                  label="Enviar notificação para e-mails"
                  hint="Aperte 'enter' para adicionar um novo"
                  persistent-hint
                  class="mt-0"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <card-title-settings class="mb-3">
          Integrador
        </card-title-settings>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
                class="mt-0"
              >
                <v-switch
                  v-model="form.integrator.settings.notificar"
                  label="Enviar notificações?"
                  dense
                  hide-details
                  class="mt-0"
                  @change="form.integrator.settings.notificar_erros = false"
                />
              </v-col>
              <v-col
                cols="6"
                class="mt-0"
              >
                <v-switch
                  v-model="form.integrator.settings.enviar_email"
                  label="Enviar e-mail?"
                  dense
                  hide-details
                  class="mt-0"
                  @change="form.integrator.settings.enviar_email_erros = false"
                />
              </v-col>
              <v-col
                cols="6"
                class="mt-0"
              >
                <v-switch
                  v-model="form.integrator.settings.notificar_erros"
                  label="Enviar notificações de erros?"
                  dense
                  hide-details
                  :disabled="!form.integrator.settings.notificar"
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="6"
                class="mt-0"
              >
                <v-switch
                  v-model="form.integrator.settings.enviar_email_erros"
                  label="Enviar e-mail de erros?"
                  dense
                  hide-details
                  :disabled="!form.integrator.settings.enviar_email"
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="12"
                class="mt-0"
              >
                <v-autocomplete
                  v-model="form.integrator.settings.usuarios"
                  label="Usuários"
                  :items="users"
                  item-value="id_pessoa"
                  item-text="nome"
                  multiple
                  hide-details
                  clearable
                  small-chips
                  deletable-chips
                  class="mt-0"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <card-title-settings class="mb-3">
          Alterações de Coleta
        </card-title-settings>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
                class="mt-0"
              >
                <v-switch
                  v-model="form.collectsEdited.settings.enviar_email"
                  label="Enviar e-mail?"
                  dense
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="12"
                class="mt-0"
              >
                <v-combobox
                  v-model="form.collectsEdited.settings.emails"
                  label="Enviar notificação para e-mails"
                  hint="Aperte 'enter' para adicionar um novo"
                  persistent-hint
                  class="mt-0"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <card-title-settings class="mb-3">
          Vencimento CNH
        </card-title-settings>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
                class="mt-0"
              >
                <v-switch
                  v-model="form.driversLicenseExpire.settings.notificar"
                  label="Enviar notificações?"
                  dense
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="mt-0"
              >
                <v-text-field
                  v-model.number="form.driversLicenseExpire.settings.dias_vencimento"
                  label="Vencimento em Dias"
                  type="number"
                  class="mt-0"
                  @keypress="disableDotAndComma"
                />
              </v-col>
              <v-col
                cols="12"
                class="mt-0"
              >
                <v-autocomplete
                  v-model="form.driversLicenseExpire.settings.usuarios"
                  label="Usuários"
                  :items="users"
                  item-value="id_pessoa"
                  item-text="nome"
                  multiple
                  hide-details
                  clearable
                  small-chips
                  deletable-chips
                  class="mt-0"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <card-title-settings class="mb-3">
          Coletas superiores a capacidade do resfriador
        </card-title-settings>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
                class="mt-0"
              >
                <v-switch
                  v-model="form.collectionsAboveTheCooler.settings.notificar"
                  label="Enviar notificações?"
                  dense
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="12"
                class="mt-0"
              >
                <v-combobox
                  v-model="form.collectionsAboveTheCooler.settings.emails"
                  label="Enviar notificação para e-mails"
                  hint="Aperte 'enter' para adicionar um novo"
                  persistent-hint
                  class="mt-0"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          bottom
          color="green mr-5"
          dark
          fab
          fixed
          large
          right
          v-on="on"
          @click.stop="save"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar </span>
    </v-tooltip>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue";

export default {

  components: {
    CardTitleSettings,
  },

  data() {
    return {
      loading: false,

      selectedNotification: null,

      form: {
        collects: {
          title: 'Produtores sem coletas',
          slug: 'produtores-sem-coletas',
          settings: {
            notificar: false,
            intervalo: 24,
            horas_sem_coleta: 48,
            emails: [],
          },
        },
        integrator: {
          title: 'Integrador',
          slug: 'integrador',
          settings: {
            notificar: false,
            notificar_erros: false,
            enviar_email: false,
            enviar_email_erros: false,
            usuarios: [],
          },
        },
        collectsEdited: {
          title: 'Edição Coleta',
          slug: 'edicao-coleta',
          settings: {
            enviar_email: false,
            emails: [],
          },
        },
        collectionsAboveTheCooler: {
          title: 'Coletas maiores a capacidade do resfriador',
          slug: 'coletas-maiores-resfriador',
          settings: {
            notificar: false,
            emails: [],
          },
        },
        driversLicenseExpire: {
          title: 'Vencimento CNH',
          slug: 'vencimento-cnh',
          settings: {
            notificar: false,
            dias_vencimento: 30,
            usuarios: [],
          },
        },
      },

      forms: {
        'produtores-sem-coletas': 'collects',
        'integrador': 'integrator',
        'edicao-coleta': 'collectsEdited',
        'vencimento-cnh': 'driversLicenseExpire',
        'coletas-maiores-resfriador': 'collectionsAboveTheCooler'
      },

      users: [],
    };
  },

  mounted() {
    this.getSettings();
    this.loadUsers();
  },

  methods: {
    async getSettings() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/settings/notifications`);

        for (const item of data) {
          const key = this.forms[item.slug];

          if (!key) continue;

          this.form[key] = {
            title: item.notificacao,
            slug: item.slug,
            settings: item.configuracao,
          }
        }

      } catch (e) {
        this.$snotify.error('Erro ao listar configurações', 'Atenção');

      } finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        this.loading = true;

        const payload = {
          items: Object.values(this.form)
            .map(item => ({
              notificacao: item.title,
              slug: item.slug,
              configuracao: item.settings,
              ativo: 1,
            }))
        };

        await this.$axios.post(`settings/notifications`, payload);

      } catch (e) {
        this.$snotify.error('Erro ao salvar as configurações', 'Atenção');

      } finally {
        this.loading = false;
      }
    },

    async loadUsers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaUsuarioJson`, {
          exibir: 'ATIVOS'
        });

        this.users = data;

      } catch (error) {
        this.$snotify.error('Erro ao carregar usuários', 'Atenção');
        console.warn(error);
      }
    },

    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
}

</script>
