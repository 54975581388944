<template>
  <div>
    <v-row
      v-for="(data, idx) of items"
      :key="idx"
      style="border-bottom: 1px #eee solid;"
    >
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-flex flex-column justify-center"
        style="max-width: 49px;"
      >
        <v-icon>
          reorder
        </v-icon>
      </v-col>
      <v-col
        class="pt-1 pb-0"
        cols="6"
        sm="3"
        md
      >
        <v-text-field
          :value="data.item ? data.item.description : null"
          label="Matéria Prima"
          disabled
        />
      </v-col>
      <v-col
        v-if="hasInputsAccess && !$vuetify.breakpoint.mobile"
        class="pt-1 pb-0"
        cols="6"
        sm="3"
        md
      >
        <masked-text-field
          :value="data.quantity"
          label="Qtde. Base"
          unmask
          :mask="masks.float"
          suffix="LT"
          disabled
        />
      </v-col>
      <v-col
        v-if="hasPlannedQuantityAccess"
        class="pt-1 pb-0"
        cols="6"
        sm="3"
        md
      >
        <masked-text-field
          :value="data.plannedQuantity"
          label="Qtde. Planejada"
          unmask
          :mask="masks.float"
          suffix="LT"
          disabled
        />
      </v-col>
      <v-col
        v-if="hasInputsAccess"
        class="pt-1 pb-0"
        cols="6"
        sm="3"
        md
      >
        <masked-text-field
          :value="data.accomplishedQuantity"
          label="Qtde. Realizada"
          unmask
          :mask="masks.float"
          suffix="LT"
          disabled
        />
      </v-col>
      <v-col
        v-if="canEdit"
        class="pt-1 pb-0"
        cols="6"
        sm="3"
        md
      >
        <masked-text-field
          :value="transferringVol[data.itemId] || null"
          label="Qtde."
          unmask
          :mask="masks.float"
          suffix="LT"
          disabled
        />
      </v-col>
    </v-row>

    <v-form
      v-if="canEdit"
      ref="form"
      lazy-validation
      @submit.prevent="save()"
    >
      <span class="text-h6 d-block mt-4">Silos</span>

      <v-divider class="mb-4" />

      <v-row>
        <v-col
          v-for="silo in availableSilos"
          :key="silo.label"
          style="max-width:220px"
        >
          <silo-card
            :silo="silo"
            :disabled="!canEdit"
            @click="onSiloSelected(silo)"
          >
            <masked-text-field
              v-if="silo.showInput"
              v-model.number="silo.transferringVol"
              label="Volume:"
              unmask
              :mask="masks.float"
              inputmode="numeric"
              class="mt-3"
              dense
              filled
              append-icon="close"
              :rules="[
                v => !!v || 'Campo obrigatório!',
                v => v <= silo.vol || 'Volume indisponível',
                validateRange(silo),
              ]"
              validate-on-blur
              @click:append="deselectSilo(silo)"
            />
          </silo-card>
        </v-col>
        <v-col
          v-if="availableSilos.length === 0"
          cols="12"
          class="text-center"
          :class="{ 'has-error': hasError }"
        >
          Nenhum silo disponível para efetuar baixas
        </v-col>
        <v-col
          v-else-if="transferringSilos.length === 0"
          cols="12"
          class="text-center"
          :class="{ 'has-error': hasError }"
        >
          Selecione um silo para apontar
        </v-col>
      </v-row>
    </v-form>

    <span class="text-h6 d-block mt-4">Registros</span>

    <v-divider class="mb-4" />

    <v-data-table
      :headers="headers"
      :items="records"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
      @click:row="onEditSiloHistory"
    >
      <template #[`item.tinas`]="{ item }">
        <div
          v-for="tina in item.tinas"
          :key="tina.id"
        >
          <v-chip
            v-if="tina.description"
            x-small
          >
            {{ tina.description }}
          </v-chip>
        </div>
      </template>

      <template #[`item.transferredVol`]="{ value }">
        {{ formatNumber(value) }}
      </template>

      <template #[`item.transferredAt`]="{ value }">
        {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
      </template>

      <template #[`item.processedAt`]="{ value }">
        <v-chip x-small>
          {{ formatDate(value, 'DD/MM/YYYY') }}
        </v-chip>
      </template>

      <template #[`item.rawMaterial.description`]="{ value }">
        <v-chip
          v-if="value"
          x-small
        >
          {{ value }}
        </v-chip>
      </template>

      <template #[`item.action`]="{ item }">
        <v-tooltip
          v-if="hasChargebackAccess"
          top
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click.stop="removeTransfer(item)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          Excluir e Estornar
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.has-error {
  animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: red;
}
</style>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import SiloCard from '@/Domains/Industry/ProductionOrder/Components/SiloCard.vue';
import { useRangeValidator } from '@/Support/Composables/validator.js'

import moment from 'moment';

const { validateRule } = useRangeValidator();

export default {

  components: {
    MaskedTextField,
    SiloCard
  },

  props: {
    canEdit: Boolean,
    order: {
      type: Object,
      default: () => ({ items: [] })
    },
    step: Object,
  },

  data() {
    return {
      headers: [
        { text: 'Silo', value: 'silo.description' },
        { text: 'Tinas', value: 'tinas', width: 100 },
        { text: 'Baixa', value: 'transferredAt' },
        { text: 'Produção', value: 'processedAt' },
        { text: 'Pasteurizador', value: 'transferredVol' },
        { text: 'Nº Lote', value: 'batchNumber' },
        { text: 'Matéria Prima', value: 'rawMaterial.description' },
        { text: 'Responsável', value: 'responsible.name' },
        { text: '', value: 'action', width: 30, sortable: false },
      ],

      items: [],
      availableRawMaterials: [],

      silos: [],

      records: [],

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },

      hasError: false,

    }
  },

  computed: {
    availableSilos() {
      return this.silos.filter(silo => this.availableRawMaterials.includes(silo.rawProduct.id));
    },

    transferringSilos() {
      return this.silos.filter(silo => silo.showInput);
    },

    transferringVol() {
      return this.transferringSilos
        .reduce((acc, silo) => {
          const id = silo.rawProduct.id;
          if (!(id in acc)) {
            acc[id] = 0;
          }

          acc[id] += parseFloat(silo.transferringVol) || 0;

          return acc;
        }, {});
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasInputsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-inputs-apontamento' && o.tipo === 'COMPONENTE');
    },
    hasChargebackAccess() {
      if (!this.canEdit) {
        return false
      }
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-exclusao-apontamento' && o.tipo === 'COMPONENTE');
    },
  },

  mounted() {
    if (this.canEdit) {
      this.loadSilos();
    }

    this.loadData();
  },

  methods: {
    loadData() {
      this.items = this.order.items
        .filter(data => data.item.isRawMaterial)
        .map(item => {
          const plannedQuantity = item.quantity * this.order.quantity;
          const writeOffQuantity = plannedQuantity - item.accomplishedQuantity;
          return {
            ...item,
            plannedQuantity,
            writeOffQuantity: (this.hasInputsAccess && writeOffQuantity > 0) ? writeOffQuantity : null,
          }
        });

      if (this.step) {
        this.items = this.items
          .filter(item => !item.step || item.step.id === this.step.id)
      }

      const originIds = this.items.map(item => item.id);

      this.availableRawMaterials = this.items.map(item => item.itemId);

      this.records = this.order.transfers.filter(transfer => transfer.type === 'SAIDA_PRODUCAO' && originIds.includes(transfer.originId));
    },

    async loadSilos() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/silo/principal`);

        if (!data.silo) {
          throw 'PHP Error';
        }

        this.silos = data.silo.map(silo => ({
          index: (silo.descricao || '').replace(/\D/g, ''),
          id: silo.id_silo,
          label: silo.descricao,
          batchNumber: silo.numero_lote,
          vol: parseInt(silo.volume_atual),
          capacity: parseInt(silo.volume_total),
          rawProduct: {
            id: silo.id_materia_prima,
            name: silo.nome_materia_prima,
          },
        })).sort((a, b) => a.index - b.index);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          'Oops, ocorreu um erro ao carregar os silos!',
          'Atenção'
        );
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onSiloSelected(silo) {
      const index = this.silos.findIndex(s => s.id === silo.id);

      this.silos.splice(index, 1, {
        ...silo,
        showInput: true
      });
    },

    deselectSilo(silo) {
      const index = this.silos.findIndex(s => s.id === silo.id);

      this.silos.splice(index, 1, {
        ...silo,
        showInput: false
      });
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      this.hasError = this.transferringSilos.length === 0;

      if (this.hasError) {
        setTimeout(() => {
          this.hasError = false;
        }, 2000);

        return;
      }

      return this.$emit('onSiloProductionTransferring', {
        productionOrder: {
          id: this.order.id,
          description: this.order.description,
          itemId: this.order.item.id,
        },
        item: this.order.item,
        silos: this.transferringSilos.map(silo => ({
          id: silo.id,
          description: silo.description,
          transferringVol: parseFloat(silo.transferringVol),
        })),
      });
    },

    onEditSiloHistory(transferring) {
      if (!this.canEdit) {
        return;
      }

      return this.$emit('onSiloProductionTransferring', transferring);
    },

    validateRange(silo) {
      const item = this.items.find(item => item.itemId === silo.rawProduct.id);

      if (!item) {
        return true;
      }

      const value = silo.transferringVol;
      const rule = item.range;

      return validateRule(value, rule);
    },

    async removeTransfer(item) {
      if (!this.canEdit) {
        return;
      }

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir esta produção?<br><br>`, { color: 'red', token: 'EXCLUIR' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.post(`/industry/silo/chargeback-movement`, {
          id: item.movementId,
        });

        this.$snotify.success('Exclusão efetuada com sucesso', 'Sucesso');
        this.reload();
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao excluir a produção!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    reload() {
      this.$emit('reload');
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }

}
</script>
