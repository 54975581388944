<template>
  <div style="max-width: 1050px; padding: 9px; margin: auto;">
    <v-form
      ref="formRef"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-col
            cols="12"
            class="px-0 pt-0"
          >
            <card-title-settings>
              Crioscopia
            </card-title-settings>
          </v-col>

          <v-card
            dark
            outlined
            color="rgba(255, 255, 255, 0.08)"
          >
            <v-card-text class="pb-0">
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-select
                    v-model="form.chargeCryoscopy"
                    :items="booleanRules"
                    label="Cobrar crioscopia"
                    prepend-inner-icon="request_quote"
                    dense
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <masked-text-field
                    v-model.number="form.cryoscopyMultiplier"
                    label="Multiplicador de Valor Crioscopia"
                    prepend-inner-icon="double_arrow"
                    suffix="x VALOR LITRO"
                    :mask="masks.float"
                    unmask
                    :disabled="!form.chargeCryoscopy"
                    :rules="form.chargeCryoscopy ? [() => form.cryoscopyMultiplier > 0 || 'Campo obrigatório!'] : []"
                    dense
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-col
            cols="12"
            class="px-0 pt-0"
          >
            <card-title-settings>
              Análises
            </card-title-settings>
          </v-col>

          <v-card
            dark
            outlined
            color="rgba(255, 255, 255, 0.08)"
          >
            <v-card-text class="pb-0">
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-select
                    v-model="form.useLatestAnalysis"
                    :items="booleanRules"
                    label="Usar análises do mês anterior para produtores sem análise no mês de referência"
                    prepend-inner-icon="event_repeat"
                    dense
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-select
                    v-model="form.limitRangeAnalysis"
                    :items="booleanRules"
                    label="Limitar a redução na bonificação de CPP e CCS a apenas uma faixa em relação ao mês anterior"
                    prepend-inner-icon="vertical_align_bottom"
                    dense
                  />
                </v-col>
                <template v-if="hasMilkType">
                  <v-col
                    cols="4"
                  >
                    <v-select
                      v-model="form.limitQuantityAnalysis"
                      :items="booleanRules"
                      label="Limitar quantidade de análises para Geométrica CPP e CCS"
                      prepend-inner-icon="biotech"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <masked-text-field
                      v-model.number="form.analysisQuantityMilkB"
                      label="Quantidade Análises - Leite B"
                      prepend-inner-icon="science"
                      :mask="masks.integer"
                      unmask
                      :disabled="!form.limitQuantityAnalysis"
                      :rules="form.limitQuantityAnalysis ? [() => form.analysisQuantityMilkB > 0 || 'Campo obrigatório!'] : []"
                      dense
                      hint="Primeiras análises do mês"
                    />
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <masked-text-field
                      v-model.number="form.analysisQuantityMilkC"
                      label="Quantidade Análises - Leite C"
                      prepend-inner-icon="science"
                      :mask="masks.integer"
                      unmask
                      :disabled="!form.limitQuantityAnalysis"
                      :rules="form.limitQuantityAnalysis ? [() => form.analysisQuantityMilkC > 0 || 'Campo obrigatório!'] : []"
                      dense
                      hint="Primeiras análises do mês"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-col
            cols="12"
            class="px-0 pt-0"
          >
            <card-title-settings>
              Incentivo Produtores Novos
            </card-title-settings>
          </v-col>

          <v-card
            dark
            outlined
            color="rgba(255, 255, 255, 0.08)"
          >
            <v-card-text class="pb-0">
              <v-row>
                <v-col
                  cols="12"
                  class="text-left text-body-1"
                >
                  Receber primeira faixa de valor como incentivo
                </v-col>
                <v-col
                  cols="4"
                >
                  <masked-text-field
                    v-model.number="form.incentiveMonths"
                    label="Período Incentivo"
                    prepend-inner-icon="date_range"
                    suffix="MESES"
                    :mask="masks.integer"
                    unmask
                    dense
                  />
                </v-col>
                <v-col
                  cols="8"
                >
                  <v-select
                    v-model="form.incentiveItems"
                    label="Itens Incentivo"
                    prepend-inner-icon="view_stream"
                    :items="incentiveItems"
                    multiple
                    chips
                    deletable-chips
                    small-chips
                    :disabled="!form.incentiveMonths"
                    :rules="form.incentiveMonths ? [rules.requiredArray] : []"
                    dense
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fixed
            fab
            large
            dark
            bottom
            right
            color="green"
            class="mr-5"
            v-on="on"
            @click.stop="saveSettings();"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </template>

        <span>Salvar configurações</span>
      </v-tooltip>
    </v-form>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios, { queries } from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'

import CardTitleSettings from '@/Domains/Settings/Components/General/CardTitleSettings.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'

import { isAnalysisType } from '@/Domains/Producer/Bonus/BonusCriteria/Enums/QualityType.js';

const { progressBar, notify } = useUtils()

const rules = {
  required: v => !!v || 'Campo obrigatório!',
  requiredArray: v => v?.length > 0 || 'Campo obrigatório!',
}

const booleanRules = [
  { text: 'Ativo', value: true },
  { text: 'Inativo', value: false },
]

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
  percentual: { mask: Number, min: 0, max: 100, scale: 4 },
}

const hasMilkType = computed(() => {
  return store.state.settings.gerais?.cadastro_produtor?.dados_producao?.tipo_leite_entrega?.show;
})

const incentiveItems = ref([])

const formRef = ref()
const form = reactive({
  chargeCryoscopy: true,
  cryoscopyMultiplier: 1,
  useLatestAnalysis: false,
  limitRangeAnalysis: false,
  limitQuantityAnalysis: false,
  analysisQuantityMilkB: null,
  analysisQuantityMilkC: null,
  incentiveMonths: 0,
  incentiveItems: [],
})

const loadSettings = async () => {
  try {
    progressBar.loading()

    const dairyId = store.state.settings?.laticinio?.id;

    const { data } = await axios.get(`/bonus/settings/${dairyId}`)

    if (data) {
      form.chargeCryoscopy = data.calcular_crioscopia
      form.cryoscopyMultiplier = data.multiplicador_crioscopia
      form.useLatestAnalysis = data.usa_ultimas_analises
      form.limitRangeAnalysis = data.limitar_faixa_analises
      form.limitQuantityAnalysis = data.limitar_quantidade_analises
      form.analysisQuantityMilkB = data.quantidade_analises_leite_b
      form.analysisQuantityMilkC = data.quantidade_analises_leite_c
      form.incentiveMonths = data.meses_incentivo
      form.incentiveItems = data.itens_incentivo || []
    }
  } catch (e) {
    console.error(e)

  } finally {
    progressBar.hide()
  }
}

const saveSettings = async () => {
  if (!await formRef.value?.validate()) {
    return
  }
  try {
    progressBar.saving()

    const dairyId = store.state.settings?.laticinio?.id;

    if (!dairyId) {
      notify.error('Laticínio não selecionado!', 'Atenção');
      return;
    }

    await axios.post(`/bonus/settings/`, {
      id_laticinio: dairyId,
      calcular_crioscopia: form.chargeCryoscopy,
      multiplicador_crioscopia: form.cryoscopyMultiplier,
      usa_ultimas_analises: form.useLatestAnalysis,
      limitar_faixa_analises: form.limitRangeAnalysis,
      limitar_quantidade_analises: form.limitQuantityAnalysis,
      quantidade_analises_leite_b: form.analysisQuantityMilkB,
      quantidade_analises_leite_c: form.analysisQuantityMilkC,
      meses_incentivo: form.incentiveMonths,
      itens_incentivo: form.incentiveItems,
    })

    notify.success('Configurações salvas com sucesso', 'Sucesso')
  } catch (error) {
    notify.error('Erro ao salvar as configurações', 'Atenção')
    console.warn(error)

  } finally {
    progressBar.hide()
  }
}

const loadIncentiveItems = async () =>  {
  try {
    const { data } = await queries.post(`/qualidade/listaQualidadeJson`, { nome: '%' })

    incentiveItems.value = data
      .map(({ id_qualidade, item }) => ({ value: id_qualidade, text: item }))
      .filter(({ value }) => isAnalysisType(value))
  }
  catch (err) {
    notify.error('Erro ao carregar os itens de incentivo', 'Atenção')
    console.warn(err)
  }
}

onMounted(() => {
  loadSettings()
  loadIncentiveItems()
})

</script>
