<template>
  <div>
    <v-row class="text-body-2 ml-1 mr-1">
      <v-col
        v-if="suggestedSilos.length > 0"
        cols="12"
        class="pa-1 red--text"
      >
        TRANSFERÊNCIA SUGERIDA
      </v-col>
      <v-col
        v-for="silo in suggestedSilos"
        :key="silo.label"
        cols="6"
        sm="4"
        lg="2"
        class="pa-1"
      >
        <span
          class="mb-1 text-caption green--text"
        >
          {{ silo.suggestedTanks.join(', ') }}
        </span>
        <silo-tank-info
          :silo="silo"
          :available-vol="availableVol"
          :measured-vol="measuredVol"
          :tanks="tanks"
          style="border: 1px solid #ccc;"
          :disabled="disableTransfer"
          :overlimit="silo.overlimit"
          @onTankTransferring="onTankTransferring"
        />
      </v-col>
      <v-col
        v-if="suggestedSilos.length > 0"
        cols="12"
        class="pa-1 red--text"
      >
        TODOS
      </v-col>
      <v-col
        v-for="silo in allSilos"
        :key="silo.label"
        cols="6"
        sm="4"
        lg="2"
        class="pa-1"
      >
        <silo-tank-info
          :silo="silo"
          :available-vol="availableVol"
          :measured-vol="measuredVol"
          :tanks="tanks"
          :raw-material="rawMaterial"
          style="border: 1px solid #ccc;"
          :disabled="disableTransfer"
          :overlimit="silo.overlimit"
          @onTankTransferring="onTankTransferring"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SiloTankInfo from "@/Domains/Platform/Unload/Components/SiloTankInfo.vue";

import AnalysisPatternValidator from "@/Domains/Platform/Unload/Services/AnalysisPatternValidator.js";
import AnalysisDictionary from "@/Domains/Platform/Unload/Services/AnalysisDictionary.js";

import _ from "lodash";

export default {

  components: {
    'silo-tank-info': SiloTankInfo,
  },

  props: {

    availableVol: {
      type: Number,
    },

    measuredVol: {
      type: Number,
    },

    transferring: {
      type: Array,
    },

    multiTransfer: {
      type: Boolean,
      default: true,
    },

    tanks: {
      type: Array,
      default: () => ([]),
    },

    rawMaterial: {
      type: String,
    },

    overlimit: {
      type: Boolean
    }

  },

  data() {
    return {
      rawMaterials: [],

      // Silos disponíveis para transferência
      silos: [],
    };
  },

  computed: {

    availableSilos() {
      const tanksRawMaterials = [this.rawMaterial, ...this.tanks.filter(tank => tank.rawMaterial?.id).map(tank => tank.rawMaterial?.id)];

      return this.silos
        .filter(silo => {
          if (!silo.rawProduct.id) {
            return true;
          }

          return silo.allowedRawMaterials.some(id => tanksRawMaterials.includes(id));
        })
        .map(silo => {
          const siloTransfers = this.transferring.filter(transferring => transferring.id === silo.id);
          const totalTransfering = _.sumBy(_.flatten(_.map(siloTransfers, 'tanks')), 'transferring');
          const capacity = silo.capacity - silo.vol;

          return {
            ...silo,
            overlimit: totalTransfering > 0 && totalTransfering > capacity,
          };
        });
    },

    suggestedSilos() {
      return this.availableSilos.filter(silo => silo.suggestedTanks.length > 0)
    },

    allSilos() {
      return this.availableSilos.filter(silo => silo.suggestedTanks.length === 0)
    },

    transferringVol() {

      if (_.isEmpty(this.transferring)) {
        return 0;
      }

      return _.sumBy(this.transferring, 'transferring');
    },

    /**
     * Desabilita a transferência para outro silo quando algum já estiver selecionado
     * @returns {boolean}
     */
    disableTransfer() {

      if (this.availableVol === 0) {
        return true;
      }

      if (this.multiTransfer) {
        return false;
      }

      return this.transferringVol > 0;
    },

  },

  watch: {
    availableSilos: {
      deep: true,

      handler(newValue) {
        const hasOverlimit = newValue.some(silo => silo.overlimit);

        return this.$emit('update:overlimit', hasOverlimit);
      },
    },

  },

  async mounted() {
    await this.loadRawMaterials();
    await this.loadSilos();
  },

  methods: {
    async loadRawMaterials() {
      try {
        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawMaterials = data.map(materiaPrima => {
          return {
            id: materiaPrima.id_item,
            description: materiaPrima.nome,
            type: materiaPrima.tipo,
            rawMaterialGroupId: materiaPrima.id_agrupador_materia_prima,
          }
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os matérias primas!",
          "Atenção"
        );
      }
    },

    async loadSilos() {
      try {
        const { data } = await this.$axios.post(`/silo/principal`);
        if (!data.silo) {
          throw "PHP Error";
        }

        this.silos = data.silo.map(silo => {
          // Busca a partir da lista de matérias-primas o ID da matéria-prima pai (Agrupador)
          const childrenRawMaterials = this.rawMaterials
            .filter(item => item.rawMaterialGroupId === silo.id_materia_prima)
            .map(item => item.id)

          return {
            index: (silo.descricao || '').replace(/\D/g, ''),
            id: silo.id_silo,
            label: silo.descricao,
            vol: parseInt(silo.volume_atual),
            capacity: parseInt(silo.volume_total),
            rawProduct: {
              id: silo.id_materia_prima,
              name: silo.nome_materia_prima,
            },
            suggestedTanks: [],
            analysisStandards: (JSON.parse(silo.padroes_analise) || []),
            allowedRawMaterials: silo.id_materia_prima ? [silo.id_materia_prima, ...childrenRawMaterials] : [],
          }
        }).sort((a, b) => a.index - b.index);

        this.handleSuggestedTanks();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      }
    },

    handleSuggestedTanks() {
      this.silos = this.silos.map(silo => {
        if (silo.analysisStandards.length > 0) {
          silo.suggestedTanks = this.tanks
            .map(tank => {
              const problems = silo.analysisStandards.map((standard) => {
                const param = AnalysisDictionary[standard.parametro] || standard.parametro;
                const rule = standard.padrao;

                if (!_.has(tank.analysis, param)) {
                  return false;
                }

                const { value } = tank.analysis[param];

                return !(new AnalysisPatternValidator().fromRule(rule).validate(value));
              })

              const hasProblems = problems.some(result => result);

              if (!hasProblems) {
                return tank.label;
              }

              return null;
            })
            .filter(tank => tank);
        }
        return silo;
      })
    },

    onTankTransferring(newValue) {
      return this.$emit('onTankTransferring', newValue);
    },

  },

}
</script>
