<template>
  <div>
    <v-row>
      <v-col
        sm="8"
        md="9"
      >
        <v-tabs
          v-model="tab"
          dark
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#tab-programming">
            Programação
          </v-tab>
          <v-tab href="#tab-report">
            Relatório
          </v-tab>
        </v-tabs>
      </v-col>

      <v-col
        sm="4"
        md="3"
      >
        <v-date-range-picker
          v-model="filter.period"
          :ranges="dateRangeFilters"
          label="Período"
          dark
          @change="getSpots"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <v-tabs-items
          v-model="tab"
        >
          <v-tab-item value="tab-programming">
            <v-card
              v-if="plannings.length == 0"
              color="transparent"
              dark
            >
              <v-card-title class="d-flex justify-center py-12">
                Nenhuma programação encontrada para o período selecionado
              </v-card-title>
            </v-card>

            <v-card
              v-for="(items, idx) in plannings"
              :key="idx"
              color="transparent"
              dark
            >
              <v-card-title>
                Programação de {{ parseType(type) }} de {{ items.rawMaterial }}
                <v-spacer />
                <div class="hidden-xs-only">
                  <div class="d-flex">
                    <div
                      class="d-flex flex-column justify-end px-14"
                    >
                      <span class="body-2 font-weight-light">
                        Volume Total
                      </span>
                      <span
                        class="body-1 font-weight-bold cyan--text text--accent-3"
                      >{{ formatNumber(items.totals.total) }}</span>
                    </div>
                  </div>
                </div>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items.items"
                dark
                dense
                disable-sort
                hide-default-footer
                class="elevation-1 report-table"
              >
                <template
                  #[`body.prepend`]="{}"
                >
                  <tr class="hidden-xs-only">
                    <td />
                    <td
                      v-for="(date, idx) in dates"
                      :key="idx"
                      class="text-start text-truncate"
                    >
                      {{ formatDate(date.date, 'dddd') }}
                    </td>
                    <td />
                    <td />
                  </tr>
                </template>

                <template
                  v-for="(date, idx) in dates"
                  #[`item.${date.column}`]="{ value }"
                >
                  <div
                    :key="idx"
                    class="full-height"
                  >
                    {{ formatNumber(value?.volume) }}
                    <v-tooltip
                      v-if="value?.hasOrder"
                      bottom
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          info
                        </v-icon>
                      </template>

                      <div
                        v-for="(item, i) in value.items"
                        :key="idx + '_' + i"
                        class="d-block"
                      >
                        {{ formatNumber(item.volume) }}
                        <b v-if="item.ordem_compra">
                          (OC: {{ item.ordem_compra }})
                        </b>
                      </div>
                    </v-tooltip>
                  </div>
                </template>

                <template
                  #[`item.total`]="{ value }"
                >
                  <b>{{ formatNumber(value) }}</b>
                </template>

                <template
                  #[`body.append`]="{}"
                >
                  <tr
                    class="hidden-xs-only"
                  >
                    <td class="text-start">
                      <b>Total {{ items.rawMaterial }}</b>
                    </td>
                    <td
                      v-for="(date, idx) in dates"
                      :key="idx"
                      class="text-start"
                    >
                      {{ formatNumber(items.totals[date.column]) }}
                    </td>
                    <td class="text-end">
                      <b>{{ formatNumber(items.totals.total) }}</b>
                    </td>
                    <td />
                  </tr>
                </template>

                <template #[`item.actions`]="{ item }">
                  <v-menu>
                    <template #activator="{ on }">
                      <v-btn
                        dark
                        icon
                        v-on="on"
                      >
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="editPlanning(item)">
                        <v-list-item-icon>
                          <v-icon>edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Editar
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="removePlanning(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Excluir
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
              <v-card-title />
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-report">
            <v-card
              class="pa-0"
              color="transparent"
              dark
            >
              <v-card-title>
                Programação de {{ parseType(type) }}
              </v-card-title>
              <data-table
                :headers="headersReport"
                :items="items"
                dark
                show-custom-group
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <spot-dialog
      v-model="planningDialog.show"
      :spot="planningDialog.spot"
      :type="type"
      @change="getSpots"
    />

    <confirm ref="confirm" />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue darken-1"
        @click="newPlanning"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Novo spot
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import Confirm from "@/Support/Components/Confirm.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import SpotDialog from '@/Domains/Platform/Spots/Components/SpotDialog.vue';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import _ from "lodash";
import moment from "moment-timezone";

moment.locale('pt');

export default {
  name: "spot-planning",

  components: {
    VDateRangePicker,
    SpotDialog,
    Confirm,
  },

  mixins: [ReportMixin],

  props: {
    type: {
      type: String,
      default: 'ENTRADA'
    },
  },

  data() {
    return {
      tab: 'tab-planning',

      filter: {
        period: [],
      },

      plannings: [],

      items: [],

      planningDialog: {
        show: false,
        spot: null
      }
    };
  },

  computed: {
    dates() {
      let period = [];

      const [startDate, endDate] = this.filter.period;

      let dateAux = startDate;

      while (dateAux <= endDate) {
        const date = moment(dateAux);
        period.push({
          date,
          column: date.format('YYYY_MM_DD')
        });
        dateAux = date.clone().add(1, 'day').format('YYYY-MM-DD');
      }

      return period;
    },

    headers() {
      const headers = [
        { text: 'Empresa', value: 'company.name' },
      ];

      for (let date of this.dates) {
        headers.push({
          text: date.date.format('DD/MM/YYYY'),
          value: date.column
        })
      }

      headers.push(
        { text: 'Total', value: 'total', align: 'end' },
        { value: "actions", align: 'end', width: 40 }
      );

      return headers;
    },

    headersReport() {
      const headers = [
        { text: 'Empresa', value: 'nome_empresa' },
        { text: 'Matéria Prima', value: 'materia_prima' },
        { text: 'Data', value: 'data', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
      ];

      if (this.type === 'SAIDA') {
        headers.push({ text: 'Ordem de Compra', value: 'ordem_compra' });
      }

      headers.push(
        { text: 'Volume', value: 'volume', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Valor Litro', value: 'valor_litro', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.000' },
        { text: 'Valor Total', value: 'valor_total', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
      );

      return headers;
    },

    dateRangeFilters() {
      const today = moment().date();
      let currrentFortnight, previousFortnight, nextFortnight;

      if (today <= 15) {
        currrentFortnight = [moment().startOf("month").format("YYYY-MM-DD"), moment().date(15).format("YYYY-MM-DD")]
        previousFortnight = [moment().subtract(1, "month").date(16).format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")]
        nextFortnight = [moment().date(16).format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")]
      }
      else {
        currrentFortnight = [moment().date(16).format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")]
        previousFortnight = [moment().startOf("month").format("YYYY-MM-DD"), moment().date(15).format("YYYY-MM-DD")]
        nextFortnight = [moment().add(1, "month").startOf("month").format("YYYY-MM-DD"), moment().add(1, "month").date(15).format("YYYY-MM-DD")]
      }

      return {
        'Esta quinzena': currrentFortnight,
        'Quinzena Anterior': previousFortnight,
        'Próxima Quinzena': nextFortnight,
      }
    }
  },

  mounted() {
    this.filter.period = this.dateRangeFilters['Esta quinzena'];
  },

  methods: {
    async getSpots() {
      this.$root.$progressBar.loading()

      try {
        const [startDate, endDate] = this.filter.period;

        const { data } = await this.$axios.post(`/spot/relatorio`, {
          data_inicio: startDate,
          data_fim: endDate,
          tipo: this.type
        });

        this.items = data;

        this.plannings = _(data)
          .map(o => ({
            key: `${o.id_empresa}_${o.id_materia_prima}`,
            ...o,
          }))
          .groupBy('key')
          .map((items) => {
            const { id_empresa, nome_empresa, id_materia_prima, materia_prima } = items[0];
            return {
              company: {
                id: id_empresa,
                name: nome_empresa
              },
              rawMaterial: {
                id: id_materia_prima,
                name: materia_prima,
              },
              ...items.reduce((acc, cur) => {
                const key = moment(cur.data).format('YYYY_MM_DD');

                if (!(key in acc)) {
                  acc[key] = {
                    volume: 0,
                    items: [],
                    hasOrder: false,
                  };
                }

                cur.volume = parseFloat(cur.volume);

                acc[key].volume += cur.volume;
                acc[key].items.push(cur);

                if (cur.ordem_compra) {
                  acc[key].hasOrder = true;
                }

                return acc;
              }, {}),
              total: items.reduce((acc, cur) => (acc + parseFloat(cur.volume)), 0),
              items
            }
          })
          .groupBy('rawMaterial.name')
          .map((items, rawMaterial) => {
            return {
              rawMaterial,
              items,
              totals: items.reduce((acc, cur) => {
                cur.items.forEach(item => {
                  const date = moment(item.data).format('YYYY_MM_DD');
                  if (!(date in acc)) {
                    acc[date] = 0;
                  }
                  acc[date] += parseFloat(item.volume);
                  acc.total += parseFloat(item.volume);
                });
                return acc;
              }, { total: 0 })
            }
          }).value();

      } catch (error) {
        console.warn(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os spots!",
          "Atenção"
        );
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    newPlanning() {
      this.planningDialog = {
        show: true,
        spot: null
      }
    },

    editPlanning(item) {
      this.planningDialog = {
        show: true,
        spot: {
          company: item.company,
          rawMaterialId: item.rawMaterial.id,
          period: this.filter.period,
        }
      }
    },

    async removePlanning(item) {
      if (!(await this.$refs.confirm.open('Remover registro', 'Tem certeza que deseja remover este planejamento?', { color: 'red' }))) {
        return;
      }
      this.$root.$progressBar.saving()
      try {

        const [startDate, endDate] = this.filter.period;

        const { data } = await this.$axios.post(
          `/spot/remove`,
          {
            data_ini: startDate,
            data_fim: endDate,
            id_empresa: item.company.id,
            id_materia_prima: item.rawMaterial.id,
            tipo: this.type
          },
        );

        if (data.codigo != 1) {
          throw data.mensagem || data;
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao remover o planejamento!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide()
        this.getSpots();
      }
    },

    getReportJson({ items, totals, rawMaterial }, isExcel = false) {
      const formatNumber = isExcel ? this.formatNumberExcel : this.formatNumber;
      return [
        // Dias da semana
        {
          Empresa: '',
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: cur.date.format('dddd')
          }), {}),
          Total: '',
        },
        // Spots
        ...items.map(item => ({
          Empresa: item.company.name,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(item[cur.column])
          }), {}),
          Total: formatNumber(item.total),
        })),
        // Totais por dia
        {
          Empresa: `Total ${rawMaterial}`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(totals[cur.column])
          }), {}),
          Total: formatNumber(totals.total),
        }
      ];
    },

    getReportTitle() {
      const [startDate = moment(), endDate = moment()] = this.filter.period;
      return `Programação de Spot - ${ this.type } - ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const title = this.getReportTitle();
      const plannings = [...this.plannings];

      const first = plannings.shift();

      const worksheet = this.XLSX.utils.json_to_sheet(this.getReportJson(first, true), { origin: 'A3' });

      worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: this.dates.length + 1 } }];

      worksheet['A1'] = { v: `Programação de ${ this.parseType(this.type) } de ${ first.rawMaterial }` };

      let startRow = first.items.length;
      plannings.forEach(items => {
        startRow += 6;
        this.XLSX.utils.sheet_add_json(worksheet, this.getReportJson(items, true), { skipHeader: true, origin: startRow + 2 });

        worksheet["!merges"].push({ s: { r: startRow, c: 0 }, e: { r: startRow, c: this.dates.length + 1 } });
        worksheet[`A${startRow + 1}`] = { v: `Programação de ${ this.parseType(this.type) } de ${ items.rawMaterial }` };
        startRow += items.items.length;
      })

      worksheet['!cols'] = [{ wch: 35 }];

      this.dates.forEach(() => {
        worksheet['!cols'].push({ wch: 12 });
      })

      worksheet['!cols'].push({ wch: 12 });

      const workbook = this.XLSX.utils.book_new();

      this.XLSX.utils.book_append_sheet(workbook, worksheet, _.truncate(title, { length: 31 }));
      this.XLSX.writeFile(workbook, `${title}.xlsx`);
    },

    /**
     * Imprime o relatório
     */
    print() {
      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      const pages = this.plannings
        .map(items => {
          const title = `Programação de ${ this.parseType(this.type) } de ${ items.rawMaterial }`;
          const json = this.getReportJson(items);

          const body = [{
            tag: 'section',
            class: 'row',
            children: [{
              tag: 'datatable',
              headers: Object.keys(json[0])
                .map(header => ({
                  key: header,
                  label: header,
                  class: 'text-left'
                })),
              items: json
            }]
          }];

          return { title, header, body };
        });

      this.printPdf({ pages, landscape: true });
    },

    parseType(value) {
      const types = {
        SAIDA: 'Saída',
        ENTRADA: 'Entrada',
      };

      return types[value] || value?.toLowerCase();
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => value ? new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value) : '-',
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
    formatNumberExcel: (value) => value == null || isNaN(value) ? '' : parseFloat(value),
  },
};
</script>
