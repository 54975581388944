<template>
  <v-dialog
    v-model="show"
    width="800"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6"> Documento </span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col
              :cols="!edit ? 6 : 12"
              class="pb-0"
            >
              <person-autocomplete-filter
                v-model="form.producer"
                label="Produtor *"
                type="PRODUCER"
                :filled="false"
                :hide-details="false"
                :rules="[(v) => Object.keys(v).length > 0 || 'Campo obrigatório!']"
                :readonly="isCardDisabled"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-autocomplete
                v-if="!edit"
                v-model="form.baseDocument"
                :items="baseDocuments"
                item-text="title"
                item-value="id"
                return-object
                prepend-inner-icon="description"
                label="Documento Base"
                clearable
                :readonly="isCardDisabled"
                @change="onBaseDocumentChange"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-text-field
                v-model="form.title"
                label="Título *"
                prepend-inner-icon="description"
                :rules="[(v) => !!v || 'Campo obrigatório!']"
                :readonly="isCardDisabled"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0 d-flex justify-space-between"
            >
              <v-btn
                class="flex-grow-1 mx-1"
                :disabled="isCardDisabled"
                @click="onFieldClick('#nome#')"
              >
                NOME
              </v-btn>
              <v-btn
                class="flex-grow-1 mx-1"
                :disabled="isCardDisabled"
                @click="onFieldClick('#cnpj_cpf#')"
              >
                CNPJ/CPF
              </v-btn>
              <v-btn
                class="flex-grow-1 mx-1"
                :disabled="isCardDisabled"
                @click="onFieldClick('#rg#')"
              >
                RG
              </v-btn>
              <v-btn
                class="flex-grow-1 mx-1"
                :disabled="isCardDisabled"
                @click="onFieldClick('#municipio#')"
              >
                MUNICÍPIO
              </v-btn>
              <v-btn
                class="flex-grow-1 mx-1"
                :disabled="isCardDisabled"
                @click="onFieldClick('#uf#')"
              >
                UF
              </v-btn>
              <v-btn
                class="flex-grow-1 mx-1"
                :disabled="isCardDisabled"
                @click="onFieldClick('#bairro#')"
              >
                BAIRRO
              </v-btn>
              <v-btn
                class="flex-grow-1 mx-1"
                :disabled="isCardDisabled"
                @click="onFieldClick('#endereco#')"
              >
                ENDEREÇO
              </v-btn>
              <v-btn
                class="flex-grow-1 mx-1"
                :disabled="isCardDisabled"
                @click="onFieldClick('#cidasc#')"
              >
                CIDASC/IMA
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-textarea
                ref="text"
                v-model="form.text"
                label="Texto *"
                outlined
                :rows="15"
                :rules="[(v) => !!v || 'Campo obrigatório!']"
                :readonly="isCardDisabled"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="green"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

export default {
  components: {
    PersonAutocompleteFilter,
  },

  props: {
    value: Boolean,
    edit: Object,
    baseDocuments: Array,
  },

  data() {
    return {
      form: {
        producer: {},
        baseDocument: {},
        text: "",
        title: "",
      },

      isCardDisabled: false,
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          producer: {},
          baseDocument: {},
          text: "",
          title: "",
        };

        this.isCardDisabled = false;

        if (this.edit) {
          this.form = { ...this.edit }

          if (this.edit.status == 'Assinado') {
            this.isCardDisabled = true;
          }
        }
      }
    },
  },

  methods: {
    onFieldClick(value) {
      const textarea = this.$refs.text.$el.querySelector("textarea");
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = this.form.text;

      this.form.text = text.substring(0, start) + value + text.substring(end, text.length);

      this.$nextTick(() => {
        textarea.focus();
        const newCursorPosition = start + value.length;
        textarea.setSelectionRange(newCursorPosition, newCursorPosition);
      });
    },

    onBaseDocumentChange(newValue) {
      if (newValue) {
        this.form.text = newValue.text;
        this.form.title = newValue.title;
      } else {
        this.form.text = "";
        this.form.title = "";
      }
    },

    async save() {
      try {
        if (!(await this.$refs.form.validate())) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_produtor: this.form.producer.id,
          titulo: this.form.title,
          texto_base: this.form.text,
        };

        const response = this.edit ? await this.update(payload) : await this.store(payload);

        this.$emit("save", response.id);
        this.close();

        this.$snotify.success("Documento salvo com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar documento.", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/producerDocument`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/producerDocument/${this.edit.id}`, payload);
      return data;
    },

    close() {
      this.show = false;
    },
  },
};
</script>