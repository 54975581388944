<template>
  <v-container>
    <v-card
      dark
      class="v-tamanho route-list"
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <date-range-picker
              v-model="filters.transferredAt.input"
              label="Movimentado Em"
              @change="onTransferredAtDateFilter"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.types"
              :items="types"
              clearable
              filled
              hide-details
              label="Tipo"
              multiple
              prepend-inner-icon="swap_vert"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.silos"
              :items="silos"
              clearable
              filled
              item-value="id"
              item-text="label"
              hide-details
              label="Silos"
              multiple
              prepend-inner-icon="panorama_vertical_select"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        :loading="loading"
        :headers="headers"
        :items="filteredHistory"
        class="elevation-1"
        group-by="code"
        @click:row="onEditSiloHistory"
      >
        <template #[`group.header`]="{ group, isOpen, toggle, items }">
          <td class="text-start">
            <v-btn
              icon
              @click="toggle"
            >
              <v-icon>
                {{ isOpen ? 'remove' : 'add' }}
              </v-icon>
            </v-btn>
          </td>
          <td class="text-start">
            <span v-if="group">
              Itinerário: <b>{{ group }}</b>
            </span>
          </td>
          <td
            class="text-start"
            :colspan="headers.length - 2"
          >
            Total:
            <v-chip small>
              <v-avatar left>
                <v-icon
                  v-if="sumBy(items, 'transferredVol') < 0"
                  color="red"
                  v-text="'arrow_circle_up'"
                />
                <v-icon
                  v-else
                  color="green"
                  v-text="'arrow_circle_down'"
                />
              </v-avatar>

              {{ formatNumber(Math.abs(sumBy(items, 'transferredVol'))) }}
            </v-chip>
          </td>
        </template>

        <template #[`item.transferredVol`]="{ item }">
          <v-chip small>
            <v-avatar left>
              <v-icon
                v-if="item.inOut === 'OUT'"
                color="red"
                v-text="'arrow_circle_up'"
              />
              <v-icon
                v-else
                color="green"
                v-text="'arrow_circle_down'"
              />
            </v-avatar>

            <span v-if="item.transferredVol !== 0">
              {{ formatNumber(Math.abs(item.transferredVol)) }}
            </span>

            <span v-else>
              ----
            </span>
          </v-chip>
        </template>

        <template #[`item.rawMaterial.description`]="{ value }">
          <v-chip
            v-if="value"
            small
            v-text="value"
          />
        </template>

        <template #[`item.type`]="{ item }">
          <v-chip
            small
          >
            {{ typeValue(item.type) }}
          </v-chip>
          <v-tooltip
            v-if="!!item.chargebackId"
            top
          >
            <template #activator="{ on }">
              <v-icon
                color="orange"
                v-on="on"
              >
                restart_alt
              </v-icon>
            </template>

            Estornado em {{ formatDate(item.chargebackDate, 'DD/MM/YYYY HH:mm') }}
          </v-tooltip>
        </template>

        <template #[`item.literPrice`]="{ item }">
          <div class="d-flex align-center">
            <v-menu
              v-if="item.hasReferences"
              max-width="350"
              max-height="300"
              :close-on-content-click="false"
            >
              <template #activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      @click="showPriceDetail(item)"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon small>
                        info
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Detalhar preço do litro</span>
                </v-tooltip>
              </template>
              <v-list v-if="!!item.references">
                <v-list-item>
                  <v-list-item-title>Referência: <b>{{ formatDate(item.references.mes, 'MM/YYYY') }}</b></v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="(item, index) in item.references.transferencias"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.nome_produtor }}</v-list-item-title>
                    <v-list-item-subtitle>
                      Volume: <b>{{ formatNumber(item.volume_liquido) }} L</b> |
                      Valor litro: <b>{{ formatCurrency(item.valor_litro) }}</b> |
                      Valor total: <b>{{ formatCurrency(item.valor_total) }}</b>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

            <div>{{ formatCurrency(item.literPrice) }}</div>
          </div>
        </template>

        <template #[`item.freightPrice`]="{ value }">
          {{ formatCurrency(value) }}
        </template>

        <template #[`item.finalPrice`]="{ value }">
          {{ formatCurrency(value) }}
        </template>

        <template #[`item.questionnaire`]="{ item }">
          <v-tooltip
            v-if="item.type === 'CIP' && (item.questionnaire.answerId || questionnaireCip.id)"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                :color="item.questionnaire.answerId ? 'orange lighten-3' : 'grey'"
                dark
                icon
                v-on="on"
                @click="openCipQuestionnaire(item)"
              >
                <v-icon>receipt_long</v-icon>
              </v-btn>
            </template>
            Checklist de CIP
          </v-tooltip>
        </template>

        <template #[`item.action`]="{ item }">
          <v-menu v-if="item.type === 'ENTRE_SILOS' || (!item.chargebackId && !['ESTORNO', 'CIP'].includes(item.type))">
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                small
                v-on.stop="on"
              >
                <v-icon
                  dark
                  dense
                >
                  more_vert
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="item.type === 'ENTRE_SILOS'"
                @click="onEditSiloHistory(item)"
              >
                <v-list-item-avatar>
                  <v-icon>
                    create
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!item.chargebackId && !['ESTORNO', 'CIP'].includes(item.type) && hasPermissionToReverseSilos"
                @click="chargeBackTransfer(item)"
              >
                <v-list-item-avatar>
                  <v-icon>
                    delete
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Estornar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template #[`footer.prepend`]>
          <div
            v-if="totalPrice"
            class="text-caption text-left ml-4"
          >
            Custo Leite:
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatCurrency(totalPrice) }}
            </v-chip>
          </div>
          <div
            v-if="totalFreight"
            class="text-caption text-left ml-4"
          >
            Custo Frete:
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatCurrency(totalFreight) }}
            </v-chip>
          </div>
        </template>
      </data-table>
    </v-card>

    <silo-to-silo-transfer-dialog
      ref="siloToSiloTransferDialog"
      @onSiloTransferred="onSiloTransferred"
    />

    <recalculate-price-movements-dialog
      ref="priceMovementsDialog"
      @recalculated="loadHistory"
    />

    <questionnaire-form-dialog
      ref="questionnaireFormDialog"
      object="CIP_SILO"
      @save="onQuestionnaireSaved()"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="hasRecalculationAccess"
        fab
        dark
        color="black darken-1"
        @click="onRecalculateMovements()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              currency_exchange
            </v-icon>
          </template>

          Recalcular preço litro de movimentações
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import moment from "moment";
import * as _ from "lodash";

import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import SiloToSiloTransferDialog from "@/Domains/Platform/Silos/Components/SiloToSiloTransferDialog.vue";
import RecalculatePriceMovementsDialog from "@/Domains/Platform/Silos/Components/RecalculatePriceMovementsDialog.vue";
import QuestionnaireFormDialog from '@/Domains/Questionnaires/Components/QuestionnaireFormDialog.vue';

const TypesEnum = {
  ENTRADA_TANQUE: 'Entrada Coleta',
  ENTRADA_PRODUCAO: 'Entrada Produção',
  SAIDA_PRODUCAO: 'Saída Produção',
  AJUSTE: 'Ajuste',
  CIP: 'CIP',
  ENTRE_SILOS: 'Transferência Entre Silos',
  SAIDA_UNIDADE: 'Saída Unidade',
  ENTRADA_UNIDADE: 'Entrada Unidade',
  SAIDA_SPOT: 'Saída Spot',
  ENTRADA_SPOT: 'Entrada Spot',
  ESTORNO: 'Estorno',
  DESCARTE: 'Descarte',
}

export default {

  components: {
    RecalculatePriceMovementsDialog,
    SiloToSiloTransferDialog,
    QuestionnaireFormDialog,
    DateRangePicker,
  },

  data() {
    return {
      // Loader
      loading: false,

      filters: {
        search: '',
        transferredAt: {
          input: "today",
          range: [],
        },
        types: [],
        silos: [],
      },

      types: Object.entries(TypesEnum).map(([value, text]) => ({ value, text })),

      silos: [],

      history: [],

      questionnaireCip: {}

    };
  },

  computed: {

    /**
     * Exibe os pedidos com base nos filtros selecionados em tela
     */
    filteredHistory() {
      if (_.isEmpty(this.filters.types) && _.isEmpty(this.filters.silos) && _.isEmpty(this.filters.search)) {
        return this.history;
      }

      const search = (this.filters.search || '').toUpperCase().trim();

      return this.history.filter(item => {
        const hasType = _.isEmpty(this.filters.types) || this.filters.types.includes(item.type);
        const hasSilo = _.isEmpty(this.filters.silos) || this.filters.silos.includes(item.silo.id);
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return hasType && hasSilo && hasSearch;
      });
    },

    headers() {
      const headers = [
        { text: 'GUID', value: 'id' },
        { text: 'Código', value: 'code', width: 30 },
        { text: 'Silo', value: 'silo.description' },
        { text: 'Movimentado Em', value: 'transferredAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
        { text: 'Volume', value: 'transferredVol' },
        { text: 'Tipo', value: 'type', formatter: value => this.typeValue(value) },
        { text: 'Origem/Destino', value: 'route.description' },
        { text: 'Tanque', value: 'tank', formatter: value => this.getTankName(value) },
        { text: 'Matéria Prima', value: 'rawMaterial.description' },
        { text: 'Nº Lote', value: 'batchNumber' },
      ];

      if (this.hasLiterPriceAccess) {
        headers.push({ text: 'Valor Litro', value: 'literPrice', mask: 'R$ #,##0.0000' });
        headers.push({ text: 'Valor Frete', value: 'freightPrice', mask: 'R$ #,##0.0000' });
        headers.push({ text: 'Valor Final', value: 'finalPrice', mask: 'R$ #,##0.0000' });
      }

      headers.push(
        { text: 'Observação', value: 'obs' },
        { text: 'Checklist', value: 'questionnaire', formatter: value => value?.answerId },
        { text: '', value: 'action', width: 30, align: 'end', altText: 'Opções', sortable: false, drag: false },
      );

      return headers;
    },

    totalPrice() {
      return this.history.reduce((total, item) => total + (item.transferredVol * (item.literPrice || 0)), 0);
    },

    totalFreight() {
      return this.history.reduce((total, item) => total + (item.transferredVol * (item.freightPrice || 0)), 0);
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasRecalculationAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-movement-recalculation' && o.tipo === 'COMPONENTE');
    },
    hasLiterPriceAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-movement-price' && o.tipo === 'COMPONENTE');
    },

    hasPermissionToReverseSilos() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "can-reverse-silo" && o.tipo === "COMPONENTE");
    },
  },

  mounted() {
    this.loadSilos();
    this.loadCipQuestionnaire();
    this.loadHistory();
  },

  methods: {

    async loadHistory() {
      try {
        const { data } = await this.$axios.post(
          `/silo/listaMovimentos`,
          this.$qs.stringify({
            data_baixa_ini: _.head(this.filters.transferredAt.range) || moment().format("YYYY-MM-DD"),
            data_baixa_fim: _.last(this.filters.transferredAt.range) || moment().format("YYYY-MM-DD")
          })
        );

        if (!_.isArray(data)) {
          throw "PHP Error";
        }

        this.history = data.map(history => {
          return {
            id: history.id_silo_movimento,
            code: history.cod_itinerario,
            silo: {
              id: history.id_silo,
              description: history.descricao,
            },
            route: {
              id: history.id_rota,
              description: history.descricao_rota,
            },
            rawMaterial: {
              id: history.id_materia_prima,
              description: history.nome_materia_prima,
            },
            transferringId: history.id_transferencia,
            transferredVol: parseInt(history.volume),
            transferredAt: history.data,
            tank: history.tanque,
            obs: history.observacao,
            type: history.tipo,
            inOut: history.volume > 0 ? 'IN' : 'OUT',
            chargebackId: history.id_silo_movimento_estorno,
            chargebackDate: history.data_estorno,
            batchNumber: history.numero_lote,
            literPrice: parseFloat(history.valor_litro) || null,
            freightPrice: parseFloat(history.valor_frete) || null,
            finalPrice: (parseFloat(history.valor_litro) || 0) + (parseFloat(history.valor_frete) || 0),
            hasReferences: !!history.referencias,
            references: null,
            questionnaire: {
              formId: history.id_formulario,
              answerId: history.id_formulario_customizado_cabecalho,
            },
          };
        })
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      }
    },

    onSiloTransferred() {
      return this.loadHistory();
    },

    onTransferredAtDateFilter(event) {
      this.filters.transferredAt.range = event;

      return this.loadHistory();
    },

    onEditSiloHistory(item) {
      if (item.type === 'ENTRE_SILOS') {
        return this.$refs.siloToSiloTransferDialog.show({
          transferringId: item.transferringId
        });
      }
    },

    async chargeBackTransfer(transferring) {
      try {
        if (!(await this.$root.$confirm(
          'Atenção',
          `Deseja realmente estornar esta movimentação?<br><br>
          <div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined red--text body-2">
            Itens relacionados ao movimento como: <b>Produção, Análises, Spot, Unidade, etc.</b> serão excluídos.
          </div>
          Esta ação não poderá ser revertida!<br><br>`,
          { color: 'red', token: 'ESTORNAR' }
        ))) {
          return;
        }

        this.loading = true;

        await this.$axios.post(`/industry/silo/chargeback-movement`, {
          id: transferring.id,
        });

        return this.loadHistory();
      } catch (e) {
        console.log(e);
        const message = _.get(e, 'response.data.message', e);

        this.$snotify.error(
          message,
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadSilos() {
      try {
        const { data } = await this.$axios.post(`/silo/principal`);

        if (!data.silo) {
          throw "PHP Error";
        }

        this.silos = data.silo.map(silo => {
          return {
            index: (silo.descricao || '').replace(/\D/g, ''),
            id: silo.id_silo,
            label: silo.descricao,
            vol: parseInt(silo.volume_atual),
            capacity: parseInt(silo.volume_total),
          }
        }).sort((a, b) => a.index - b.index);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      }
    },

    onRecalculateMovements() {
      this.$refs.priceMovementsDialog.show();
    },

    async showPriceDetail(item) {
      if (item.references) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/industry/silo/movement/${item.id}/price-detail`);

        const index = this.history.findIndex(h => h.id === item.id);

        this.history.splice(index, 1, {
          ...item,
          references: data
        });

      } catch (e) {
        const message = _.get(e, 'response.data.message', 'Erro ao detalhar preço');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadCipQuestionnaire() {
      try {
        const { data } = await this.$axios.get(`/questionnaires/forms`, { params: {
          objeto: 'CIP_SILO',
        } });

        this.questionnaireCip = {
          id: data?.[0]?.id_formulario,
          title: data?.[0]?.titulo,
        };
      } catch (e) {
        console.log(e);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os checklist de CIP!', 'Atenção');
      }
    },

    async openCipQuestionnaire(item) {
      this.$refs.questionnaireFormDialog.show({
        id: item.questionnaire.formId || this.questionnaireCip.id,
        answerId: item.questionnaire.answerId,
        objectId: item.id,
      });
    },

    onQuestionnaireSaved() {
      return this.loadHistory();
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.transferredAt.range;
      return `Movimentações do Silo - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      this.$refs.report.exportExcel(null, this.getReportTitle());
    },

    print() {
      this.$refs.report.print(null, this.getReportTitle());
    },

    typeValue: (value) => TypesEnum[value],

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),

    getTankName: (value) => {
      const tanks = {
        tanque1: 'Tanque 1',
        tanque2: 'Tanque 2',
        tanque3: 'Tanque 3',
        tanque4: 'Tanque 4',
        tanque5: 'Tanque 5',
        tanque6: 'Tanque 6',
        tanque7: 'Tanque 7',
        tanque8: 'Tanque 8'
      }

      return tanks[value] || ''

    },

    sumBy: _.sumBy,

    formatCurrency: (value) => !value ? '-' : 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),

  }

}
</script>
