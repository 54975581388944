<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Configurações de atendimento {{ type }}
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-row>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Causa</span>
                <v-switch
                  v-model="item.obrigatorio_causa"
                  :label="item.obrigatorio_causa ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostra_causa"
                  :label="item.mostra_causa ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_causa"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Indicador</span>
                <v-switch
                  v-model="item.obrigatorio_indicador"
                  :label="item.obrigatorio_indicador ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostra_indicador"
                  :label="item.mostra_indicador ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_indicador"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Recomendação</span>
                <v-switch
                  v-model="item.obrigatorio_recomendacao"
                  :label="item.obrigatorio_recomendacao ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostra_recomendacao"
                  :label="item.mostra_recomendacao ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_recomendacao"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>N Serviços</span>
                <v-switch
                  v-model="item.obrigatorio_num_servicos"
                  :label="item.obrigatorio_num_servicos ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_num_servicos"
                  :label="item.mostrar_num_servicos ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_num_servicos"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Valor dos serviços</span>
                <v-switch
                  v-model="item.obrigatorio_valor_dos_servicos"
                  :label="item.obrigatorio_valor_dos_servicos ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_valor_dos_servicos"
                  :label="item.mostrar_valor_dos_servicos ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_valor_dos_servicos"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="(type == 'VETERINARIO' || type == 'VISITA')"
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Quantidade de animais</span>
                <v-switch
                  v-model="item.obrigatorio_quantidade_animais"
                  :label="item.obrigatorio_quantidade_animais ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_quantidade_animais"
                  :label="item.mostrar_quantidade_animais ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_quantidade_animais"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="type == 'AGRONOMICO'"
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Área</span>
                <v-switch
                  v-model="item.obrigatorio_area"
                  :label="item.obrigatorio_area ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="type == 'AGRONOMICO'"
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>N de Talhões</span>
                <v-switch
                  v-model="item.obrigatorio_num_talhoes"
                  :label="item.obrigatorio_num_talhoes ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="type == 'AGRONOMICO'"
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>ID de Talhões</span>
                <v-switch
                  v-model="item.obrigatorio_id_talhoes"
                  :label="item.obrigatorio_id_talhoes ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="(type == 'VETERINARIO' || type == 'VISITA')"
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Id Animais</span>
                <v-switch
                  v-model="item.obrigatorio_id_animais"
                  :label="item.obrigatorio_id_animais ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_id_animais"
                  :label="item.mostrar_id_animais ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_id_animais"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Fotos / Anexos</span>
                <v-switch
                  v-model="item.obrigatorio_fotos_anexos"
                  :label="item.obrigatorio_fotos_anexos ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_fotos_anexos"
                  :label="item.mostrar_fotos_anexos ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_fotos_anexos"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Km Percorrido</span>
                <v-switch
                  v-model="item.obrigatorio_km_percorrido"
                  :label="item.obrigatorio_km_percorrido ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_km_percorrido"
                  :label="item.mostrar_km_percorrido ?  'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_km_percorrido"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Coleta de Amostra</span>
                <v-switch
                  v-if="type != 'VETERINARIO' "
                  v-model="item.obrigatorio_coleta_amostra"
                  :label="item.obrigatorio_coleta_amostra ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_coleta_amostra"
                  :label="item.mostrar_coleta_amostra ?  'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_coleta_amostra"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Produto</span>
                <v-switch
                  v-model="item.mostrar_produto"
                  :label="item.mostrar_produto ?  'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="save();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import _ from "lodash";

export default {

  components: {
  },
  props: {
    type: String,

  },

  data() {
    return {

      item: {
        obrigatorio_causa: false,
        obrigatorio_quantidade_animais: false,
        obrigatorio_indicador: false,
        obrigatorio_recomendacao: false,
        obrigatorio_servico: false,
        obrigatorio_area: false,
        mostra_causa: true,
        mostra_indicador: true,
        mostra_recomendacao: true,
        id_configuracao: null,
        obrigatorio_fotos_anexos: false,
        obrigatorio_num_servicos: false,
        obrigatorio_id_animais: false,
        obrigatorio_km_percorrido: false,
        obrigatorio_valor_dos_servicos: false,
        mostrar_fotos_anexos: null,
        mostrar_num_servicos: null,
        mostrar_quantidade_animais: null,
        mostrar_id_animais: null,
        mostrar_km_percorrido: null,
        mostrar_valor_dos_servicos: null,
        obrigatorio_num_talhoes: false,
        obrigatorio_id_talhoes: false,
        obrigatorio_coleta_amostra: false,
        mostrar_coleta_amostra: false,
        mostrar_produto: false,
      },
    }

  },

  mounted() {
    this.getSettings();
  },

  watch: {
    'item.obrigatorio_causa': function(newVal) {
      if (newVal) {
        this.item.mostra_causa = true;
      }
    },
    'item.obrigatorio_indicador': function(newVal) {
      if (newVal) {
        this.item.mostra_indicador = true;
      }
    },
    'item.obrigatorio_recomendacao': function(newVal) {
      if (newVal) {
        this.item.mostra_recomendacao = true;
      }
    },
    'item.obrigatorio_num_servicos': function(newVal) {
      if (newVal) {
        this.item.mostrar_num_servicos = true;
      }
    },
    'item.obrigatorio_valor_dos_servicos': function(newVal) {
      if (newVal) {
        this.item.mostrar_valor_dos_servicos = true;
      }
    },
    'item.obrigatorio_quantidade_animais': function(newVal) {
      if (newVal) {
        this.item.mostrar_quantidade_animais = true;
      }
    },
    'item.obrigatorio_id_animais': function(newVal) {
      if (newVal) {
        this.item.mostrar_id_animais = true;
      }
    },
    'item.obrigatorio_fotos_anexos': function(newVal) {
      if (newVal) {
        this.item.mostrar_fotos_anexos = true;
      }
    },
    'item.obrigatorio_km_percorrido': function(newVal) {
      if (newVal) {
        this.item.mostrar_km_percorrido = true;
      }
    },
    'item.obrigatorio_coleta_amostra': function(newVal) {
      if (newVal) {
        this.item.mostrar_coleta_amostra = true;
      }
    },
  },

  methods: {
    async getSettings() {
      try {
        this.$root.$progressBar.loading();
        let { data } = await this.$axios.get(`/settings/care`, { params: {
          tipo: this.type,
        } });

        if (!_.isEmpty(data)) {
          this.item = _.first(data);
        }

      } catch (e) {
        this.$snotify.error("Erro ao carregar configuracao", "Atenção");
        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {

        this.$root.$progressBar.saving();

        const payload = {
          obrigatorio_causa: this.item.obrigatorio_causa,
          obrigatorio_quantidade_animais: this.item.obrigatorio_quantidade_animais,
          obrigatorio_indicador: this.item.obrigatorio_indicador,
          obrigatorio_recomendacao: this.item.obrigatorio_recomendacao,
          obrigatorio_servico: this.item.obrigatorio_servico,
          obrigatorio_area: this.item.obrigatorio_area,
          mostra_causa: this.item.mostra_causa,
          mostra_indicador: this.item.mostra_indicador,
          mostra_recomendacao: this.item.mostra_recomendacao,
          tipo: this.type,
          obrigatorio_fotos_anexos: this.item.obrigatorio_fotos_anexos,
          obrigatorio_num_servicos: this.item.obrigatorio_num_servicos,
          obrigatorio_id_animais: this.item.obrigatorio_id_animais,
          obrigatorio_km_percorrido: this.item.obrigatorio_km_percorrido,
          obrigatorio_valor_dos_servicos: this.item.obrigatorio_valor_dos_servicos,
          mostrar_fotos_anexos: this.item.mostrar_fotos_anexos,
          mostrar_num_servicos: this.item.mostrar_num_servicos,
          mostrar_quantidade_animais: this.item.mostrar_quantidade_animais,
          mostrar_id_animais: this.item.mostrar_id_animais,
          mostrar_km_percorrido: this.item.mostrar_km_percorrido,
          mostrar_valor_dos_servicos: this.item.mostrar_valor_dos_servicos,
          obrigatorio_num_talhoes: this.item.obrigatorio_num_talhoes,
          obrigatorio_id_talhoes: this.item.obrigatorio_id_talhoes,
          obrigatorio_coleta_amostra: this.item.obrigatorio_coleta_amostra,
          mostrar_coleta_amostra: this.item.mostrar_coleta_amostra,
          mostrar_produto: this.item.mostrar_produto,
        };

        const response = !_.isEmpty(this.item.id_configuracao) ? await this.update(payload) : await this.store(payload);

        this.item.id_configuracao = response.id_configuracao;

        this.getSettings();

        this.$snotify.success("Configuração salva com sucesso", "Sucesso");

      } catch (e) {

        this.$snotify.error("Erro ao salvar Configuração", "Atenção");
        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/settings/care`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/settings/care/${this.item.id_configuracao}`, payload);
      return data;
    },
  }
}
</script>
