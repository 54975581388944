<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="bonus-planning"
  >
    <v-row>
      <v-col
        v-if="route && route.id"
        cols="12"
      >
        <card-title-settings>
          {{ route.name }}
        </card-title-settings>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-autocomplete
          v-model="itemSelected"
          :items="items"
          item-text="item"
          dark
          hide-selected
          hide-details
          label="Inserir item"
          prepend-inner-icon="search"
          filled
          return-object
          :disabled="isViewModeLocal"
          @change="addItem"
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-menu
          v-model="startDate.picker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          content-class="menu-select"
          :disabled="isViewModeLocal"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(startDate.value, 'DD/MM/YYYY')"
              label="Data de início"
              prepend-inner-icon="event"
              :rules="[v => !!v || 'Campo obrigatório!']"
              persistent-hint
              dark
              filled
              hide-selected
              hide-details
              :disabled="isViewModeLocal"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="startDate.value"
            no-title
            @input="startDate.picker = false"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <v-text-field
          v-model="minPrice"
          type="number"
          label="Valor Base do Leite"
          prefix="R$"
          dark
          filled
          hide-selected
          hide-details
          :disabled="isViewModeLocal"
          @input="recalculateItems"
        />
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <v-text-field
          :value="maxBonus"
          type="number"
          label="Bonificaçães máximas"
          prefix="R$"
          dark
          filled
          hide-selected
          hide-details
          readonly
        />
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <v-text-field
          :value="maxPrice"
          type="number"
          label="Base + Bonificaçães máximas"
          prefix="R$"
          dark
          filled
          hide-selected
          hide-details
          readonly
        />
      </v-col>
      <v-col
        v-if="!isViewModeLocal"
        cols="12"
      >
        <v-form
          ref="form"
        >
          <v-row>
            <v-col
              v-if="ranges.length == 0"
              cols="12"
            >
              <v-card
                class="graph-card"
                color="transparent"
              >
                <v-card-title
                  class="justify-center"
                >
                  <v-col cols="12">
                    Escolha um item para iniciar o planejamento
                  </v-col>
                  <v-col cols="12">
                    ou uma das opções abaixo
                  </v-col>
                  <v-col cols="3">
                    <v-btn @click="copyPlanning">
                      Copiar existente
                    </v-btn>
                  </v-col>

                  <v-col cols="3">
                    <v-btn @click="showImportPlanning">
                      Importar Planilha
                    </v-btn>
                  </v-col>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="bonus-grid">
            <v-col
              v-for="(range, idx) of ranges"
              :key="idx"
            >
              <v-card
                class="graph-card"
                color="transparent"
              >
                <v-card-title>
                  <div
                    class="d-flex"
                    style="width: calc(100% - 55px);"
                  >
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span
                          class="text-truncate"
                          v-on="on"
                        >
                          {{ range.item }}
                        </span>
                      </template>
                      {{ range.item }}
                    </v-tooltip>
                  </div>
                  <v-spacer />
                  <template v-if="range.type === 'FAIXA'">
                    <v-btn
                      icon
                      color="red"
                    >
                      <v-icon
                        @click="changeRange(idx, 'remove')"
                      >
                        remove
                      </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="blue"
                    >
                      <v-icon
                        @click="changeRange(idx, 'add')"
                      >
                        add
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      icon
                      color="red"
                    >
                      <v-icon
                        @click="changeRange(idx, 'remove')"
                      >
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                </v-card-title>
                <v-card-text class="px-0">
                  <v-list
                    dark
                    color="transparent"
                    class="pt-0"
                  >
                    <v-list-item
                      v-for="(item, i) in range.list"
                      :key="`${idx}.${i}`"
                    >
                      <v-list-item-action
                        v-if="range.type === 'FAIXA'"
                        class="mr-1 mt-4 mb-0"
                      >
                        {{ (i == range.list.length - 1) ? '>' : 'Até' }}
                      </v-list-item-action>
                      <v-list-item-title>
                        <v-row no-gutters>
                          <v-col
                            v-if="range.type !== 'FRETE'"
                            class="pr-1 py-1"
                          >
                            <v-text-field
                              v-if="range.type === 'EXAME'"
                              :value="item.quantity == 0 ? 'Vencido' : 'Não Vencido'"
                              label="Vencimento"
                              readonly
                            />
                            <v-text-field
                              v-else-if="range.type === 'TIPO_LEITE'"
                              :value="item.quantity == 0 ? 'B' : 'C'"
                              label="Tipo de Leite"
                              readonly
                            />
                            <v-text-field
                              v-else
                              v-model="item.quantity"
                              type="number"
                              label="Quantidade"
                              hide-selected
                              hide-details
                              :rules="[v => !!v || 'Informe a quantidade']"
                              @keypress="disableDot"
                            />
                          </v-col>
                          <v-col
                            class="pl-1 py-1"
                          >
                            <v-text-field
                              v-model="item.price"
                              type="number"
                              label="Valor"
                              hide-selected
                              hide-details
                              :rules="[v => !!v || 'Informe o valor']"
                              @keypress="disableDot"
                            />
                          </v-col>
                          <v-col
                            class="pl-1 py-1"
                          >
                            <v-text-field
                              v-model="item.percent"
                              type="number"
                              label="%"
                              hide-selected
                              hide-details
                              @keypress="disableDot"
                              @input="updateValue(idx,i, 'percent')"
                            />
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-row
        v-else
        no-gutters
        class="bonus-grid"
      >
        <v-col
          v-for="(range, idx) of ranges"
          :key="idx"
        >
          <v-card
            class="report-card"
            color="transparent"
            dark
          >
            <v-card-title>
              {{ range.item }}
            </v-card-title>
            <v-data-table
              v-if="range.type === 'EXAME'"
              :headers="view.headersExams"
              :items="range.list"
              dark
              dense
              disable-sort
              hide-default-footer
              :items-per-page="range.list.length"
              class="elevation-1 report-table"
            >
              <template #[`item.quantity`]="{ value }">
                {{ value == 0 ? 'Vencido' : 'Não Vencido' }}
              </template>
              <template #[`item.price`]="{ value }">
                {{ formatCurrency(value) }}
              </template>
            </v-data-table>
            <v-data-table
              v-else-if="range.type === 'TIPO_LEITE'"
              :headers="view.headersMilkType"
              :items="range.list"
              dark
              dense
              disable-sort
              hide-default-footer
              :items-per-page="range.list.length"
              class="elevation-1 report-table"
            >
              <template #[`item.quantity`]="{ value }">
                {{ value == 0 ? 'B' : 'C' }}
              </template>
              <template #[`item.price`]="{ value }">
                {{ formatCurrency(value) }}
              </template>
            </v-data-table>
            <v-data-table
              v-else-if="range.type === 'FRETE'"
              :headers="view.headersFreight"
              :items="range.list"
              dark
              dense
              disable-sort
              hide-default-footer
              :items-per-page="range.list.length"
              class="elevation-1 report-table"
            >
              <template #[`item.price`]="{ value }">
                {{ formatCurrency(value) }}
              </template>
            </v-data-table>
            <v-data-table
              v-else
              :headers="view.headers"
              :items="range.list"
              dark
              dense
              disable-sort
              hide-default-footer
              :items-per-page="range.list.length"
              class="elevation-1 report-table"
            >
              <template
                #body="{ items }"
              >
                <tbody>
                  <tr
                    v-for="(item, i) in items"
                    :key="item.name"
                  >
                    <td>
                      {{ (i == items.length - 1) ? '>' : 'Até' }}
                    </td>
                    <td>
                      {{ formatNumber(item.quantity) }}
                    </td>
                    <td>
                      {{ formatCurrency(item.price) }}
                    </td>
                    <td>
                      {{ formatNumber(item.percent) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <v-card-title>
              <v-spacer />
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-row>

    <v-dialog
      v-model="copy.show"
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Planejamentos
          </span>
        </v-card-title>

        <v-data-table
          :headers="copy.headers"
          :items="copy.items"
          class="elevation-1"
          @click:row="selectPlanning"
        >
          <template #[`item.data`]="{ value }">
            <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
          </template>

          <template #[`item.bonus_maximo`]="{ value }">
            <span>{{ formatCurrency(value) }}</span>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            outlined
            @click="copy.show = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <import-excel-dialog
      v-model="importPlanning.show"
      title="Importar Planejamento"
      :fields="importFields"
      sheet-name="Volume (Litros)"
      show-model
      @import="onImportPlanning"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="ranges.length > 0 && !isViewModeLocal"
        fab
        dark
        color="blue darken-4"
        @click.stop="save"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar planejamento
        </v-tooltip>
      </v-btn>

      <v-btn
        color="deep-purple lighten-1"
        fab
        dark
        @click="isViewModeLocal = !isViewModeLocal"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              {{ isViewModeLocal ? 'edit' : 'visibility' }}
            </v-icon>
          </template>

          {{ isViewModeLocal ? 'Editar' : 'Visualizar' }}
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<style lang="scss">
.bonus-planning {
  .graph-card {
    margin-top: 5px !important;
    background: rgba(0, 0, 0, 0.5) !important;

    > .v-card__title {
      color: #fff;
      background: unset;
    }

    > .v-card__text {
      color: #fff !important;
    }
  }
  .bonus-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}
</style>

<script>
import moment from "moment-timezone";
import _, { isNull } from "lodash";
import XLSX from "xlsx-js-style";
import ImportExcelDialog from '@/Support/Components/ImportExcelMultiSheetDialog.vue';

import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

import { QualityType, getRangeType } from '@/Domains/Producer/Bonus/BonusCriteria/Enums/QualityType.js'

export default {
  name: "bonus-planning",

  components: {
    CardTitleSettings,
    ImportExcelDialog
  },

  props: {
    value: String,
    route: Object,
    isViewMode: Boolean,
    rawMaterialType: {
      type: String,
      default: 'PRINCIPAL',
    },
    rawMaterialId: String,
  },

  data() {
    return {
      id: null,
      itemSelected: '',
      entries: [],

      ranges: [],

      startDate: {
        picker: false,
        value: moment().format('YYYY-MM-DD'),
      },

      minPrice: 0,
      idMilkPrice: '',

      view: {
        headers: [
          { text: '', value: 'until', align: 'start', width: 40 },
          { text: 'Quantidade', value: 'quantity', align: 'start', },
          { text: 'Valor', value: 'price', align: 'center' },
          { text: '%', value: 'percent', align: 'center' },
        ],
        headersExams: [
          { text: 'Vencimento', value: 'quantity', align: 'start', },
          { text: 'Valor', value: 'price', align: 'center' },
        ],
        headersMilkType: [
          { text: 'Tipo Leite', value: 'quantity', align: 'start', },
          { text: 'Valor', value: 'price', align: 'center' },
        ],
        headersFreight: [
          { text: 'Valor', value: 'price', align: 'center' },
        ],
      },

      copy: {
        show: false,

        headers: [
          { text: 'Data', value: 'data' },
          { text: 'Bônus máximo', value: 'bonus_maximo' },
          { text: 'Laticínio', value: 'nome_laticinio' },
          { text: 'Rota', value: 'nome_rota' },
        ],

        items: [],

      },

      importPlanning: {
        show: false,
        imported: [],

        headers: [
          { text: 'Cod. Lat.', value: 'codigo_laticinio' },
          { text: 'Nome Prod', value: 'nome_produtor' },
          { text: 'Observação', value: 'observacao' },
          { text: 'Número', value: 'numero' },
          { text: 'Emissão', value: 'data_emissao' },
          { text: 'Recebimento', value: 'data_vencimento' },
          { text: 'Valor', value: 'valor' },
          { text: '', value: 'button', width: 100 },
        ],

        selected: [],
      },
    };
  },

  computed: {
    isViewModeLocal: {
      get() {
        return this.isViewMode;
      },

      set(newValue) {
        return this.$emit("update:isViewMode", newValue);
      },
    },
    items() {
      const ranges = this.ranges.map(({ item }) => item);
      return this.entries.filter(({ item }) => !ranges.includes(item));
    },
    maxBonus() {
      return this.roundToFourDecimals(this.ranges.reduce((acc, cur) => acc + Math.max(...cur.list.map(({ price }) => parseFloat(price))), 0));
    },
    maxPrice() {
      return this.roundToFourDecimals(parseFloat(this.minPrice) + this.maxBonus);
    },
    importFields() {
      /**
       * Obtém as colunas da última importação,
       * desse modo, seleciona automaticamente as colunas
       */
      const settings = this.$store.state.settings.gerais.campos_importados_planejamento || {};
      return [
        { text: 'Faixa', keys: ['Faixa', settings['Faixa'] ].filter(o => o), example: 'Até' },
        { text: 'Quantidade', keys: ['Quantidade', settings['Quantidade'] ].filter(o => o), example: '3' },
        { text: 'Bonificação R$', keys: ['Bonificação R$', settings['Bonificação R$'] ].filter(o => o), example: '1,00' },
      ]
    },

    isImporting() {
      return this.importPlanning.imported.length > 0
    },

    isPhpGenerator() {
      return this.$store.state.settings.gerais?.gerador_bonificacao === 'PHP'
    },

    hasMilkType() {
      return this.$store.state.settings.gerais?.cadastro_produtor?.dados_producao?.tipo_leite_entrega?.show
    },
  },

  created() {
    this.id = this.value;
    this.loadQualities()
  },

  mounted() {
    this.loadPlanning();
  },

  methods: {
    async loadPlanning() {
      try {
        const { data } = await this.$axios.post(`/tabelaPreco/detalhePrecos`, { id: this.id });

        if (_.isString(data)) {
          throw "PHP error";
        }

        const { tabelaPreco, valorLeite } = data;

        this.idMilkPrice = valorLeite.id_valor_leite;
        this.minPrice = this.roundToFourDecimals(parseFloat(valorLeite.valor));

        const res = tabelaPreco
          .sort((a, b) => a.qtd_amostra - b.qtd_amostra)
          .reduce((acc, cur) => {
            if (!(cur.item in acc)) {
              acc[cur.item] = {
                id: cur.id_qualidade_consulta,
                item: cur.item,
                type: getRangeType(cur.id_qualidade_consulta),
                list: []
              }
            }

            acc[cur.item].list.push({
              quantity: cur.qtd_amostra,
              price: cur.valor_amostra,
              percent: !isNull(cur.percentual) ? cur.percentual : ((cur.valor_amostra / this.minPrice) * 100).toFixed(2)
            })

            return acc;
          }, {})

        this.ranges = Object.values(res);

        this.startDate.value = tabelaPreco.length > 0 ? tabelaPreco[0].data : moment().format('YYYY-MM-DD');
      }
      catch (err) {
        console.warn(err)
      }
    },

    async loadQualities() {
      try {
        const { data } = await this.$queries.post(`/qualidade/listaQualidadeJson`, { nome: '%' });

        if (_.isString(data)) {
          throw "PHP error";
        }

        this.entries = data.map(({ id_qualidade, item }) => ({
          id: id_qualidade,
          item,
          type: getRangeType(id_qualidade)
        }));

        if (!this.isPhpGenerator) {
          this.entries = this.entries.filter(item => ![
            QualityType.GORDURA_TRIMESTRAL,
            QualityType.VOLUME_DIFERENCA_AA,
            QualityType.TIPO_LEITE_ENTREGA,
            QualityType.ORDENHA_MECANIZADA,
          ].includes(item.id));
        }

        if (!this.hasMilkType) {
          this.entries = this.entries.filter(item => ![
            QualityType.TIPO_LEITE_ENTREGA,
            QualityType.ORDENHA_MECANIZADA,
          ].includes(item.id));
        }

        if (this.rawMaterialType === 'SECUNDARIA') {
          this.entries = this.entries.filter((item) => [9, 10, 15, 22].includes(item.id));
        }

      }
      catch (err) {
        console.warn(err)
      }
    },

    addItem(item) {
      if (['EXAME', 'TIPO_LEITE'].includes(item.type)) {
        this.ranges.unshift({
          ...item,
          list: [{ quantity: 0, price: null, percent: null }, { quantity: 1, price: null, percent: null }],
        })
      }
      else if (item.type === 'FRETE') {
        this.ranges.unshift({
          ...item,
          list: [{ quantity: 0, price: null, percent: null }],
        })
      }
      else {
        this.ranges.unshift({
          ...item,
          list: [{ quantity: null, price: null, percent: null }],
        })
      }
      setTimeout(() => {
        this.itemSelected = "";
      }, 10);
    },

    changeRange(idx, type) {
      if (type == 'add') {
        this.ranges[idx].list.push({ quantity: null, price: null, percent: null });
      }
      if (type == 'remove') {
        if (this.ranges[idx].type !== 'FAIXA') {
          this.ranges.splice(idx, 1);
        }
        else {
          if (this.ranges[idx].list.length == 1) {
            this.ranges.splice(idx, 1);
          }
          else {
            this.ranges[idx].list.splice(-1, 1);
          }
        }
      }
    },

    disableDot (e) {
      if (e.charCode === 46) {
        e.preventDefault()
      }
    },

    async save() {
      if (!await this.$refs.form?.validate()) {
        return;
      }

      const form = {
        id_tabela_agrupador: this.id,
        id_materia_prima: this.rawMaterialId,
        id_rota: this.route?.id,
        data_inicio: moment(this.startDate.value).format('YYYY-MM-DD'),
        faixas: this.ranges.map(range => ({
          id_qualidade_consulta: range.id,
          item: range.item,
          lista: range.list.map(item => ({
            qtd_amostra: item.quantity,
            valor_amostra: item.price,
            percentual: item.percent,
          }))
        })),
        id_valor_leite: this.idMilkPrice,
        valor_leite: this.minPrice,
      };

      try {
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.post(`/tabelaPreco/insereAltera`, form);

        this.id = data.id_tabela_agrupador;
        this.$emit('input', data.id_tabela_agrupador);
        this.loadPlanning();
        this.$snotify.success("Planejamento salvo com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar planejamento", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async copyPlanning() {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.get('/bonus/price-table/actives');
        this.copy.items = data;
      } catch (error) {
        this.$snotify.error("Erro ao carregar tabela", "Atenção");
        console.warn(error)
      } finally {
        this.$root.$progressBar.hide();
        this.copy.show = true;
      }
    },

    async selectPlanning({ id_tabela }) {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.post(`/tabelaPreco/detalhePrecos`, { id: id_tabela });

        if (_.isString(data)) {
          throw "PHP error";
        }

        this.idMilkPrice = data.valorLeite.id_valor_leite;

        const res = data.tabelaPreco
          .sort((a, b) => a.qtd_amostra - b.qtd_amostra)
          .reduce((acc, cur) => {
            if (!(cur.item in acc)) {
              acc[cur.item] = {
                id: cur.id_qualidade_consulta,
                item: cur.item,
                list: []
              }
            }

            acc[cur.item].list.push({
              quantity: cur.qtd_amostra,
              price: cur.valor_amostra,
              percent: !isNull(cur.percentual) ? cur.percentual : ((cur.valor_amostra / this.minPrice) * 100).toFixed(2)
            })

            return acc;
          }, {})

        this.ranges = Object.values(res);
      }
      catch (err) {
        this.$snotify.error("Erro ao copiar tabela", "Atenção");
        console.warn(err)
      } finally {
        this.$root.$progressBar.hide();
        this.copy.show = false;
      }
    },

    exportExcel() {

      let worksheet = XLSX.utils.book_new();

      worksheet["!merges"] = [];
      worksheet['!cols'] = [];

      this.ranges.forEach((range, idx) => {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [[range.item]], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, range.list.map(item => ({
          'Quantidade': 'Até',
          '': parseFloat(item.quantity),
          'Valor': parseFloat(item.price),
        })), { origin: { r: 1, c: col } });

        // Merges title column
        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 2 } })
        // Merges quantity column
        worksheet["!merges"].push({ s: { r: 1, c: col }, e: { r: 1, c: col + 1 } })

        worksheet['!cols'].push({ wch: 4 }, { wch: 10 }, { wch: 10 }, { wch: 12 })
      })

      const workbook = XLSX.utils.book_new();
      const filename = "planejamento_de_bonificacao";

      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value, casasDecimais = 4) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: casasDecimais }).format(value),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),

    updateValue(idxRange, idxList, fieldChange) {

      if(fieldChange === 'percent') {
        this.ranges[idxRange].list[idxList].price = ((this.ranges[idxRange].list[idxList].percent * this.minPrice)/100).toFixed(2)
      }

      if(fieldChange === 'price') {
        this.ranges[idxRange].list[idxList].percent = ((this.ranges[idxRange].list[idxList].price / this.minPrice) * 100).toFixed(2)
      }
    },

    recalculateItems() {
      const basePrice = this.minPrice;

      this.ranges = this.ranges.map(function (range) {
        const updatedList = range.list.map(function (list) {
          const calculatedPrice = ((list.percent * basePrice) / 100).toFixed(4);
          return {
            'percent': list.percent,
            'quantity': list.quantity,
            'price': calculatedPrice
          };
        });

        return {
          id: range.id,
          item: range.item,
          list: updatedList
        };
      });
    },

    roundToFourDecimals(numero, casasDecimais = 4) {
      const multiplicador = Math.pow(10, casasDecimais);
      return Math.round(numero * multiplicador) / multiplicador;
    },

    showImportPlanning() {
      this.importPlanning.show = true;
    },

    async onImportPlanning({ data }) {

      const itemsSheet = data.map(function(item) {
        const entrie = this.entries.find(objeto => objeto.item === item.sheet);

        if (entrie) {
          const list = item.items.map(function (itm) {
            return {
              percent: 0,
              price: itm['Bonificação R$'],
              quantity: itm['Quantidade']
            };
          });

          return {
            id: entrie.id,
            item: item.sheet,
            list: list
          }
        }
      }.bind(this));

      const items = itemsSheet.filter(function(item) {
        if (item) {
          return item;
        }
      });

      this.ranges = Object.values(items);
      this.importPlanning.show = false;
    }
  },
};
</script>
