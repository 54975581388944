<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Vacinas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
        </v-card-title>
        <data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          :search="filters.search"
          style="white-space: nowrap"
          @click:row="showDialog"
        >
          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="hasEditItemAccess"
                  @click="showDialog(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      edit
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Editar
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="hasDeleteItemAccess"
                  @click="deleteItem(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Excluir
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </data-table>
      </v-card>
    </v-container>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasCreateItemAccess"
        fab
        color="purple"
        @click="showDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Cadastrar Vacina
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <product-dialog
      v-model="edit"
      :edit-id.sync="editId"
      :group-id.sync="editId"
      @save="loadItems"
    />
  </div>
</template>

<script>
import ProductDialog from "@/Domains/Projects/HealthExamination/SanityVaccinesRegisterDialog.vue";
import { isNull } from "lodash";
export default {

  components: {
    ProductDialog,
  },

  data() {
    return {
      items: [],

      filters: {
        search: "",
        group: "VACINAS",
      },

      groupList: [],

      edit: false,
      editId: null,
    };
  },

  computed: {
    headers() {

      if (!isNull(this.filters.group)) {
        if(this.groupList.some(group => group.group === this.filters.group)) {
          const selectedGroup = this.groupList.filter(group => group.group === this.filters.group)

          const headersMap = selectedGroup.map(function(group) {

            const width = 100;

            const headersDynamic = group.fields.map(function(field) {
              return { text: field.description, value: field.id, align: 'start', width: width};
            });

            const headerActions = [
              { text: '', value: '', align: 'end' },
              { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
            ]

            let headers = [
              { text: 'Nome', value: 'name', align: 'start', width: '300'},
              ... headersDynamic,
              ... headerActions
            ];

            return headers;
          });

          return headersMap[0] ?? [];
        }

        return [];
      }

      return [];
    },

    //Pega os dados de permissão do usúario.
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    //Se for admin ou ter o cargo Supervisor(Milkup) libera todos os acessos.
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    //Permissão para poder CRIAR uma Causa.
    hasCreateItemAccess() {
      return this.isAdmin || (this.userResources.some(o => (o.recurso === "product-create") && o.tipo === "COMPONENTE" ));
    },

    //Permissão para poder EDITAR uma Causa.
    hasEditItemAccess() {
      return this.isAdmin || (this.userResources.some(o => (o.recurso === "product-edit") && o.tipo === "COMPONENTE" ));
    },

    //Permissão para poder DELETAR uma Causa.
    hasDeleteItemAccess() {
      return this.isAdmin || (this.userResources.some(o => (o.recurso === "product-delete") && o.tipo === "COMPONENTE" ));
    },
  },

  async mounted() {
    await this.getGroups();
    await this.loadItems();
  },

  methods: {
    async getGroups() {
      try {

        const { data } = await this.$axios.get('registrations/product-group', { params: {
            grupo: 'VACINAS',
          }
        });

        this.groupList = data.map(item => {
          return {
            id: item.id_grupo_produto,
            group: item.grupo,
            fields: item.campos,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");

        console.warn(err);
      } finally {

      }
    },

    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/product', { params: {
            grupo: 'VACINAS',
          }
        });

        this.items = data.map(item => {

          var fieldsMap = [];

          for (const key in item.campos) {
            fieldsMap[key] = item.campos[key];
          }

          return {
            id: item.id_produto,
            group: item.id_grupo_produto,
            name: item.nome,
            ... fieldsMap
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showDialog({ id }) {
      if (!this.hasEditItemAccess) {
        return;
      }
      this.edit = true;
      this.editId = id;
    },

    async deleteItem(Item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir o produto ${Item.name} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/product/${Item.id}`);

        this.$snotify.success("Produto excluído com sucesso", "Sucesso");
        return await this.loadItems();
      } catch (e) {
        this.$snotify.error("Erro ao excluir produto", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getTypeById(id) {
      for (const type of this.typesList) {
        if (type.id === id) {
          return type.type;
        }
      }

      return ' - ';
    },
  },
}
</script>
