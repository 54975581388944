<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        {{ product.name }}
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        {{ form.id ? 'Edição de dados do estoque' : 'Cadastro de estoque' }}
      </v-card-subtitle>
      <v-divider />
      <v-card-text
        class="pt-5"
      >
        <v-form
          ref="formRef"
          lazy-validation
          @submit="save"
        >
          <v-row>
            <v-col
              v-if="form.lotNumber || !form.id"
              class="pb-0"
            >
              <v-text-field
                v-model="form.lotNumber"
                label="Lote"
                :disabled="!!form.id"
                persistent-placeholder
                :rules="form.id ? [] : [v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col class="pb-0">
              <masked-text-field
                v-model="form.quantity"
                label="Qtde. Estoque"
                :disabled="quantityDisabled"
                :mask="masks.float"
                unmask
                inputmode="numeric"
                :suffix="product.measurement"
                :rules="[v => !!v || 'Campo obrigatório!']"
                validate-on-blur
              />
            </v-col>
            <v-col
              v-if="!hidePrice"
              class="pb-0"
            >
              <money-input
                v-model="form.price"
                label="Preço Unitário"
                persistent-placeholder
              />
            </v-col>
          </v-row>
          <v-row v-if="form.lotNumber || !form.id">
            <v-col class="pb-0">
              <v-text-field
                v-model="form.manufacturingDate"
                label="Fabricação"
                type="date"
                persistent-placeholder
                :disabled="!form.lotNumber"
              />
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                v-model="form.expirationDate"
                label="Validade"
                type="date"
                persistent-placeholder
                :disabled="!form.lotNumber"
              />
            </v-col>
            <v-col
              v-if="form.id"
              class="pb-0"
            >
              <v-select
                v-model="form.lotStatus"
                :items="[
                  { text: 'LIBERADO', value: 'LIBERADO' },
                  { text: 'RESTRITO', value: 'RESTRITO' },
                  { text: 'SUSPENSO', value: 'SUSPENSO' },
                ]"
                label="Status Lote"
                clearable
                persistent-placeholder
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import MoneyInput from '@/Support/Components/MoneyInput.vue'

const { progressBar, notify } = useUtils()

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

// eslint-disable-next-line
defineProps({
  quantityDisabled: Boolean,
  hidePrice: Boolean
})

// eslint-disable-next-line
const emit = defineEmits([
  'save',
])

const dialog =  ref(false)

const formRef = ref()

const form = reactive({
  id: null,
  lotNumber: null,
  manufacturingDate: null,
  expirationDate: null,
  quantity: null,
  price: null,
  lotStatus: null,
})
const product =  ref({})

const close = () => {
  dialog.value = false
}

const show = (data) => {
  dialog.value = true

  if (data.stock?.id) {
    form.id = data.stock.id
    form.lotNumber = data.stock.lotNumber
    form.manufacturingDate = data.stock.manufacturingDate
    form.expirationDate = data.stock.expirationDate
    form.quantity = data.stock.quantity
    form.price = data.stock.price
    form.lotStatus = data.stock.lotStatus
  }

  product.value = data.product

  formRef.value?.resetValidation()
}

const save = async () => {
  if (!await formRef.value?.validate()) {
    return
  }

  try {
    progressBar?.saving()

    if (form.id) {
      const payload = {
        data_fabricacao: form.manufacturingDate,
        data_validade: form.expirationDate,
        status_lote: form.lotStatus,
        quantidade: form.quantity,
        valor: form.price,
      }

      await axios.put(`/stock/${form.id}`, payload)
    } else {
      const payload = {
        id_item: product.value.id,
        quantidade: form.quantity,
        tipo: 'ENTRADA',
        lotes: [
          {
            numero: form.lotNumber,
            data_fabricacao: form.manufacturingDate,
            data_validade: form.expirationDate,
            quantidade: form.quantity,
          }
        ],
      }

      await axios.post(`/stock`, payload)
    }

    close()

    emit('save')

    notify.success('Estoque salvo com sucesso', 'Sucesso')
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao salvar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

// eslint-disable-next-line
defineExpose({
  show
})
</script>
